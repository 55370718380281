

import CommonPanel from "../../common/common-panel";
import { useTranslation } from "react-i18next";

// Charts
import SurveyEmissionChart from "./charts/survey-emission";
import SurveyCompletionStatusChart from "./charts/survey-completion-status";
import EmissionBySizeChart from "./charts/emission-by-size";
// import SurveyOverViewBanner from "./components/survey-overview-component";

// Table Component
import Table from "./table";

// CSS
import "./survey-details.css";


// Test
import { GetSurveyDetails, GetSurveyDetailsByID } from "../../../services/AOGI/survey-details";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { AppDispatch, AppState } from '../../../store/store';
import { AddSurveyDetails } from "../../../store/AOGI/survey-details";

// React Router DOM
import { useParams, useNavigate, Navigate } from 'react-router-dom';

// Loading Screen
import LoadingScreen from "../../common/Loading";

const SurveyDetails = () => {
    //  0=Read
    //  1=Add
    //  2=Update
    //  3=Delete 


    const { id } = useParams();
    const { name } = useParams();
    const { customerId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const SurveyDetails_Redux = useSelector((state: AppState) => state.AOGISurveyDetails.SurveyDetailsData);

    const [isLoading, isLoadingUpdate] = useState(false);

    // Chart States

    // SUrvey Overview
    const [TotalSites, UpdateTotalSites] = useState(0);
    const [CompletedSites, UpdateCompletedSites] = useState<number>(0);
    const [RemainingSites, UpdateRemainingSites] = useState<number>(0);


    // SUrvey Overview
    const [LargeEmissionCount, UpdateLargeEmissionCount] = useState(0);
    const [MediumEmissionCount, UpdateMediumEmissionCount] = useState<number>(0);
    const [SmallEmissionCount, UpdateSmallEmissionCount] = useState<number>(0);

    // Emission By Size
    const [TotalEmissionCount, UpdateTotalEmissionCount] = useState<number>(0);


    let catched_id = id;
    const dispatch: AppDispatch = useDispatch();
    const [SurveyDetailsData, UpdateSurveyDetailsData] = useState<any>("");
    const [SurveyDetailsName, UpdateSurveyDetailsName] = useState<any>("");

    // display date
    const [current_time, UpdateCurrentTime] = useState<any>("");

    useEffect(() => {
        if (SurveyDetailsData !== "") {
            GetSurveyDetailsByID(catched_id, SurveyDetails_Redux).then((res_catched: any) => {

                UpdateSurveyDetailsData(res_catched);

            })
        }
    }, [SurveyDetails_Redux])
    useEffect(() => {
        isLoadingUpdate(true)
        GetSurveyDetails(catched_id, "").then((res: any) => {

            // Filter Data
            // use var catched_id == URL Param ID
            GetSurveyDetailsByID(res[0].id, res).then((res_catched: any) => {
                if (res_catched[0] == "") {
                    navigate("/aogi/broken_url")
                }
                dispatch(AddSurveyDetails(res_catched as any));
                UpdateSurveyDetailsData(res_catched);
                UpdateSurveyDetailsName(res_catched[0]?.name);
                document.title = `Survey Details - ${res_catched[0]?.name}`;

                setTimeout(() => {
                    isLoadingUpdate(false)
                }, 3000)

            })

        });

    }, []);


    // Setting chart Data
    useEffect(() => {
        // Survey Overview
        // Total =====
        let d = new Date();
        let day = d.getDate();
        let month: any = d.getMonth();
        let full_year: any = d.getFullYear();
        let hr = d.getHours();
        let min: any = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        let ampm = "AM";
        if (hr > 12) {
            hr -= 12;
            ampm = "PM";
        }
        let months_array = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        month = months_array[month];
        UpdateCurrentTime(`${day}-${month}-${full_year} ${hr}:${min} ${ampm}`);

        if (SurveyDetailsData !== "") {
            UpdateTotalSites(SurveyDetailsData[0]?.data?.length);
            let completed_count: number = 0;
            let remaining_count: number = 0;
            let large_count: number = 0;
            let medium_count: number = 0;
            let small_count: number = 0;
            let total_emission_count: number = 0;
            SurveyDetailsData[0]?.data?.forEach((val: any) => {
                let check_complete = 0;
                val.emission.forEach((emission_val: any) => {

                    if (emission_val.size === 'Medium') {
                        if (emission_val.noOfLeaks != null) {

                            medium_count = medium_count + emission_val.noOfLeaks;
                            total_emission_count = total_emission_count + emission_val.noOfLeaks;

                        } else {
                            medium_count++;
                            total_emission_count++;

                        }

                    } else if (emission_val.size === 'Small') {
                        if (emission_val.noOfLeaks != null) {

                            small_count = small_count + emission_val.noOfLeaks;
                            total_emission_count = total_emission_count + emission_val.noOfLeaks;

                        } else {
                            small_count++;
                            total_emission_count++;

                        }

                    } else if (emission_val.size === 'Large') {
                        if (emission_val.noOfLeaks != null) {

                            large_count = large_count + emission_val.noOfLeaks;
                            total_emission_count = total_emission_count + emission_val.noOfLeaks;

                        } else {
                            large_count++;
                            total_emission_count++;

                        }



                    }

                    check_complete++;
                });
                UpdateLargeEmissionCount(large_count);
                UpdateMediumEmissionCount(medium_count);
                UpdateSmallEmissionCount(small_count);
                UpdateTotalEmissionCount(total_emission_count)
                if (check_complete > 0) {
                    completed_count++;
                    UpdateCompletedSites(completed_count);
                    UpdateRemainingSites(Number(SurveyDetailsData[0]?.data?.length) - Number(completed_count));


                }
                if (check_complete == 0) {
                    // remaining_count++;
                    // UpdateRemainingSites(remaining_count);

                }

            });

            // comleted =====

            // Calculate Percentage for Emission by Size


        }


    }, [SurveyDetailsData, LargeEmissionCount, MediumEmissionCount, SmallEmissionCount, TotalEmissionCount])



    return <>
        <LoadingScreen isLoading={isLoading} />
        <div className="content__header content__boxed overlapping">
            <div className="content__wrap pt-3 pb-4" style={{ marginBottom: "10px" }}>

                {/* <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="./index.html">AOGI</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                </ol>
            </nav> */}

                <h1 className="page-title mb-2 mt-4">{t("SurveyDetails.HeaderWithSurveyName")} {SurveyDetailsName} <span style={{ float: "right" }}>{t("CustomerLabel")} {'\u00A0'}:{'\u00A0'}{name}</span></h1>
                <div>
                    <label className="render_time_survey_details">
                        {t("SurveyDetails.AsOfTime")} {current_time}
                    </label>
                </div>


            </div>

        </div>

        <div className="content__boxed">
            <div className="content__wrap">



                {/* Grid Main Div */}
                <div className="aogi_survey_details_upper_div">

                    {/* one */}
                    {/* <SurveyOverViewBanner TotalSites={TotalSites} CompletedSites={CompletedSites} RemainingSites={}/> */}
                    <div className="card aogi_survey_details_survey_overview">
                        <div className="card-body survey_details_card_body">
                            <h5 className="card-title card_survey_detail_heading">{t("SurveyDetails.SurveyOverviewHeader")} </h5>

                            <table className="survey_details_card_table">
                                <tr>
                                    <td>{t("SurveyDetails.TotalSitesText")} </td>
                                    <td className="bold-values-table">{TotalSites}</td>
                                </tr>

                                <tr>
                                    <td>{t("SurveyDetails.CompletedText")} </td>

                                    <td className="bold-values-table">{CompletedSites}</td>
                                </tr>

                                <tr>
                                    <td>{t("SurveyDetails.RemainingText")} </td>
                                    <td className="bold-values-table">{RemainingSites}</td>

                                </tr>
                            </table>



                        </div>
                    </div>

                    {/* two */}
                    <div className="card aogi_survey_details_emission_overview">
                        <div className="card-body survey_details_card_body">
                            <h5 className="card-title card_survey_detail_heading">{t("SurveyDetails.EmissionOverview")} </h5>



                            <table className="survey_details_card_table">
                                <tr>
                                    <td>{t("SurveyDetails.LargeText")} </td>
                                    <td className="bold-values-table">{LargeEmissionCount}</td>
                                </tr>

                                <tr>
                                    <td>{t("SurveyDetails.MediumText")} </td>
                                    <td className="bold-values-table">{MediumEmissionCount}</td>
                                </tr>

                                <tr>
                                    <td>{t("SurveyDetails.SamllText")} </td>
                                    <td className="bold-values-table">{SmallEmissionCount}</td>
                                </tr>
                            </table>



                        </div>
                    </div>

                    {/* three */}
                    <div className="card aogi_survey_details_survey_emission">
                        <div className="card">
                            <CommonPanel InnerComponent={<SurveyEmissionChart SurveyDetails_Redux={SurveyDetailsData} />} Heading={t("SurveyDetails.SurveyEmissionHeader")} />


                        </div>
                    </div>

                    {/* four */}
                    <div className="card aogi_survey_details_survey_completion_status">
                        <div className="card">
                            <CommonPanel InnerComponent={<SurveyCompletionStatusChart TotalSites={TotalSites} RemainingSites={RemainingSites} CompletedSites={CompletedSites} SurveyDetails_Redux={SurveyDetails_Redux} />} Heading={t("SurveyDetails.SurveyCompleteHeader")} />


                        </div>
                    </div>
                    <br className='d-md-none' />
                    {/* five */}
                    <div className="card aogi_survey_details_emission_by_size">
                        <div className="card">
                            <CommonPanel InnerComponent={<EmissionBySizeChart SurveyDetails_Redux={SurveyDetails_Redux} />} Heading={t("SurveyDetails.EmissionBySizeHeader")} />
                        </div>
                    </div>
                </div>


                <article className="d-md-flex gap-4">
                    <div className="flex-fill">

                        <section id="boxed-layout-tips" className="card mb-4">
                            <div className="card">
                                {/* Table */}
                                <CommonPanel InnerComponent={<Table SurveyDetails_Redux={SurveyDetails_Redux} />} Heading={SurveyDetailsName} />

                            </div>
                        </section>

                    </div>

                </article>

            </div>
        </div></>
}

export default SurveyDetails;