// Redux Import 
import AOGISurveyDetails from "../../store/AOGI/survey-details";
import axios from 'axios';
import { resolve } from "node:path/win32";
import Helpers from "../../utils/helpers";

// service API
import SurveyDetailsService from "../survey_details/surevy_details.service";


const GetSurveyDetails = async (id: any, para: any) => {

    return await new Promise((resolve, reject) => {
        // check redux data
        if (para == "") {

            SurveyDetailsService.getSurveyDetailsList(id).then((res) => {
                resolve([res])
            })
        } else {
            // Data present in Redux so send as it is
            resolve(para);
        }


    })


    return;
}

const RemoveSurveyDetails = async (id: any, surveyId: any, ReduxData: any) => {
    return await new Promise((resolve, reject) => {

        // Send Data to API
        let data = [{
            "_id": id,
            "SurveyID": surveyId
        }]
        SurveyDetailsService.DeleteSurveyDetails(data).then((res) => {
            if (res == true) {
                let _ReduxData = JSON.parse(JSON.stringify(ReduxData));
                _ReduxData.forEach(function (o: any) {
                    o.data = o.data.filter((obj: any) => obj.id !== id);
                });
                // After API success then remove from Redux Data
                // ReduxData = ReduxData.filter(function (obj: any) {

                //     return obj.id !== id;
                // });

                resolve(_ReduxData);
            } else if (res == false) {
                resolve(false);
            }
        });
    })

}

//Delete sub table records - Jerin
const DeleteSubSurveyDetails = async (id: any, ReduxData: any, pid: any) => {
    return await new Promise((resolve, reject) => {

        // Send Data to API
        let data = {
            "_id": pid,
            "Emission": [{ "id": id }]
        }
        SurveyDetailsService.DeleteSubSurveyRecord(data).then((res) => {
            if (res == "SUCCESS") {
                let _ReduxData = JSON.parse(JSON.stringify(ReduxData));

                _ReduxData.forEach(function (obj: any) {
                    var tmpData = obj.data.filter((o: any) => o.id == pid);
                    tmpData[0].emission = tmpData[0].emission.filter((s: any) => s.id !== id);
                    tmpData[0].emissionCount = parseInt(tmpData[0].emissionCount) - 1;
                });
                // After API success then remove from Redux Data
                resolve(_ReduxData);
            } else if (res == false) {
                resolve(false);
            }
        })
    })

}


const RemoveSurveyDetailsMultiple = async (ids: any, SurveyID: any, ReduxData: any) => {
    return await new Promise((resolve, reject) => {

        // Send Data to API
        let finData: { _id: any, SurveyID: any }[] = [];
        ids.map((a: any, b: any) => {
            finData.push({ '_id': a, "SurveyID": SurveyID })
        })
        SurveyDetailsService.DeleteSurveyDetails(finData).then((res) => {
            if (res == true) {
                let _ReduxData = JSON.parse(JSON.stringify(ReduxData));
                _ReduxData.forEach(function (o: any) {
                    ids.filter(function (k: any) {
                        o.data = o.data.filter((obj: any) => obj.id !== k);

                    })
                });
                // After API success then remove from Redux Data
                // ReduxData = ReduxData.filter(function (obj: any) {

                //     return obj.id !== id;
                // });


                resolve(_ReduxData);
            } else if (res == false) {
                resolve(false);
            }
        });

    })

}


const GetSurveyDetailsByID = async (id: any, ReduxData: any) => {
    return await new Promise((resolve, reject) => {

        let IdData = ReduxData.filter(function (obj: any) {
            return obj.id == id;
        });


        resolve(IdData);


    })

}


const FilterSurveyDetailListNew = async (reduxData: any, status: any, sourceSize: any) => {
    let statusOpen: boolean;
    if (status == "Open") {
        statusOpen = true;
    } else {
        statusOpen = false;
    }

    let statusCompleted: boolean;
    if (status == "Completed") {
        statusCompleted = true;
    } else {
        statusCompleted = false;
    }

    let statusAll: boolean;
    if (status == "All") {
        statusAll = true;
    } else {
        statusAll = false;
    }

    return await new Promise((resolve, reject) => {
        if (reduxData === "") {
            resolve("");
        }

        let filterSurveyDetailList = JSON.parse(JSON.stringify(reduxData));

        let allSurveyList: any = [];
        let openSurveyList: any = [];
        let completedSurveyList: any = [];

        openSurveyList = filterSurveyDetailList.filter((surveyDetail: any) => {
            return surveyDetail.emissionCount == 0
        });

        completedSurveyList = filterSurveyDetailList.filter((surveyDetail: any) => {
            return (statusCompleted && surveyDetail.emissionCount > 0)
        });

        if (statusAll) {
            allSurveyList = filterSurveyDetailList;
        } else {
            if (statusOpen) {
                allSurveyList = [...openSurveyList]
            }
            if (statusCompleted) {
                allSurveyList = [...allSurveyList, ...completedSurveyList]
            }
        }

        let sourceSizeArray = ["Small", "Medium", "Large"];
        let sourceEmissionArray = ["Enardo Valve", "Vent", "Flare", "Hatch", "Other"];
        let leakPersistenceArray = ["Persistence", "Intermittent"];

        if (sourceSize?.length > 0) {

            allSurveyList = allSurveyList.filter((surveyDetail: any) => {

                return (sourceSizeArray.some((x: any) => sourceSize.includes(x)) == false) || surveyDetail.emission.some(function (emssionRecord: any) {
                    return (
                        (sourceSize.some((e: any) => e == "Small") && emssionRecord.size?.toLowerCase() == "small")
                        || (sourceSize.some((e: any) => e == "Medium") && emssionRecord.size?.toLowerCase() == "medium")
                        || (sourceSize.some((e: any) => e == "Large") && emssionRecord.size?.toLowerCase() == "large")
                    )

                });
            });


            allSurveyList = allSurveyList.filter((surveyDetail: any) => {
                return (sourceEmissionArray.some((x: any) => sourceSize.includes(x)) == false) || surveyDetail.emission.some(function (emssionRecord: any) {
                    return ((sourceSize.some((e: any) => e == "No Emission") && emssionRecord.source?.toLowerCase() == "noemission")
                        || (sourceSize.some((e: any) => e == "Enardo Valve") && emssionRecord.source?.toLowerCase() == "enardovalve")
                        || (sourceSize.some((e: any) => e == "Vent") && emssionRecord.source?.toLowerCase() == "vent")
                        || (sourceSize.some((e: any) => e == "Flare") && emssionRecord.source?.toLowerCase() == "flare")
                        || (sourceSize.some((e: any) => e == "Hatch") && emssionRecord.source?.toLowerCase() == "hatch")
                        || (sourceSize.some((e: any) => e == "Other") && emssionRecord.source?.toLowerCase() == "other")
                    )

                });
            });


            allSurveyList = allSurveyList.filter((surveyDetail: any) => {
                return (leakPersistenceArray.some((x: any) => sourceSize.includes(x)) == false) || surveyDetail.emission.some(function (emssionRecord: any) {
                    return (
                        (sourceSize.some((e: any) => e == "Persistent") && emssionRecord.leakPersistence?.toLowerCase() == "persistent")
                        || (sourceSize.some((e: any) => e == "Intermittent") && emssionRecord.leakPersistence?.toLowerCase() == "intermittent"))

                });
            });

        }

        resolve(allSurveyList);

    })

}


// Filters

// By 1) status
//    2) size
//    3) Source
const FilterSurveyDetailList = async (reduxData: any, status: any, Size: any, source: any) => {

    // , Size: any, Source: any
    // Setting for Status
    let statusOpen: boolean;
    if (status.StatusOpen == "btn-primary") {
        statusOpen = true;
    } else {
        statusOpen = false;
    }

    let statusCompleted: boolean;
    if (status.StatusCompleted == "btn-primary") {
        statusCompleted = true;
    } else {
        statusCompleted = false;
    }

    // Setting for Size
    let sizeSmall: boolean;
    if (Size.SizeSmall == "btn-primary") {
        sizeSmall = true;
    } else {
        sizeSmall = false;
    }

    let sizeMedium: boolean;
    if (Size.SizeMedium == "btn-primary") {
        sizeMedium = true;
    } else {
        sizeMedium = false;
    }

    let sizeLarge: boolean;

    if (Size.SizeLarge == "btn-primary") {
        sizeLarge = true;
    } else {
        sizeLarge = false;
    }


    //Jerin - Filter for Source
    let sourceNoEmission: boolean;

    if (source.SourceNoEmission == "btn-primary") {
        sourceNoEmission = true;
    } else {
        sourceNoEmission = false;
    }

    let sourceFlare: boolean;

    if (source.SourceFlare == "btn-primary") {
        sourceFlare = true;
    } else {
        sourceFlare = false;
    }

    let sourceHatch: boolean;

    if (source.SourceHatch == "btn-primary") {
        sourceHatch = true;
    } else {
        sourceHatch = false;
    }

    let sourceEnardoValve: boolean;

    if (source.SourceEnardoValve == "btn-primary") {
        sourceEnardoValve = true;
    } else {
        sourceEnardoValve = false;
    }

    let sourceVent: boolean;

    if (source.SourceVent == "btn-primary") {
        sourceVent = true;
    } else {
        sourceVent = false;
    }

    let sourceOthers: boolean;

    if (source.StatusOthers == "btn-primary") {
        sourceOthers = true;
    } else {
        sourceOthers = false;
    }

    return await new Promise((resolve, reject) => {
        if (reduxData === "") {
            resolve("");
        }

        let filterSurveyDetailList = JSON.parse(JSON.stringify(reduxData));


        let openSurveyList = [];
        let completedSurveyList = [];
        if (statusOpen) {
            openSurveyList = filterSurveyDetailList.filter((surveyDetail: any) => {
                return surveyDetail.emissionCount == 0
            });
        }

        if (statusCompleted) {
            completedSurveyList = filterSurveyDetailList.filter((surveyDetail: any) => {
                return (statusCompleted && surveyDetail.emissionCount > 0)

            });

            completedSurveyList = completedSurveyList.filter((surveyDetail: any) => {
                return surveyDetail.emission.some(function (emssionRecord: any) {
                    return (sourceNoEmission && emssionRecord.source?.toLowerCase() == "noemission")
                        || (sourceEnardoValve && emssionRecord.source?.toLowerCase() == "enardovalve")
                        || (sourceVent && emssionRecord.source?.toLowerCase() == "vent")
                        || (sourceFlare && emssionRecord.source?.toLowerCase() == "flare")
                        || (sourceHatch && emssionRecord.source?.toLowerCase() == "hatch")
                        || (sourceOthers && emssionRecord.source?.toLowerCase() == "other")
                });
            });

            completedSurveyList = completedSurveyList.filter((surveyDetail: any) => {
                return surveyDetail.emission.some(function (emssionRecord: any) {
                    return (emssionRecord?.size == null)
                        || (sizeSmall && emssionRecord.size?.toLowerCase() == "small")
                        || (sizeMedium && emssionRecord.size?.toLowerCase() == "medium")
                        || (sizeLarge && emssionRecord.size?.toLowerCase() == "large")
                });
            });
        }

        resolve([...openSurveyList, ...completedSurveyList]);

    })

}

// Update Emission Records
const UpdateSurveyDetailsList = async (id: any, updatedData: any, formData_redux: any, ReduxData: any) => {
    return await new Promise((resolve, reject) => {
        // Send Data to API
        SurveyDetailsService.UpdateSurveyDetailsList(updatedData).then((res) => {
            // After API success then remove from Redux Data
            if (res?.length > 0) {
                let updated_data = res.filter((res: any) => res.id === id)
                // let updated_data = res[res?.length - 1]
                let _ReduxData = JSON.parse(JSON.stringify(ReduxData)); //Created A Copy for rewrite object 
                let _updatedData = formData_redux?.Emission[0]; //Created A Copy for rewrite object 
                _ReduxData.map(function (obj: any) {
                    obj?.emission.map(function (rec: any) {
                        if (rec.id === id) {
                            rec.source = updated_data[0].source;
                            rec.size = updated_data[0].size;
                            rec.description = (updated_data[0].description != null || updated_data[0].description != "null") ? updated_data[0].description : "";
                            rec.picture = updated_data[0].picture;
                            rec.pictureID = updated_data[0]?.pictureID;
                            rec.video = updated_data[0].video;
                            rec.videoID = updated_data[0].videoID;
                            rec.noOfLeaks = updated_data[0].noOfLeaks
                            rec.userLocDateTime = updated_data[0].userLocDateTime
                            rec.leakPersistence = updated_data[0].leakPersistence
                        }
                    })
                });
                resolve(_ReduxData);
            }
        });
    })

}

// Add records to emission data - Jerin
const UpdateSubSurveyDetailsList = async (id: any, formData: any, formData_redux: any, ReduxData: any) => {
    return await new Promise((resolve, reject) => {
        // Send Data to API
        SurveyDetailsService.UpdateSurveyDetailsList(formData).then((res) => {
            if (res?.length > 0) {
                let inserted_data = res[res?.length - 1]
                // After API success then remove from Redux Data
                let _ReduxData = JSON.parse(JSON.stringify(ReduxData)); //Created A Copy for rewrite object 
                let _formData = formData_redux?.Emission[0]; //Created A Copy for rewrite object 
                _ReduxData.forEach(function (obj: any) {
                    var tmpData = obj.data.filter((o: any) => o.id == id);
                    tmpData[0]?.emission.push({
                        'id': inserted_data?.id,
                        'source': inserted_data?.source,
                        'size': inserted_data?.size,
                        'description': inserted_data?.description,
                        'picture': inserted_data?.picture,
                        'pictureID': inserted_data?.pictureID,
                        'video': inserted_data?.video,
                        'videoID': inserted_data?.videoID,
                        'noOfLeaks': inserted_data?.noOfLeaks,
                        'userLocDateTime': inserted_data?.userLocDateTime,
                        'leakPersistence': inserted_data?.leakPersistence
                    })
                    tmpData[0].emissionCount = parseInt(tmpData[0]?.emissionCount) + 1
                });
                resolve(_ReduxData);
            }
        })
    })

}

// Download piture and video - Jerin
const DownloadPictureVideo = async (id: any, fileName: any) => {
    return await new Promise((resolve, reject) => {
        // Send Data to API
        SurveyDetailsService.DownloadPictureVideoService(id, fileName).then((res) => {
        })
    })

}

export { GetSurveyDetails, RemoveSurveyDetails, FilterSurveyDetailList, RemoveSurveyDetailsMultiple, GetSurveyDetailsByID, UpdateSurveyDetailsList, DeleteSubSurveyDetails, UpdateSubSurveyDetailsList, DownloadPictureVideo, FilterSurveyDetailListNew }