import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Customer from "../../interfaces/customer/customer";

export interface Todo {
    message: string;
    userData: any;
}

const AOGICommon = createSlice({
    name: "AOGICommon",
    initialState: {
        CustData: "",
        SiteData: "",
        StackedBarGraphHeight: false,
        setMapHeightPanel: false,
        setEmissionBySizeHeightPanel: false,
        setSurveyCompStatusHeightPanel: false,
        appendSitesData: "",
        isAuth: false,
        userData: {
            name: "",
            email: ""
        }
    },
    reducers: {
        addCustomersData: (state, action: any) => {
            let allIndex = action.payload?.findIndex((x: Customer) => x.name == 'All');
            if (allIndex != -1)
                action.payload[allIndex].name = "All Customers"
            state.CustData = action.payload;
            return state;
        },
        getSitesData: (state, action: any) => {
            state.SiteData = action.payload;
            return state;
        },
        appendSitesData: (state, action: any) => {
            state.SiteData = action.payload;
            return state;
        },
        SetUserAuth: (state, action: any) => {
            state.isAuth = true;
            state.userData = action.payload;
            return state;
        },
        logout: (state, action: any) => {
            state.isAuth = false;
            state.userData = {
                name: "",
                email: ""
            };
            return state;
        },
        setSurveyEmissionStackGraph: (state, action: any) => {
            state.StackedBarGraphHeight = action.payload;
            return state;
        },
        setMapHeightPanel: (state, action: any) => {
            state.setMapHeightPanel = action.payload;
            return state;
        },
        setEmissionBySizeHeightPanel: (state, action: any) => {
            state.setEmissionBySizeHeightPanel = action.payload;
            return state;
        },
        setSurveyCompStatusHeightPanel: (state, action: any) => {
            state.setSurveyCompStatusHeightPanel = action.payload;
            return state;
        }
    }
});

export const { addCustomersData, getSitesData, setSurveyEmissionStackGraph,setMapHeightPanel,setEmissionBySizeHeightPanel ,setSurveyCompStatusHeightPanel} = AOGICommon.actions;

export default AOGICommon;