// React
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// Prime UI
import { SplitButton } from 'primereact/splitbutton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
// CSS
// import 'primeflex/primeflex.css';
import { Calendar } from 'primereact/calendar';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import indexcss from './css/index.module.scss';

import { UpdateSurveyDetailsList, UpdateSubSurveyDetailsList } from '../../../services/AOGI/survey-details';
import TokenService from '../../../services/autentication/token.service';

// Images
import Leak from "../../../assets/img/icons/leak.png"
import Flare from "../../../assets/img/icons/flare.png"
import Hatch from "../../../assets/img/icons/hatch.png"
import OtherLeak from "../../../assets/img/icons/otherleak.png"
import { AddSurveyDetails } from '../../../store/AOGI/survey-details';
import { applyStyles } from '@popperjs/core';
import { Toast } from 'primereact/toast';

// loading screen
import LoadingScreen from "../../common/Loading";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import "./css/survey-emission-popup.css";


const SurveyEdit = (prop: any) => {
    const dispatch = useDispatch();
    const toast = useRef<any>(null);
    const { t } = useTranslation();
    const [emissionData, UpdateemissionData] = useState([]);
    const [sourceDropDown, UpdateSourceDropDown] = useState<any>([]);
    const [leakPeristenceDropDown, UpdateLeakPeristenceDropDown] = useState<any>([]);
    const [sizeDropDown, UpdateSizeDropDown] = useState<any>([]);
    const [hsSizeDpDown, sethsSizeDpDown] = useState<boolean>(false);
    const [curUserData, setCurUserData] = useState<any>(null);
    const [picPreview, setPicPreview] = useState<any>("");
    const [vidPreview, setVidPreview] = useState<any>(null);
    const [isLoading, isLoadingUpdate] = useState(false);
    const [loadingText, loadingTextUpdate] = useState("");
    const [datime, setDatime] = useState<Date | Date[] | undefined>(undefined);
    const [isNoEmissionSelected, setIsNoEmissionSelected] = useState(true);


    const Sourceoptions = [
        { value: 'NoEmission', label: 'No Emission' },
        { value: 'Flare', label: 'Flare' },
        { value: 'Hatch', label: 'Hatch' },
        { value: 'EnardoValve', label: 'EnardoValve' },
        { value: 'Vent', label: 'Vent' },
        { value: 'Other', label: 'Other' },
    ]

    const LeakPersistenceoptions = [
        { value: 'Intermittent', label: 'Intermittent' },
        { value: 'Persistent', label: 'Persistent' },
    ]
    useEffect(() => {
        setCurUserData(TokenService?.getUser());
        UpdateemissionData(prop.data.emission);
        if (prop.type == "outer") {
            let emissionDatas = prop.data.filter((val: any) => {
                return val.id == prop.id
            });
            UpdateemissionData(emissionDatas[0].emission);
        }
        if (prop?.editData?.id) {
            formik.setValues({
                "source": prop?.editData?.source,
                "size": prop?.editData?.size,
                "description": prop?.editData?.description,
                "picture": prop?.editData?.picture,
                "video": prop?.editData?.video,
                "id": prop?.editData?.id,
                "noOfLeaks": (prop?.editData?.noOfLeaks == null) ? 1 : prop?.editData?.noOfLeaks,
                "leakPersistence": prop?.editData?.leakPersistence,
                "userLocDateTime": prop?.editData?.userLocDateTime
            });
            UpdateSourceDropDown({ 'value': prop?.editData?.source, 'label': prop?.editData?.source })
            UpdateSizeDropDown({ 'value': prop?.editData?.size, 'label': prop?.editData?.size })
            if (prop?.editData?.picture != "null" || prop?.editData?.picture != null || prop?.editData?.picture != "undefined" || prop?.editData?.picture != undefined) {
                setPicPreview(prop?.editData?.picture)
            }
            setVidPreview(prop?.editData?.video)
            if (prop?.editData?.source !== "NoEmission") {
                sethsSizeDpDown(true)
            } else {
                sethsSizeDpDown(false)
            }
            if (prop?.editData?.leakPersistence) {
                UpdateLeakPeristenceDropDown({ 'value': prop?.editData?.leakPersistence, 'label': prop?.editData?.leakPersistence })
            }
            setDatime(new Date(moment(prop?.editData?.userLocDateTime).local().format('YYYY-MM-DD HH:mm')))
            // UpdateSourceDropDown(prop?.editData?.source)
        }
    }, [])



    const items = [
        {
            label: 'Update',
            icon: 'pi pi-refresh',
            command: () => {
                alert("update");
            }
        },
        {
            label: 'Delete',
            icon: 'pi pi-times',
            command: () => {
                alert("Delete");
            }
        },
    ]
    const formik: any = useFormik({
        initialValues: {
            source: '',
            size: '',
            description: '',
            picture: '',
            video: '',
            id: '',
            PictureFile: "",
            VideoFile: "",
            noOfLeaks: "",
            leakPersistence: "",
            userLocDateTime: "",
        },
        validationSchema: Yup.object().shape({
            source: Yup.string().required('This field is required'),
            // video: prop?.frmTitle == "Update" ? Yup.string() : Yup.string().required('This field is required'),
            video: Yup.string().when('source', {
                is: (value: any) => value === "NoEmission",
                then: Yup.string(),
                otherwise: Yup.string().required('This field is required'),
            }),
            size: Yup.string().when('source', {
                is: (value: any) => value === "NoEmission",
                then: Yup.string().nullable(),
                otherwise: Yup.string().required('This field is required'),
            }),
            noOfLeaks: Yup.number().positive().when('source', {
                is: (value: any) => value === "NoEmission",
                then: Yup.number().positive().nullable(),
                otherwise: Yup.number().typeError('This field must be a number').positive().required('This field is required').lessThan(100, "Limit should be less than 100").label("No. Of Leaks"),
            }),
            // userLocDateTime: Yup.string().required('This field is required'),
            userLocDateTime: Yup.string().when('source', {
                is: (value: any) => value === "NoEmission",
                then: Yup.string(),
                otherwise: Yup.string().required('This field is required'),
            }),
            // size: (prop?.editData?.source != "NoEmission") ? Yup.string().required('This field is required') : Yup.string().nullable(),
            // noOfLeaks: (prop?.editData?.source != "NoEmission") ? Yup.number().positive().required('This field is required') : Yup.number().positive().default(0),
        }),
        onSubmit: async (values: any) => {
            const formData_redux = {
                _id: prop?.id ? prop?.id : prop?.data?.id,
                "Emission": [{
                    id: values.id ? values.id : "",
                    source: values.source,
                    size: (values.size == "null" || values.size == null || values.size == "undefined" || values.size == undefined) ? "" : values.size,
                    description: values.description,
                    picture: picPreview,
                    video: vidPreview,
                    UpdatedBy: curUserData?.firstName + " " + curUserData?.lastName,
                }]
            };
            var formData = new FormData();
            formData.append("aogiSiteID", prop?.id ? prop?.id : prop?.data?.id);
            formData.append("EmissionID", values.id ? values.id : "");
            formData.append("Source", values.source);
            formData.append("Size", (values.size == "null" || values.size == null || values.size == "undefined" || values.size == undefined) ? "" : values.size);
            formData.append("Description", values.description);
            formData.append("Picture", picPreview);
            formData.append("Video", vidPreview);
            formData.append("UpdatedBy", curUserData?.firstName + " " + curUserData?.lastName);
            formData.append("PictureFile", values.PictureFile != undefined ? values.PictureFile : "");
            formData.append("VideoFile", values.VideoFile != undefined ? values.VideoFile : "");
            formData.append("noOfLeaks", values.noOfLeaks);
            formData.append("CustomerID", prop?.customerId);
            formData.append("SurveyID", prop?.surveyId);
            formData.append("SiteName", prop.siteName);
            formData.append("leakPersistence", values.leakPersistence);
            formData.append("userLocDateTime", moment.utc(moment(values.userLocDateTime)).format());
            if (prop?.frmTitle == "Update") {
                var existingData = prop?.mainData?.[0].data?.length > 0 ? prop?.mainData?.[0].data : prop?.mainData;
                loadingTextUpdate("Processing...");
                isLoadingUpdate(true);

                let res = await UpdateSurveyDetailsList(prop?.editData?.id, formData, formData_redux, existingData);
                if (res) {
                    let test = [{
                        "id": prop?.mainData?.[0]?.id,
                        "data": res
                    }]
                    dispatch(AddSurveyDetails(test as any));
                    prop.UpdateSurveyUpdatedDetailsData(test);
                    prop.UpdateCustomToast("Update");
                    setTimeout(() => {
                        isLoadingUpdate(false);
                        loadingTextUpdate("");
                    }, 3000)

                }
            } else if (prop?.frmTitle == "Add") {
                loadingTextUpdate("Processing...");
                isLoadingUpdate(true);

                let res = await UpdateSubSurveyDetailsList(prop?.id, formData, formData_redux, prop?.originalData)
                if (res) {
                    dispatch(AddSurveyDetails(res as any));
                    prop.UpdateSurveyUpdatedDetailsData(res);
                    prop.setDisplayBasic(false)
                    prop.UpdateCustomToast("Add");
                    isLoadingUpdate(false);
                    loadingTextUpdate("");

                }
            }
        },
    });

    const handleSourceChange = (e: any) => {
        if (e?.value) {
            formik.setFieldValue('source', e?.value);
            UpdateSourceDropDown({ 'value': e?.value, 'label': e?.label })
            if (e?.value !== "NoEmission") {
                sethsSizeDpDown(true)
                setIsNoEmissionSelected(true)
            } else {
                sethsSizeDpDown(false)
                setIsNoEmissionSelected(false)
            }
        }
    }

    const handleSizeChange = (e: any) => {
        if (e?.value) {
            formik.setFieldValue('size', e?.value);
            UpdateSizeDropDown({ 'value': e?.value, 'label': e?.label })
        }
    }

    const handlePictureChange = (e: any) => {
        let pictureFile = e.target.files[0];
        // setPicPreview(URL.createObjectURL(pictureFile))
        setPicPreview(pictureFile?.name)
        formik.setFieldValue('PictureFile', pictureFile);
        formik.setFieldValue('picture', pictureFile?.name);
    }

    const handleVideoChange = (e: any) => {
        let videoFile = e.target.files[0];
        setVidPreview(videoFile?.name)
        formik.setFieldValue('VideoFile', videoFile);
        formik.setFieldValue('video', videoFile?.name);
    }

    const handleLeakChange = (e: any) => {
        if (e?.value) {
            formik.setFieldValue('leakPersistence', e?.value);
            UpdateLeakPeristenceDropDown({ 'value': e?.value, 'label': e?.label })
        }
    }

    const handleUserTimeDate = (e: any) => {
        if (e?.value) {
            formik.setFieldValue('userLocDateTime', e?.value);
            setDatime(e.value)
        }
    }

    return <>
        <LoadingScreen isLoading={isLoading} text={loadingText} />
        <div className="">
            <section>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div className="card h-100">
                            <div className="card-body">

                                {/* Block styled form */}
                                <div className='survey-edit-popup'>
                                <form className="row g-3" onSubmit={formik.handleSubmit} encType={'multipart/form-data'}>
                                    <div className='row g-3 col-md-12'>
                                        <div className={"col-md-5"}>
                                            <label htmlFor="_dm-inputEmail2" className="form-label">{t("SurveyDetails.SourceLabel")} <b><span className="text-danger"> *</span></b></label>
                                            <Select
                                                className={formik.touched.source && formik.errors.source ? ' basic-single' : 'basic-single'}
                                                classNamePrefix="select"
                                                placeholder={'Choose an item'}
                                                id="source"
                                                name="source"
                                                options={Sourceoptions}
                                                value={sourceDropDown}
                                                onChange={(e) => handleSourceChange(e)}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.source && formik.errors.source ? <div className="text-red">{formik.errors.source}</div> : null}
                                        </div>
                                        {
                                            hsSizeDpDown == true ?
                                                <>
                                                    <div className={"col-md-4"}>
                                                        <label htmlFor="_dm-inputEmail2" className="form-label">{t("SurveyDetails.SizeLabel")}  <b><span className="text-danger"> *</span></b></label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            placeholder={'Choose an item'}
                                                            id="size"
                                                            name="size"
                                                            options={[{ value: 'Small', label: 'Small' },
                                                            { value: 'Medium', label: 'Medium' },
                                                            { value: 'Large', label: 'Large' }]}
                                                            value={sizeDropDown}
                                                            onChange={(e) => handleSizeChange(e)}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                        {formik.touched.size && formik.errors.size ? <div className="text-red">{formik.errors.size}</div> : null}
                                                    </div>
                                                    <div className={"col-md-3"}>
                                                        <label htmlFor="_dm-inputEmail2" className="form-label">{t("SurveyDetails.TableAddNumberLabel")} <b><span className="text-danger"> *</span></b></label>
                                                        <input style={{ width: "88%", paddingBottom: "8px" }} type={"text"} name={'noOfLeaks'} className="p-inputtext p-component" onChange={formik.handleChange} value={formik.values.noOfLeaks} onBlur={formik.handleBlur} />
                                                        {formik.touched.noOfLeaks && formik.errors.noOfLeaks ? <div className="text-red" style={{ width: "172%" }}>{formik.errors.noOfLeaks}</div> : null}
                                                    </div>
                                                </>
                                                : ''
                                        }
                                    </div>
                                    <div className={"col-md-5"}>
                                        <label htmlFor="_dm-inputPassword2" className="form-label">{t("SurveyDetails.PictureLabel")}</label><br></br>
                                        <label htmlFor="choose-file" className="border-0">
                                            <i className="form-control border-0 fa fa-paperclip fa-6" aria-hidden="true" style={{ fontSize: '20px' }} aria-label="upload"></i>
                                        </label>
                                        <input style={{ display: "none" }} accept="image/gif, image/jpeg, image/png" id="choose-file" type="file" name={'picture'} onChange={(e) => handlePictureChange(e)} />
                                        {(picPreview == "null" || picPreview == null) ? "" : picPreview}
                                    </div>
                                    <div className="col-md-7">
                                        <label htmlFor="_dm-inputPassword2" className="form-label">{t("SurveyDetails.VideoLabel")}{isNoEmissionSelected==true? <b><span className="text-danger"> *</span></b> : "" }</label><br></br>
                                        <label htmlFor="choose-file2" className="border-0">
                                            <i className="form-control border-0 fa fa-video-camera fa-6" aria-hidden="true" style={{ fontSize: '20px' }} aria-label="upload2"></i>
                                        </label>
                                        <input style={{ display: "none" }} id="choose-file2" type="file" name={'video'} onChange={(e) => handleVideoChange(e)} accept="video/mp4,video/x-m4v,video/*" />
                                        {vidPreview ? vidPreview : prop?.editData?.video}
                                        {formik.touched.video && formik.errors.video ? <div className="text-red">{formik.errors.video}</div> : null}
                                    </div>
                                    <div className="col-sm-5 col-md-5 col-lg-5">
                                        <label htmlFor="_dm-inputPassword2" className="form-label">{t("SurveyDetails.LeakPersistanceLabel")}</label><br></br>

                                        <Select
                                            className={'basic-single'}
                                            classNamePrefix="select"
                                            placeholder={'Choose an item'}
                                            id="leak_persistence"
                                            name="leak_persistence"
                                            options={LeakPersistenceoptions}
                                            value={leakPeristenceDropDown}
                                            onChange={(e) => handleLeakChange(e)}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="col-sm-7 col-md-7 col-lg-7">
                                        <label htmlFor="_dm-inputPassword2" className="form-label">{t("SurveyDetails.SurveyDateTimeLabel")} ({prop?.surveyTimeZone}) {isNoEmissionSelected==true? <b><span className="text-danger"> *</span></b> : "" }</label><br></br>
                                        <Calendar id="datime" name='userLocDateTime' value={datime} onChange={(e: any) => handleUserTimeDate(e)} showTime showIcon hourFormat="12" style={{ width: "94%" }} />
                                        {formik.touched.userLocDateTime && formik.errors.userLocDateTime ? <div className="text-red">{formik.errors.userLocDateTime}</div> : null}
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="_dm-inputZip" className="form-label">{t("SurveyDetails.DescriptionLabel")}</label>
                                        <textarea id="_dm-inputZip" name={'description'} className="form-control" rows={3} cols={8} onChange={formik.handleChange} value={formik.values.description} onBlur={formik.handleBlur}></textarea>
                                        {formik.touched.description && formik.errors.description ? <div className="text-red">{formik.errors.description}</div> : null}
                                    </div>

                                    <div className="" style={{ textAlign: 'right' }}>
                                        <button type="submit" className="btn btn-primary">
                                            {
                                                prop?.frmTitle == "Update" ? t("UpdateButton") : t("AddButton")
                                            }
                                        </button>&nbsp;&nbsp;&nbsp;
                                        <button type="button" className="btn btn-outline-light md-2" onClick={() => { prop?.frmTitle == "Add" ? prop.setDisplayBasic(false) : prop.setDisplaySubBasic(false) }}>{t("CancelButton")}</button>
                                    </div>

                                </form>
                                {/* END : Block styled form */}
                            </div>
                        </div>
                    </div>
                </div>
        </div>

            </section>
            <span className="p-error">
                {t("MandatoryTextFileds")}
            </span>
        </div>

    </>
}

export default SurveyEdit;