import { memo, useEffect, useState } from 'react';
import { GoogleMap, LoadScript, MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api';
import { Link } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import PopEmissionData from './pop-emission-data';


const SimpleMap = (props: any) => {
  const om: any = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
  const setHeight = props.height;
  const [oms, setOms] = useState<any>(null);
  const [selectedPlace, setSelectedPlace] = useState<any>(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [hideHeli, setHideHeli] = useState(false);
  const [map, setMap] = useState<any>(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const [titlePopUp, setTitlePopUp] = useState<string>("");
  const [latLon, setLatLon] = useState({
    lat: 32.43104345,
    lng: -104.0942642
  })

  let HelicopterIcon = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  let BlueHelicopterIcon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
  let GreenHelicopterIcon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';


  useEffect(() => {
    if (map) {
      map.setZoom(4);
      // setMap(map)
    }
    if (props?.data?.filteredValues) {
      setHideHeli(false)
    }
  }, [props?.data?.filteredValues])

  const onLoad = (map: any) => {
    setMap(map)
    var newOms = new om.OverlappingMarkerSpiderfier(map,
      {
        markersWontMove: true,   // we promise not to move any markers, allowing optimizations
        markersWontHide: true,   // we promise not to change visibility of any markers, allowing optimizations
        //basicFormatEvents: true  // allow the library to skip calculating advanced formatting information
        ignoreMapClick: true,
        //nearbyDistance: 10,
        spiralFootSeparation: 32,
        spiralLengthFactor: 5,
        spiralLengthStart: 14,
        circleFootSeparation: 40
      });

    setOms(newOms);
  };

  const markerClickHandler = (event: any, place: any, marker: any) => {
    // Remember which place was clicked
    setInfoOpen(false);
    setSelectedMarker(marker);
    setSelectedPlace(place);
    setInfoOpen(true);
  };


  const setMapContainerHeight: any = (setHeight) ? '550px' : '350px';
  const mapContainerStyle: any = {
    height: setMapContainerHeight,
    width: '100%',
  }
  let locations: any = [];
  let new_locations: any = [];
  let sorted_data: any = props?.data?.filteredValues;
  let maxLength = Math.max(...sorted_data.map((edge: any) => edge.data.length));

  if (props?.data?.filteredValues?.length > 0) {
    locations = [];
    let isNotDone = true;
    props?.data?.filteredValues?.map((val: any) => {

      if (maxLength == val.data.length && isNotDone) {
        new_locations = [];
        isNotDone = false;
        val.data.map((val_data: any) => {
          let obj: any = { lat: Number(val_data.gmap.lat), lng: Number(val_data.gmap.lon), title: String(val_data.name), }
          new_locations.push(obj);

        })
      }
      val.data.map((val_data: any) => {
        let obj: any = { lat: Number(val_data.gmap.lat), lng: Number(val_data.gmap.lon), title: String(val_data.name), }
        locations.push(obj);

      })
    });

  };

  let final_new_location: any = [];
  new_locations.forEach((val: any) => {
    props?.data?.filteredValues?.map((emissionData: any, n: any) => {
      emissionData?.data?.map((coreData: any) => {

        if (val.title == coreData.name) {
          if (coreData.emission.length > 0) {

            var noEmiFlg = 0;

            coreData.emission.map((newVal: any) => {
              if (newVal?.source != "NoEmission") {
                noEmiFlg = 1;
              } else if (newVal?.source == "NoEmission") {
                noEmiFlg = 2;
              }
            })

            final_new_location.push({ lat: val.lat, lng: val.lng, title: val.title, hasEmission: noEmiFlg, emission: coreData.emission, i: val.title });
          } else {
            final_new_location.push({ lat: val.lat, lng: val.lng, title: val.title, hasEmission: 0, emission: coreData.emission, i: val.title });

          }
        }
      })
    })
  });



  // Test Start


  let arr: any = final_new_location;

  let Unique = (arr: any) => {
    //To store the unique sub arrays
    let uniques = [];

    //To keep track of the sub arrays
    let itemsFound: any = {};

    for (let val of arr) {
      //convert the sub array to the string
      let stringified: any = JSON.stringify(val);

      //If it is already added then skip to next element
      if (itemsFound[stringified]) {
        continue;
      }

      //Else add the value to the unique list
      uniques.push(val);

      //Mark it as true so that it can tracked
      itemsFound[stringified] = true;
    }

    //Return the unique list
    return uniques;
  }

  let filtered = Unique(arr);
  final_new_location = [];
  filtered.sort(function (a: any, b: any) { return b.hasEmission - a.hasEmission; });

  filtered?.map((val: any) => {

    filtered?.map((val1: any) => {
      if (val.title == val1.title) {
        if (val1.hasEmission == 1) {
          let flag = final_new_location.filter((obj: any) => obj.title == val1.title && obj.hasEmission == 1);
          if (flag.length == 0) {
            final_new_location.push(val1);

          }
        } else if (val1.hasEmission == 0) {
          let flag = final_new_location.filter((obj: any) => obj.title == val1.title && obj.hasEmission == 0);
          if (flag.length == 0) {
            final_new_location.push(val1);
          }
        } else if (val1.hasEmission == 2) {
          let flag = final_new_location.filter((obj: any) => obj.title == val1.title && obj.hasEmission == 2);
          if (flag.length == 0) {
            final_new_location.push(val1);
          }
        }
      }
    })
  })

  // Test End
  const center: any = { lat: 32.43104345, lng: -104.0942642 }


  const options = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }


  const openSite = (e: any, rdata: any) => {
    var selectedCustomer: any = localStorage.getItem('customer-selected') || "";
    selectedCustomer = JSON.parse(selectedCustomer)

    setShowPopUp(true)
    setTitlePopUp(rdata?.i)
    // titlePopUp
    const mainEmissionData: any = {
      cid: props?.data?.customer?.customerID,
      id: props?.data.filteredValues[0].id,
      rdata: rdata,
      customerName: selectedCustomer?.dashboardSelectedCustomer?.name
    }
    setLatLon({
      lat: rdata?.lat,
      lng: rdata?.lng
    })
    setPopUpData(mainEmissionData);
  }
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCdsXnMXcV_vTCPjTd5tHGCi2Oed3hVKEQ"
    >

      <GoogleMap onLoad={onLoad} id='marker-example' mapContainerStyle={mapContainerStyle} zoom={4} center={latLon} options={{ mapTypeId: "hybrid" }}>

        {(() => {
          if (final_new_location?.length == 1) {
            let displayHeliCopIcon: any;
            final_new_location[0].emission?.map((itm: any) => {
              if (final_new_location[0]?.hasEmission == 0) {
                displayHeliCopIcon = BlueHelicopterIcon;
              } else {
                displayHeliCopIcon = HelicopterIcon;
              }
            })
            return (
              <Marker position={{ lat: final_new_location[0]?.lat, lng: final_new_location[0]?.lng }} onClick={(e) => openSite(e, final_new_location[0])}
                icon={{
                  url: displayHeliCopIcon, // The URL of the icon image
                  scaledSize: new window.google.maps.Size(50, 50), // Specify the desired size here
                }} />
            )
          } else {
            return <MarkerClusterer maxZoom={15} onClick={() => setHideHeli(true)}>

              {(clusterer) =>

                final_new_location.map((location: any, lid: any) => {
                  let displayHeliCopIcon: any;
                  if (location.hasEmission == 0) {
                    displayHeliCopIcon = BlueHelicopterIcon;
                  } else if (location.hasEmission == 2) {
                    displayHeliCopIcon = GreenHelicopterIcon;
                  } else {
                    displayHeliCopIcon = HelicopterIcon;
                  }
                  return <Marker
                    visible={hideHeli}
                    key={lid}
                    position={{ lat: location.lat, lng: location.lng }}
                    clusterer={clusterer}
                    title={location?.title}
                    icon={{
                      url: displayHeliCopIcon, // The URL of the icon image
                      scaledSize: new window.google.maps.Size(50, 50), // Specify the desired size here
                    }}
                    onClick={(e) => openSite(e, location)} onLoad={marker => {
                      oms.addMarker(marker);
                    }}
                  />
                })
              }

            </MarkerClusterer>
          }
        })()}
        <Dialog header={titlePopUp} visible={showPopUp} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => setShowPopUp(false)} className={'create-modal-popup'}>
          <PopEmissionData eData={popUpData} displaySiteBtn={true} />
        </Dialog>
      </GoogleMap>

    </LoadScript>

  )
}

export default memo(SimpleMap);