
// CSS
import './dashboard.css';

import { useTranslation } from "react-i18next";

// components for common panel
import CommonPanel from '../../common/common-panel';
import EmissionOverView from './common-panel-components/emission-over-view';
import EmissionBySource from './common-panel-components/emission-by-source';
import EmissionBySize from './common-panel-components/emission-by-size';
import SitesWithConitnuesEmission from './common-panel-components/site-with-continous-emission';
import AllSurveys from "./common-panel-components/all-surveys-table"
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useRef, useState } from "react";
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import CustomerService from '../../../services/customer/customer.service';
import { addCustomersData } from '../../../store/AOGI/common';
import { AppDispatch, AppState } from '../../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import DashboardService from '../../../services/dashboard/dashboard.service';
import DashboardStore, { addDashboardStoreData } from '../../../store/AOGI/dashboard.store';
// Loading Screen
import LoadingScreen from "../../common/Loading";
import PersistantService from '../../../services/autentication/persistant.service';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { FilterSurveyDetailListNew } from '../../../services/AOGI/survey-details';

const Dashboard = () => {

    document.title = "AOGI-Dashboard";
    const { t } = useTranslation();
    const toast = useRef<any>(null);
    let customer = PersistantService.getPersistSelectedCustomer()?.dashboardSelectedCustomer;

    const dispatch: AppDispatch = useDispatch();
    const [isLoading, isLoadingUpdate] = useState(false);
    const [loadingText, loadingTextUpdate] = useState("");
    const CustData_Redux = useSelector((state: AppState) => state.AOGICommon.CustData);
    const [custList, custListUpdate] = useState<any>([]);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(customer ?? 'All Customers');
    const [selectedName, setSelectedName] = useState<any>(null);
    const [selectedNameForVal, setSelectedNameForVal] = useState<any>(null);
    const [surveyDate, setSurveyDate] = useState<any>(null);
    const [surveyData, setSurveyData] = useState<any>(null);
    const [filteredValues, setFilteredValues] = useState<any>([]);
    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    const [selectedCompStatus, setSelectedCompStatus] = useState("");
    const [surveyNames, setSurveyNames] = useState([
    ]);

    const statusArr = [{ value: 'All', label: 'All' }, { value: 'Open', label: 'Open' }, { value: 'Completed', label: 'Completed' }]
    const groupedDpdowns = [
        {
            label: 'Size',
            items: [
                { label: 'Small', value: 'Small' },
                { label: 'Medium', value: 'Medium' },
                { label: 'Large', value: 'Large' },
            ]
        },
        {
            label: 'Source',
            items: [
                { label: 'Flare', value: 'Flare' },
                { label: 'Hatch', value: 'Hatch' },
                { label: 'Enardo Valve', value: 'Enardo Valve' },
                { label: 'Vent', value: 'Vent' },
                { label: 'Other', value: 'Other' },
                { label: 'No Emission', value: 'No Emission' },
            ]
        },
        {
            label: 'Leak Persistence',
            items: [
                { label: 'Persistent', value: 'Persistent' },
                { label: 'Intermittent', value: 'Intermittent' },
            ]
        }
    ];

    useEffect(() => {
        isLoadingUpdate(true);
        setSurveyDate(new Date())
        if (custList == "") {
            CustomerService.getCustomerList().then((res) => {
                dispatch(addCustomersData(res as any));
                custListUpdate(res);
                if (res.length == 1) {
                    setSelectedCustomer(res[0]);
                }
            });
        } else {
            if (custList.length == 1) {
                setSelectedCustomer(custList[0]);
            }
            custListUpdate(custList);
        }
        setTimeout(() => {
            isLoadingUpdate(false);
        }, 2000);

    }, []);


    const setSurveyCheckList = (res: any) => {
        let allSurvey: any = [];
        let surveyNames = [...allSurvey];
        const sortedData = [...res];
        sortedData.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        sortedData?.forEach((val: any) => {
            let TempList = [{ "name": val.name }];
            surveyNames = [...surveyNames, ...TempList]
        });

        setSurveyNames(surveyNames as any);
        // setSelectedName(surveyNames as any)
        setSelectedName([
            {
                "name": surveyNames[0]?.name
            }
        ])
        setSelectedNameForVal(surveyNames[0]?.name)
        setSelectedStatus('All')
    }
    // Filter Events
    // 1) Year 
    const setSurveyYearSelected = (event: any) => {
        event.preventDefault();
        let selectedDate = new Date(event.value);
        setSurveyDate(selectedDate);
    }
    // 2) Survey List
    useEffect(() => {

        isLoadingUpdate(true);

        if (selectedCustomer) {

            let Cust_ID: any = selectedCustomer;
            Cust_ID = Cust_ID?.customerID;


            let YearSelected = convert(surveyDate);
            DashboardService.getSurveyList(Cust_ID, YearSelected).then((res) => {
                if (res == "") {
                    res = [];
                    setTimeout(() => {
                        isLoadingUpdate(false);
                    }, 1000)
                } else {
                    setTimeout(() => {
                        isLoadingUpdate(false);

                    }, 2000);
                }
                setSurveyData(res);
                dispatch(addDashboardStoreData(res));
                setSurveyCheckList(res);
                filterValues();


            });
        } else {
            setTimeout(() => {
                isLoadingUpdate(false);
                toast?.current?.clear();
                toast?.current?.show({ severity: 'error', summary: 'No Data', detail: 'Please Select Customer', life: 3000 });
            }, 2000)
        }



    }, [selectedCustomer, surveyDate])

    useEffect(() => {
        filterValues();
        setSelectedCompStatus("")
        setSelectedStatus("")
        setShowCompleted(false)
    }, [selectedName, surveyData])

    function convert(str: any) {
        var date = new Date(str);

        return [date.getFullYear()].join("-");
    }

    const filterValues = () => {

        let filteredSurveyList = surveyData;
        if (typeof filteredSurveyList == 'object') {

            if (selectedName && selectedName.length > 0) {
                filteredSurveyList = filteredSurveyList?.filter((surveyItem: any) => {
                    return selectedName.find((statusItem: any) => {
                        return surveyItem.name?.toLowerCase() == statusItem.name?.toLowerCase()

                    }) != undefined;
                });

            } else {
                filteredSurveyList = [];
            }
            setFilteredValues(filteredSurveyList);
        }


    }


    useEffect(() => {
        if (selectedStatus != "All") {
            let filteredSurveyStatusList = surveyData;
            if (filteredSurveyStatusList?.length > 0) {
                filteredSurveyStatusList = filteredSurveyStatusList?.filter((surveyItem: any) => {
                    return selectedName.find((statusItem: any) => {
                        return surveyItem.name?.toLowerCase() == statusItem.name?.toLowerCase()

                    }) != undefined;
                });
            }
            if (filteredSurveyStatusList?.[0]?.data && selectedStatus?.length > 0) {
                let existingData = [{
                    data: filteredSurveyStatusList?.[0]?.data,
                    date: filteredSurveyStatusList?.[0].date,
                    id: filteredSurveyStatusList?.[0].id,
                    name: filteredSurveyStatusList?.[0].name,
                    status: filteredSurveyStatusList?.[0].status,
                    surveyTimeZone: filteredSurveyStatusList?.[0].surveyTimeZone
                }]
                FilterSurveyDetailListNew(filteredSurveyStatusList?.[0]?.data, selectedStatus, selectedCompStatus).then((res) => {
                    existingData[0].data = res;
                    setFilteredValues(JSON.parse(JSON.stringify(existingData)))
                })
            }
        } else {
            let filteredSurveyList = surveyData;
            if (typeof filteredSurveyList == 'object') {

                if (selectedName && selectedName.length > 0) {
                    filteredSurveyList = filteredSurveyList?.filter((surveyItem: any) => {
                        return selectedName.find((statusItem: any) => {
                            return surveyItem.name?.toLowerCase() == statusItem.name?.toLowerCase()

                        }) != undefined;
                    });

                } else {
                    filteredSurveyList = [];
                }
                setFilteredValues(filteredSurveyList);
            }
        }
    }, [selectedStatus, selectedCompStatus])

    const groupedItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{option.label}</div>
            </div>
        );
    };
    return <>
        <Toast ref={toast} style={{ marginTop: "25px" }} />
        <LoadingScreen isLoading={isLoading} text={loadingText} />
        <ConfirmDialog />
        <div className="content__header content__boxed overlapping">
            <div className="content__wrap pt-1 pb-2">



                <p className="lead text-overflow">{t("Dashboard.HeaderText")}</p>

                <div className="surveypage-heading">
                    <div className="row bg-primary" id="divCustFilter">
                        <div className="row align-items-end">
                            <div className="col-lg-1 col-md-2 col-sm-4 col-xs-4">
                                <label className="dashboard-title">{t("CustomerLabel")}</label>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4">
                                <Dropdown className="dashboardDropdown" value={selectedCustomer} options={custList?.filter((x: any) => x._id != "000000000000000000000000")} onChange={(e: any) => {
                                    setSelectedCustomer(e.value);
                                    PersistantService.setPersistSelectedCustomer({ dashboardSelectedCustomer: e.value });
                                }} optionLabel="name" filter filterBy="name" placeholder={t("Dashboard.IndexCustomer")} />
                            </div>
                            <div className="col-lg-1 col-md-2 col-sm-4 col-xs-4">
                                <label className="dashboard-dateTitle">{t("DuringLabel")}</label>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4 ">
                                <Calendar style={{ width: "220px" }} id="yearpicker" view="year" dateFormat="yy" className="survey_calender" value={surveyDate} onChange={(e) => setSurveyYearSelected(e)} placeholder={surveyDate} showIcon></Calendar>
                            </div>
                            <div className="col-lg-1 col-md-2 col-sm-4 col-xs-4">
                                <label className="dashboard-title">{t("SurveyNameLabel")}</label>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4">
                                <Dropdown
                                    className="dashboardDropdown"
                                    value={selectedNameForVal}
                                    options={surveyNames}
                                    optionLabel="name"
                                    optionValue="name"
                                    onChange={(e) => {
                                        setSelectedNameForVal(e?.value)
                                        setSelectedName([
                                            {
                                                "name": e?.value
                                            }
                                        ])
                                    }}
                                    placeholder={t("Survey.IndexName")} />
                                {/* <MultiSelect className="dashboard_multiselect" value={selectedName} options={surveyNames} onChange={(e: any) => { setSelectedName(e.value); }} optionLabel="name" filter filterBy="name" placeholder={t("Dashboard.IndexStatus")} selectAll={true} maxSelectedLabels={3} /> */}
                            </div>
                        </div>
                        <div className="row align-items-end">
                            <div className="col-lg-1 col-md-2 col-sm-4 col-xs-4">
                                <label className="dashboard-title">{t("Survey.TableStatus")}</label>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4">
                                <Dropdown
                                    className="dashboardDropdown"
                                    value={selectedStatus}
                                    options={statusArr}
                                    optionLabel="label"
                                    onChange={(e) => {
                                        setSelectedStatus(e?.value)
                                        if (e?.value == "Completed") {
                                            setShowCompleted(true)
                                        } else {
                                            setShowCompleted(false)
                                        }
                                    }}
                                    placeholder={t("Survey.IndexStatus")} />
                            </div>

                            <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4 ">
                                {
                                    showCompleted == true ?

                                        <MultiSelect
                                            value={selectedCompStatus}
                                            options={groupedDpdowns}
                                            onChange={(e) => {
                                                setSelectedCompStatus(e.value)
                                                if (e?.value == "Completed") {
                                                    setShowCompleted(true)
                                                }
                                            }}
                                            optionLabel="label"
                                            optionGroupLabel="label"
                                            optionGroupChildren="items"
                                            optionGroupTemplate={groupedItemTemplate}
                                            placeholder="Choose an item"
                                            display="chip"
                                            className="dashboardmultiselectdp"
                                            style={{ width: '300px', backgroundColor: 'transparent', border: '1px solid transparent' }}
                                        /> : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div className="content__boxed dashboard_aogi_content_box_first">
            <div className="content__wrap">
                <div className="dashboard_main_grid_div">
                    <div className="card emission-overview-map">
                        <CommonPanel InnerComponent={<EmissionOverView filteredValues={filteredValues} customer={selectedCustomer} />} Heading={t("Dashboard.EmissionOverviewHeader")} />

                    </div>
                    <br className='d-md-none' />
                    <div className="card emission-by-source">
                        <CommonPanel InnerComponent={<EmissionBySource filteredValues={filteredValues} customer={selectedCustomer} />} Heading={t("Dashboard.EmissionBySourceHeader")} />
                    </div>
                    <br className='d-md-none' />
                    <div className="card emission-overview-map">
                        <CommonPanel InnerComponent={<EmissionBySize filteredValues={filteredValues} customer={selectedCustomer} />} Heading={t("Dashboard.EmissionBySizeHeader")} />
                    </div>
                    <br className='d-md-none' />
                    <div className="card sites-with-conitnues-emission">
                        <CommonPanel InnerComponent={<SitesWithConitnuesEmission filteredValues={filteredValues} />} Heading={t("Dashboard.SiteWithContinousEmissionHeader")} />
                    </div>
                    <br className='d-md-none' />
                    <div className="card all-surveys-table">
                        <CommonPanel InnerComponent={<AllSurveys filteredValues={filteredValues} />} Heading={t("Dashboard.AllSurveyTableHeader")} />
                    </div>
                </div>




            </div>
        </div>

    </>
}

export default Dashboard;