import { useState, useRef, useEffect } from "react";
import { Calendar } from 'primereact/calendar';
import "./survey.css";
import { useFormik } from 'formik';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import Select from 'react-select';
import moment from 'moment'
import TokenService from "../../../services/autentication/token.service";
import SurveyService from "../../../services/survey/survey.service";
import CustomerService from "../../../services/customer/customer.service";
import AOGISurvey from "../../../interfaces/aogi-survey/aogi-survey";
import Customer from "../../../interfaces/customer/customer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../../store/store";
import { addSurveyData } from "../../../store/AOGI/survey";
import { addCustomersData } from "../../../store/AOGI/common";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { string } from "yup";

interface AddOrEditSurveyProps {
  aogiSurvey?: AOGISurvey,
  refreshSurveyTable?: any,
  visible: boolean,
  setAddEditSurveyModalShow: any,
  isEditSurvey: boolean,
  statusValues?: any,
  setSurveyDate: any,
  timezoneValues?: any,
}

const AddOrEditSurvey = (props: AddOrEditSurveyProps) => {

  const timezoneValues = [
    {
      "name": "Hawaii Standard Time", "code" :"HST"
    },
    {
      "name": "Alaska Daylight Time", "code" :"AKDT"
    },
    {
      "name": "Pacific Daylight Time", "code" :"PDT"
    },
    {
      "name": "Mountain Daylight Time", "code" :"MDT"
    },
    {
      "name": "Central Daylight Time", "code" :"CDT"
    },
    {
      "name": "Eastern Daylight Time", "code" :"EDT"
    }
  ];
  const [selectedTimezone, setSelectedTimezone] = useState<any>("");

  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const Survey_Redux = useSelector((state: AppState) => state.AOGISurveyIndex.SurveyData);
  const CustData_Redux = useSelector((state: AppState) => state.AOGICommon.CustData);

  const toast = useRef<any>(null);
  const [curUserData, setCurUserData] = useState<any>(null);
  const [customersList, setCustomersList] = useState<Customer[]>([]);

  useEffect(() => {
    setCurUserData(TokenService?.getUser());
    if (CustData_Redux == "") {
      CustomerService.getCustomerList().then((customerData: Customer[]) => {
        setCustomersList(customerData);

        if (props?.aogiSurvey?._id)
          surveyForm.setFieldValue('customer', customersList.find((customer: Customer) => props?.aogiSurvey?.customerID == customer.customerID));

      });
    } else {
      dispatch(addCustomersData(CustData_Redux));
      setCustomersList(CustData_Redux as any);

      if (props?.aogiSurvey?._id)
        surveyForm.setFieldValue('customer', customersList.find((customer: Customer) => props?.aogiSurvey?.customerID == customer.customerID));
    }

  }, []);

  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.customer) {
      errors.customer = 'Please select a customer';
    }
    if (!values.surveyDate) {
      errors.surveyDate = 'Please select date';
    }
    if (!values.surveyName) {
      errors.surveyName = 'Please enter name';
    }
    if (!values.status) {
      errors.status = 'Please select a status';
    }
    if (!values.surveyTimeZone) {
      errors.surveyTimeZone = 'Please select a timezone';
    }
    return errors;

  };

  const surveyForm: any = useFormik({
    initialValues: {
      _id: "",
      customerId: "",
      customer: null,
      surveyName: "",
      surveyDate: null,
      status: { "status": "OPEN" },
      surveyTimeZone: "",
    },
    validate,
    onSubmit: async (values: any) => {

      var surveyFormData: AOGISurvey = {
        customerID: values?.customer?.customerID,
        customerName: values?.customer?.name,
        surveyName: values.surveyName,
        surveyDate: localToUtc(values.surveyDate),
        status: values.status?.status,
        surveyTimeZone: values.surveyTimeZone,
        createdBy: curUserData?.firstName + " " + curUserData?.lastName,
        updatedBy: curUserData?.firstName + " " + curUserData?.lastName,
      };
      if (!props?.isEditSurvey) {

        await SurveyService.addSurvey(surveyFormData).then((response: any) => {
          if (response == "SUCCESS" || response == true) {
            toast?.current?.show({ severity: 'success', summary: 'Success Message', detail: 'Initiated successfully', life: 7000 });
            setTheSurveyForm(true);
            dispatch(addSurveyData(""));
            props.setSurveyDate(values.surveyDate)
          } else {
            toast?.current?.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong! ' + response, life: 7000 });
          }
        });
      }
      else {

        surveyFormData._id = values._id;

        await SurveyService.updateAogiSurvey(surveyFormData).then((response: any) => {
          if (response == "SUCCESS" || response == true) {
            toast?.current?.show({ severity: 'success', summary: 'Success Message', detail: 'Updated successfully', life: 7000 });
            setTheSurveyForm(true);
            dispatch(addSurveyData(""));
            props.setSurveyDate(values.surveyDate)
          } else {
            toast?.current?.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong! ' + response, life: 7000 });
          }
        });
      }
    },
  });

  useEffect(() => {
    if (props?.aogiSurvey?._id && props?.visible) {
      surveyForm.setValues({
        "customerId": props?.aogiSurvey?.customerID,
        "surveyName": props?.aogiSurvey?.surveyName,
        "status": { "status": props?.aogiSurvey?.status },
        "surveyDate": props?.aogiSurvey?.surveyDate ? new Date(props?.aogiSurvey?.surveyDate) : null,
        "_id": props?.aogiSurvey?._id,
        "surveyTimeZone": props?.aogiSurvey?.surveyTimeZone,
      });
      setSelectedTimezone(props?.aogiSurvey?.surveyTimeZone)
      surveyForm.setFieldValue('customer', customersList.find((customer: Customer) => props?.aogiSurvey?.customerID == customer.customerID),);
    }
  }, [props?.aogiSurvey, props?.visible])


  const setTheSurveyForm = (isRefreshRequired: boolean) => {
    surveyForm.resetForm();
    setSelectedTimezone(null);
    props.setAddEditSurveyModalShow(false);
    if (isRefreshRequired) {
      props.refreshSurveyTable();
    }

  }

  const isFormFieldValid = (name: string) => {
    return !!(surveyForm.touched[name] && surveyForm.errors[name])
  };

  const getFormErrorMessage = (name: string) => {
    return isFormFieldValid(name) && <span className="p-error">{surveyForm.errors[name]}</span>;
  }

  const localToUtc = (date: Date): Date | undefined => {
    if (date == null) {
      return undefined;
    } else {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }
  }

  const hideModalPopUp = () => {
    setTheSurveyForm(false);
  }

  const onCustomerChange = (customer: Customer) => {
    surveyForm.setFieldValue('customer', customer);
    surveyForm.setFieldValue('customerId', customer?.customerID);
  }

  const onStausChange = (status: any) => {
    surveyForm.setFieldValue('status', { "status": status?.status });
  }

  const onDateChange = (e: any) => {
    surveyForm.setFieldValue('surveyDate', new Date(moment.utc(e?.value).toISOString()));
  }

  const onTimezoneChange = (e: any) => {
    if (e.value) {
      setSelectedTimezone(e.value)
      surveyForm.setFieldValue('surveyTimeZone',  e?.value);
    }
  }
  return <>
    <Toast ref={toast} />
    <Dialog className="create-modal-popup" {...props} header={props.isEditSurvey ? t("EditSurvey.PopupHeader") : t("CreateSurvey.PopupHeader")} modal closeOnEscape={false} closable={true}
      draggable={false} resizable={false} onHide={() => hideModalPopUp()}>
      <form onSubmit={surveyForm.handleSubmit}>
        <div className="row">
          <div className="col-lg-8 col-md-2 col-sm-4 col-xs-2">
            <label htmlFor="selectedCustomer" className={"form-label " + classNames({ 'p-error': isFormFieldValid('customer') })}>{t("CustomerLabel")} <span className="required-star"> &nbsp;*</span></label>
            <span className="p-float-label">
              {/* <Select
                classNamePrefix="select"
                placeholder={''}
                id="customer"
                name="customer"
                options={customersList.filter((x: Customer) => x._id != "000000000000000000000000")}
                getOptionLabel={(option) => option.name}
                value={surveyForm.values.customer}
                onChange={(e: any) => onCustomerChange(e)}
              /> */}
              <Dropdown // className="select"
                style={{ width: "100%" }}
                placeholder={''}
                id="customer"
                name="customer"
                options={customersList.filter((x: Customer) => x._id != "000000000000000000000000")}
                optionLabel="name" filter filterBy="name"// getOptionLabel={(option) => option.name}                
                value={surveyForm.values.customer}
                onChange={(e: any) => onCustomerChange(e.value)}
                disabled={props?.isEditSurvey == true ? true : false} />
            </span>

            {getFormErrorMessage('customer')}
          </div>
          <div className="col-lg-4 col-md-2 col-sm-4 col-xs-2" >
            <label htmlFor="selectedDate" className={"form-label " + classNames({ 'p-error': isFormFieldValid('surveyDate') })}>{t("CreateSurvey.DateText")} <span className="required-star"> &nbsp;*</span></label>
            <span className="p-float-label">
              <Calendar name="surveyDate" id="surveyDate" style={{ width: "100%" }} value={surveyForm.values.surveyDate} onChange={onDateChange} placeholder="Select date" showIcon />
            </span>
            {getFormErrorMessage('surveyDate')}
          </div>
        </div>
        <br />
        <div className="row">
          <div className={props.isEditSurvey ? "col-sm-8 col-xs-12" : "col-sm-8  col-xs-10"}>
            <label htmlFor="selectedName" className={"form-label " + classNames({ 'p-error': isFormFieldValid('surveyName') })}>{t("CreateSurvey.NameText")} <span className="required-star"> &nbsp;*</span></label>
            <span className="p-float-label">
              <InputText name="surveyName" id="surveyName" style={{ width: "100%" }} value={surveyForm.values.surveyName} onChange={surveyForm.handleChange} placeholder="Enter name" />
            </span>
            {getFormErrorMessage('surveyName')}
          </div>
          <div className="col-sm-4 col-xs-8">
            <label htmlFor="selectedTimezone" className={"form-label " + classNames({ 'p-error': isFormFieldValid('surveyTimeZone') })}>Timezone <span className="required-star"> &nbsp;*</span></label>
            <span className="p-float-label">
              <Dropdown
                style={{ width: "100%" }}
                placeholder={'Select Timezone'}
                id="surveyTimeZone"
                name="surveyTimeZone"
                options={timezoneValues}
                value={selectedTimezone}
                onChange={(e) => onTimezoneChange(e)}
                optionValue="code"
                optionLabel="name"
              />
            </span>
            {getFormErrorMessage('surveyTimeZone')}
          </div>
        </div>
        <br />
        <div className="row">
          {props.isEditSurvey &&
            <div className="col-sm-4 col-xs-12">
              <label htmlFor="status" className={"form-label " + classNames({ 'p-error': isFormFieldValid('status') })}>{t("StatusLabel")} <span className="required-star"> &nbsp;*</span></label>
              <span className="p-float-label">
                {/* <Select
                  classNamePrefix="select"
                  placeholder={''}
                  id="status"
                  name="status"
                  options={props.statusValues}
                  getOptionLabel={(option) => option.status}
                  value={surveyForm.values.status}
                  onChange={(e: any) => onStausChange(e)}
                /> */}
                <Dropdown
                  style={{ width: "100%" }}
                  placeholder={''}
                  id="status"
                  name="status"
                  options={props.statusValues}
                  optionLabel="status" filterBy="status"
                  value={surveyForm.values.status}
                  onChange={(e: any) => onStausChange(e.value)} />
              </span>
              {getFormErrorMessage('status')}
            </div>}
          <div className="col-sm-12">
            <br />
            <span className="p-error">
              {t("MandatoryTextFileds")}
            </span>
          </div>
        </div>
        <div className="p-dialog-footer mt-2 footer-button" >
          <div className="mobile_button">
            <button className="btn btn-primary" type="submit"  >
              {
                props?.isEditSurvey ? t("UpdateButton") : t("InitiateButton")
              }
            </button>
            <button type="reset"
              onClick={() => hideModalPopUp()}
              autoFocus className="btn btn-outline-light md-2">{t("CancelButton")}</button>
          </div>
        </div>
      </form>
    </Dialog>
  </>
}

export default AddOrEditSurvey;
