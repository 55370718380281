// CSS
import './site-details.css';


// components for common panel
import CommonPanel from '../../common/common-panel';
import SiteOverView from './common-panel-components/site-overview';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SiteEmission from "./common-panel-components/site-emission-by-source";
import SiteDetailsTable from './common-panel-components/site-surveydetails-table';
import SiteEmissionBySize from "./common-panel-components/site-emission-by-size"


const SiteDetails = () => {

    const [SiteDetailsName, UpdateSiteDetailsName] = useState<any>("");
    const { t } = useTranslation();

    const { cust, name, state, type } = useParams();
    const custName = cust?.replace("}", "");

    const navigate = useNavigate();
    document.title = "AOGI-Site Details";
    return <>
        <div className="content__header content__boxed overlapping">
            <div className="content__wrap pt-1 pb-2">
                <h1 className="page-title mb-2 mt-4">{t("SiteDetails.HeaderWithSiteName")} {name}<span className="site-details-customer-name" style={{ float: "right" }}>{t("SiteDetails.HeaderWithCutomerName")} {'\u00A0'}:{'\u00A0'}{custName}</span></h1>
            </div>

        </div>

        <div className="content__boxed">
            <div className="content__wrap">
                <div className="dashboard_main_grid_div">
                    <div className="card site-emission-overview-map">
                        <CommonPanel InnerComponent={<SiteOverView />} Heading={t("SiteDetails.EmissionOverviewHeader")} />

                    </div>
                    <br className='d-md-none' />
                    <div className="card site-emission-by-source">
                        <CommonPanel InnerComponent={<SiteEmission />} Heading={t("SiteDetails.EmissionBySourceHeader")} />
                    </div>
                    <br className='d-md-none' />
                    <div className="card site-emission-by-size">
                        <CommonPanel InnerComponent={<SiteEmissionBySize />} Heading={t("SiteDetails.EmissionBySizeHeader")} />
                    </div>
                    <br className='d-md-none' />
                    <div className="card all-surveys-table">
                        <CommonPanel InnerComponent={<SiteDetailsTable />} Heading={t("SiteDetails.SurveyDetailsTableHeader")} />
                    </div>
                </div>




            </div>
        </div>

    </>
}

export default SiteDetails;