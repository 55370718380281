import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import Marker from "../../../../assets/img/helicopterMarker.png";
import AogiSiteService from "../../../../services/aogi-site/aogi-site.service";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import PopEmissionData from "../../dashboard/Map/pop-emission-data";
import { useTranslation } from "react-i18next";

export default function SimpleMap(props: any) {
  const [siteData, setSiteData] = useState<any>("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState([]);
  const [popUpTitle, setPopUpTitle] = useState("");
  const { t } = useTranslation();
  let lati = Number(props.lati);
  let lon = Number(props.lon);
  const { customerId, name } = useParams();
  const cid = customerId?.replace("}", "");

  let HelicopterIcon = 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
  let BlueHelicopterIcon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
  let GreenHelicopterIcon = 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';

  const renderMarkers = (map: any, maps: any, chopperIconCount: any) => {
    let marker = new maps.Marker({
      position: { lat: lati, lng: lon },
      map,
      icon: {
        url: (chopperIconCount === 0) ? GreenHelicopterIcon : HelicopterIcon,
        scaledSize: new window.google.maps.Size(50, 50), // Adjust the size here
      },
      style: { backgroundSize: "1110px" },
      title: t("MapChoperIcon"),
    });
    marker.addListener("click", () => {
      AogiSiteService.getAogiSiteSurveyDetails(name as string, cid as string).then((res) => {
        res.sort((a: any, b: any) => {
          return (new Date(b.date) as any) - (new Date(a.date) as any); // ascending
        })
        setSiteData(res[0].data[0].emission)
        readyData(res[0])
        setPopUpTitle(res[0].data[0]?.name)
      })
    });
    return marker;
  };

  const readyData = (data: any) => {
    const emsData = { emission: data?.data[0]?.emission, hasEmission: 1, i: data?.data[0]?.name, lat: '', lon: '', title: data?.data[0]?.name }
    const mainEms: any = {
      cid: cid,
      id: data?.id,
      rdata: emsData,
    }
    setPopUpData(mainEms)
    setShowPopUp(true)
  }


  const defaultProps = {
    center: {
      lat: lati,
      lng: lon
    },
    zoom: 17,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '50vh', width: '100%' }}>

      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCdsXnMXcV_vTCPjTd5tHGCi2Oed3hVKEQ" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={
          async (map: { map: any, maps: any }) => {
            var chopperIconCount = 0;
            await AogiSiteService.getAogiSiteSurveyDetails(name as string, cid as string).then((res) => {
              const recentSorted = res.sort((a: any, b: any) => {
                return (new Date(b.date) as any) - (new Date(a.date) as any); // ascending
              })
              if (recentSorted && recentSorted[0]?.data[0]?.emission?.length > 0) {
                chopperIconCount = recentSorted[0]?.data[0]?.emission?.length
              } else {
                chopperIconCount = recentSorted[0]?.data[0]?.emission?.length
              }
            })
            renderMarkers(map.map, map.maps, chopperIconCount)
          }
        }
        options={{ streetViewControl: true, mapTypeControl: true, mapTypeId: "hybrid" }}
      >
      </GoogleMapReact>
      <Dialog header={popUpTitle} visible={showPopUp} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => setShowPopUp(false)} className={'create-modal-popup'}>
        <PopEmissionData eData={popUpData} displaySiteBtn={false} />
      </Dialog>
    </div>
  );
}