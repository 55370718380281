// React
import { useEffect, useState } from "react";

// CSS
import "./indexStyle.css";

// Prime React
import { Calendar, CalendarChangeParams } from 'primereact/calendar';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";

// Common Panel
import CommonPanel from "../../common/common-panel";


// Redux
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../../store/store";

// Store
import { addCustomersData } from "../../../store/AOGI/common";
// Table
import SiteSurveyTable from "./site-survey-table";
import Helpers from "../../../utils/helpers";

// API Service
import CustomerService from "../../../services/customer/customer.service";
import AOGISurvey from "../../../interfaces/aogi-survey/aogi-survey";
import Customer from "../../../interfaces/customer/customer";
import PersistantService from "../../../services/autentication/persistant.service";
import { useTranslation } from "react-i18next";

const SurveyIndex = () => {
    document.title = "AOGI-Surveys";
    const date = new Date();
    let allCustomerObject: Customer = {
        "_id": "000000000000000000000000",
        "customerID": "",
        "name": "All Customers",
        "status": "true",
        "isUom": null,
        "unit": null
    };

    const [statusValues, setStatusValues] = useState([
        {
            "status": "OPEN"
        },
        {
            "status": "INPROGRESS"
        },
        {
            "status": "COMPLETED"
        },
        {
            "status": "SCHEDULED"
        },
        {
            "status": "CANCELLED"
        },
    ]);

    let customer = PersistantService.getPersistSelectedCustomer()?.surveySelectedcustomer;

    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    const CustData_Redux = useSelector((state: AppState) => state.AOGICommon.CustData);
    const [custList, custListUpdate] = useState<any>(CustData_Redux);
    const [selectedCustomer, setSelectedCustomer] = useState<any>(customer ?? allCustomerObject);
    const [selectedName, setSelectedName] = useState<AOGISurvey>({ surveyName: "All Surveys" });
    const [surveyDate, setSurveyDate] = useState<Date>(date);
    const [selectedStatus, setSelectedStatus] = useState<any>(statusValues);
    const [surveyNames, setSurveyNames] = useState<AOGISurvey[]>([]);
    let [countDownloadSurveyData, setCountDownloadSurveyData] = useState<number>(0);

    useEffect(() => {
        if (custList == "") {
            CustomerService.getCustomerList().then((res) => {
                dispatch(addCustomersData(res as any));
                custListUpdate(res);
                if (res.length == 1) {
                    setSelectedCustomer(res[0]);

                }
            });
        } else {
            custListUpdate(custList);
            if (custList.length == 1) {
                setSelectedCustomer(custList[0]);

            }
        }

    }, []);


    const setSurveyYearSelected = (param: CalendarChangeParams) => {
        Helpers.ConsoleLog(param);
        param.preventDefault();
        if (param.value) {
            let dataSelected: any = param.value;
            let selectedDate = new Date(dataSelected);

            setSurveyDate(selectedDate);
        }
    }

    const setSurveyDropDownNames = (surveyNames: AOGISurvey[]) => {
        let allSurvey = [{ surveyName: "All Surveys" }]
        surveyNames = [...allSurvey, ...surveyNames];
        setSurveyNames(surveyNames);
    }

    const handleSurveyDropDown = (data: any) => {
        setSelectedCustomer(data);
        PersistantService.setPersistSelectedCustomer({ surveySelectedcustomer: data });
    }


    const downloadSurveyData = () => {
        let count = ++countDownloadSurveyData;
        setCountDownloadSurveyData(count);
    }

    return <>
        <section id="content" className="content">
            <div className="content__header content__boxed overlapping">
                <div className="content__wrap pt-1 pb-2">
                    <p className="lead text-overflow">
                        {t("Survey.HeaderText")}
                    </p>
                    <div className="surveypage-heading">
                        <div className="row bg-primary" id="divCustFilter">
                            <div className="row align-items-end">
                                <div className="col-lg-1 col-md-1 col-sm-4 col-xs-4">
                                    <label className="title">{t("CustomerLabel")}</label>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <Dropdown className="surveyDropdown" value={selectedCustomer} options={custList} onChange={(e: any) => { handleSurveyDropDown(e.target.value) }} optionLabel="name" filter filterBy="name" placeholder={t("Survey.IndexCustomer")} />
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-4 col-xs-4">
                                    <label className="title">{t("DateLabel")}</label>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-4 col-xs-4 ">
                                    <Calendar style={{ width: "220px" }} id="yearpicker" view="year" dateFormat="yy" className="survey_calender" value={surveyDate} onChange={(e: CalendarChangeParams) => setSurveyYearSelected(e)} placeholder={t("Survey.IndexYear")} showIcon></Calendar>
                                </div>
                            </div>
                            <div className="row md-1 mb-3">
                                <div className="col-lg-1 col-md-1 col-sm-4 col-xs-4">
                                    <label className="title1 ">{t("SurveyNameLabel")}</label>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                    <Dropdown className="surveyDropdown" value={selectedName} options={surveyNames} onChange={(e: any) => { setSelectedName(e.value) }} optionLabel="surveyName" filter filterBy="surveyName" placeholder={t("Survey.IndexName")} />
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-4 col-xs-4">
                                    <label className="title1">{t("StatusLabel")}</label>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-4 col-xs-4">
                                    <MultiSelect className="survey_multiselect" value={selectedStatus} options={statusValues} onChange={(e: any) => setSelectedStatus(e.value)} optionLabel="status" filterBy="status" placeholder={t("Survey.IndexStatus")} maxSelectedLabels={3} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content__boxed">
                <div className="content__wrap">
                    <div className="card">
                        <CommonPanel downloadData={downloadSurveyData} InnerComponent={<SiteSurveyTable exportSurveyData={countDownloadSurveyData} setSurveyDate={setSurveyDate} selectedCustomer={selectedCustomer} setSurveyDropDownNames={setSurveyDropDownNames} selectedSurveyName={selectedName} selectedStatus={selectedStatus} surveyDate={surveyDate} CustData={custList} statusValues={statusValues} />} Heading={""} />

                    </div>
                </div>
            </div>
        </section>
    </>
}

export default SurveyIndex;