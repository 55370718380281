import React from 'react';
const ContentHeader2: React.FC = () => {
    return <>
        <h1>Comp 2</h1>
        <input type="text" placeholder='type something here' style={{width:"200px"}}></input>
        <input type="button" value="Submit" style={{width:"100px"}}/>

        <div style={{height:"438px"}}></div>

    </>
}

export default ContentHeader2;