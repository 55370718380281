import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useLayoutEffect, useState, useRef } from 'react';
import * as XLSX from "xlsx";
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import CustomerService from '../../../services/customer/customer.service';
import Helpers from '../../../utils/helpers';
import AOGISite from '../../../interfaces/aogi-site/aogi-site';
import AogiSiteService from '../../../services/aogi-site/aogi-site.service';
import AOGIExcelSite from '../../../interfaces/aogi-site/aogi-site-excel-interface';
import AOGISiteBulk from '../../../interfaces/aogi-site/aogi-site-bulk';
import CommonConstant from '../../../constants/common.constant';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../../store/store';
import { AppendSiteList } from '../../../services/AOGI/site-upload';
import { SitesList } from '../../../store/AOGI/site-upload';
import Customer from '../../../interfaces/customer/customer';
import { addCustomersData } from '../../../store/AOGI/common';
import { useTranslation } from "react-i18next";


export const CreateSite = (props: any) => {

  const toast = useRef<any>(null);
  const { t } = useTranslation();

  const [selectedCustomer, setSelectedCustomer] = useState();
  const [aogiSites, setAogiSites] = useState<AOGISite[]>([]);
  const [aogiSitesBulkData, setAogiSitesBulkData] = useState<AOGISiteBulk | null>(null);
  const [isValidatingTheSiteUploadTemplate, setIsValidatingTheSiteUploadTemplate] = useState(false);
  const [isSiteUploadInProgress, setIsSiteUploadInProgress] = useState(false);
  const [customers, setCustomers] = useState([]);
  const SiteList_Redux = useSelector((state: AppState) => state.AOGISiteUpload.SiteList);

  const CustData_Redux = useSelector((state: AppState) => state.AOGICommon.CustData);
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {

    CustomerService.getCustomerList().then((res: any) => {
      Helpers.ConsoleLog("create site customers data");
      Helpers.ConsoleLog(res);
      // here will be the cus list
      if (CustData_Redux == "") {
        setCustomers(res);
        dispatch(addCustomersData(res as any));
      } else {
        setCustomers(CustData_Redux as any);
      }
    })


    // Helpers.ConsoleLog("create site popup customers data");
    // Helpers.ConsoleLog(CustData_Redux);
    // setCustomers(CustData_Redux as any);
    // CustomerService.getCustomerList().then((res: any) => {
    //   Helpers.ConsoleLog("create site popup customers data");
    //   Helpers.ConsoleLog(res);
    //   setCustomers(res);
    // })
  }, [])


  const formik: any = useFormik({
    initialValues: {
      selectedCustomer: null,
      siteUploadFile: ''
    },
    validate: async (data: any) => {
      let errors: any = {};

      if (!data.selectedCustomer) {
        errors.selectedCustomer = 'Please select a customer.';
      }

      if (!data.siteUploadFile) {
        errors.siteUploadFile = 'Please select a file.';
      }
      else {
        if (!await isValidSiteUploadTemplate(data.siteUploadFile)) {
          errors.siteUploadFile = 'Upload the file in the correct format! Also check the correct customer selected or not before upload. Please download the sample template for reference';
        }
      }

      return errors;
    },
    onSubmit: async (data: any) => {
      let response = await addOrUpdateSites(data);
      if(response == "SUCCESS"){
        props.onDataRead(data)
        props.onHide();
        formik.resetForm();
      }
      else{
        formik.errors["siteUploadFile"] = "Duplicate sites found in the upload template. Kindly remove the duplicate sites and reupload";
        
      }
    }
  });


  const addOrUpdateSites = async (data: any) => {

    setIsSiteUploadInProgress(true);
    Helpers.ConsoleLog(data);

    if (aogiSitesBulkData?.aogiSites) {
      return await AogiSiteService.addOrUpdateAogiSite(aogiSitesBulkData?.aogiSites).then( async (data: any) => {

        if(data == "SUCCESS"){
          await AogiSiteService.getAogiSite(aogiSitesBulkData?.aogiSites[0]?.customerID).then((response: any) => {
            AppendSiteList(SiteList_Redux, response).then((merged_res: any) => {
              dispatch(SitesList(merged_res));
              props.setAogiSitesRedux(merged_res);
              setAogiSites(merged_res);
            })
          });
          Helpers.ConsoleLog("add or update site", data);
          toast?.current?.show({ severity: 'success', summary: 'Site upload', detail: 'Uploaded Successfully', life: 3000 });
        }
        
        setIsSiteUploadInProgress(false);
        
        return data;
      });
    }

  }

  const isFormFieldValid = (name: string) => {
    return !!(formik.touched[name] && formik.errors[name])
  };
  const getFormErrorMessage = (name: string) => {
    return isFormFieldValid(name) && <span className="p-error">{formik.errors[name]}</span>;
  };

  const originalHeaderArray = ['FID', 'Name', 'Type', 'County', 'State', 'Latitude', 'Longitude'];

  const isValidSiteUploadTemplate: any = async (file: any) => {
    setIsValidatingTheSiteUploadTemplate(true);
    let excelData: any = await readExcel(file);

    setAogiSites(excelData.data);
    Helpers.ConsoleLog(excelData);


    if (excelData?.headerObject) {

      let isValidData: boolean = true;

      let aogiSitesBulkData: AOGISiteBulk = { aogiSites: [], totalSites: 0, totalValidSites: 0, notValidSites: [] };

      let isValidHeader = JSON.stringify(originalHeaderArray) == JSON.stringify(excelData.headerObject[0]);
      if (isValidHeader) {
        excelData?.data.forEach((site: AOGIExcelSite) => {
          isValidData = (site.Latitude != undefined && site.Longitude != undefined) && isLatitude(site.Latitude) && isLongitude(site.Longitude);
          aogiSitesBulkData.totalSites++;
          if (isValidData && formik.values.selectedCustomer?.customerID != null) {
            aogiSitesBulkData.totalValidSites++;
            aogiSitesBulkData.aogiSites.push({ assetType: CommonConstant.AOGI, customerID: formik.values.selectedCustomer?.customerID, county: site.County, location: site.Latitude + "," + site.Longitude, state: site.State, thingNo: site.Name?.trim(), type: site.Type?.trim(), serialNo: site.FID })
          }
        });

        setAogiSitesBulkData(aogiSitesBulkData)
        setIsValidatingTheSiteUploadTemplate(false);

        Helpers.ConsoleLog(isValidData);

        return isValidData;
      } else {
        setIsValidatingTheSiteUploadTemplate(false);
        return false;
      }
    } else {
      setIsValidatingTheSiteUploadTemplate(false);
      return false;
    }
  }
  const isLatitude = (num: number) => isFinite(num) && Math.abs(num) <= 90;
  const isLongitude = (num: number) => isFinite(num) && Math.abs(num) <= 180;

  const readExcel = (file: any) => {

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e: any) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        let headerObject = XLSX.utils.sheet_to_json(ws,
          {
            header: 1,
            defval: ""
          })

        resolve({ data, headerObject });
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    formik.resetForm();
  }, [])


  return (
    <>
      <Toast ref={toast} />
      <Dialog className="create-modal-popup" {...props} header={t("CreateSite.PopupHeader")} modal closeOnEscape={false} closable={true}
        draggable={false} resizable={false} onHide={() => { props.onHide(); formik.resetForm(); }}>

        <div className="flex justify-content-center">
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="field">
              <label htmlFor="selectedCustomer" className={"form-label " + classNames({ 'p-error': isFormFieldValid('selectedCustomer') })}>{t("SiteUpload.SelectCustomerText")}<span className="required-star">&nbsp;*</span></label>
              <span className="p-float-label">
                <Dropdown name="selectedCustomer" id="selectedCustomer"   filterPlaceholder='Select a customer' optionLabel="name" filter filterBy="name" value={formik.values.selectedCustomer} options={customers?.filter((x: Customer) => x._id != "000000000000000000000000")} onChange={formik.handleChange} placeholder="Select a Customer" />
              </span>
              {getFormErrorMessage('selectedCustomer')}
            </div>
            <br />
            <div className="field">
              <label htmlFor="selectedCustomer" className={"form-label " + classNames({ 'p-error': isFormFieldValid('siteUploadFile') })}>{t("SiteUpload.FileUploadText")}<span className="required-star">&nbsp;*</span></label>
              <span className="p-float-label">
                <input
                  type="file"
                  name="siteUploadFile"
                  id="siteUploadFile"
                  className='form-control'
                  accept=".xls,.xlsx"
                  onClick={(event: any) => event.target.value = null}
                  onChange={(event: any) => {
                    formik.setFieldValue("siteUploadFile", event.currentTarget.files[0]);
                  }}
                />
              </span>
              {getFormErrorMessage('siteUploadFile')}
            </div>
            <div className="col-sm-12">
              <br />
              <span className="p-error">
                {t("MandatoryTextFileds")}
              </span>
            </div>
            <div className="p-dialog-footer mt-2  footer-button" >
              <div className='mobile_button'>
                <button className="btn btn-primary" type="submit" disabled={isSiteUploadInProgress}>
                  {isSiteUploadInProgress && <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>}
                  {!isSiteUploadInProgress ? t("UploadButton") : t("Uploading")+"...." }
                </button>
                <button type="button"  onClick={() => { props.onHide(); formik.resetForm(); }} autoFocus className="btn btn-outline-light md-2" >{t("CancelButton")}</button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};
export default CreateSite;


