export const Footer = () => {
    let d_year:any = new Date();
    d_year = d_year.getFullYear();
    return (
        <section id="content" className="content">
            <footer className="border-top mt-auto footer_background_color" id="footer">
                <div className="hide-fixed">
                    <div className="footer_text">
                        <span data-i18n="Common.Poweredby">Powered by</span> ENTERPRISE<span className="testOrDev"> - TEST</span> © {d_year}
                    </div>
                </div>
            </footer>
        </section>
    );
};
export default Footer;