import Flare from "../../../assets/img/icons/flare.png"
import OtherLeak from "../../../assets/img/icons/otherleak.png"
import Leak from '../../../assets/img/icons/leak.png'
import Hatch from "../../../assets/img/icons/hatch.png"
import NoEmission from "../../../assets/img/icons/onlineCircle.png";
import Vent from "../../../assets/img/vent.png";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import ApiConstant from "../../../constants/api.constants";
import SurveyDetailsService from "../../../services/survey_details/surevy_details.service";
const GroupIcons = (para: any) => {
    const [popup_video_url_group_icons, setpopup_video_url_group_icons] = useState("");
    const [popup_video_title_group_icons, setpopup_video_title_group_icons] = useState("");
    const [DisplayVideo_group_icons, setDisplayVideo_group_icons] = useState(false);
    const [noOfLeaks, setNoOfLeaks] = useState(0);

    const [flare_count, setflare_count] = useState(0);
    const [Vent_count, setVent_count] = useState(0);
    const [other_count, setother_count] = useState(0);
    const [EnardoValve_count, setEnardoValve_count] = useState(0);
    const [Hatch_count, setHatch_count] = useState(0);
    const [NoEmission_count, setNoEmission_count] = useState(0);

    const [flare_count_leak, setflare_count_leak] = useState(0);
    const [Vent_count_leak, setVent_count_leak] = useState(0);
    const [other_count_leak, setother_count_leak] = useState(0);
    const [EnardoValve_count_leak, setEnardoValve_count_leak] = useState(0);
    const [Hatch_count_leak, setHatch_count_leak] = useState(0);
    const [NoEmission_count_leak, setNoEmission_count_leak] = useState(0);


    const [flare_video_data, setflare_video_data] = useState<any>([]);
    const [vent_video_data, setvent_video_data] = useState<any>([]);
    const [other_video_data, setother_video_data] = useState<any>([]);
    const [EnardoValve_video_data, setEnardoValve_video_data] = useState<any>([]);
    const [Hatch_video_data, setHatch_video_data] = useState<any>([]);
    const [NoEmission_video_data, setNoEmission_video_data] = useState<any>([]);

    let customerId = para.customerId
    let surveyId = para.surveyId
    para = para.row;

    let temp_val = 0;
    let check_flare_count = 0;
    let check_other_count = 0;
    let check_EnardoValve_count = 0;
    let check_Hatch_count = 0;
    let check_Vent_count = 0;
    let check_NoEmission_count = 0;

    let check_flare_count_leak = 0;
    let check_other_count_leak = 0;
    let check_EnardoValve_count_leak = 0;
    let check_Hatch_count_leak = 0;
    let check_Vent_count_leak = 0;
    let check_NoEmission_count_leak = 0;
    useEffect(() => {
        para?.emission.map((setVideoValues: any) => {
            if (setVideoValues.noOfLeaks != null) {
                if (setVideoValues.source == 'Flare') {
                    check_flare_count = check_flare_count + 1;
                    check_flare_count_leak = check_flare_count_leak + setVideoValues.noOfLeaks;


                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setflare_video_data(video_array);

                    setflare_count(check_flare_count);
                    setflare_count_leak(check_flare_count_leak);
                } else if (setVideoValues.source == 'Vent') {
                    check_Vent_count = check_Vent_count + 1;
                    check_Vent_count_leak = check_Vent_count_leak + setVideoValues.noOfLeaks;

                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setvent_video_data(video_array);

                    setVent_count(check_Vent_count);
                    setVent_count_leak(check_Vent_count_leak);
                } else if (setVideoValues.source == 'Other') {
                    check_other_count = check_other_count + 1;
                    check_other_count_leak = check_other_count_leak + setVideoValues.noOfLeaks;

                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setother_video_data(video_array);

                    setother_count(check_other_count);
                    setother_count_leak(check_other_count_leak);
                } else if (setVideoValues.source == 'EnardoValve') {
                    check_EnardoValve_count = check_EnardoValve_count + 1;
                    check_EnardoValve_count_leak = check_EnardoValve_count_leak + setVideoValues.noOfLeaks;


                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setEnardoValve_video_data(video_array);


                    setEnardoValve_count(check_EnardoValve_count);
                    setEnardoValve_count_leak(check_EnardoValve_count_leak);
                } else if (setVideoValues.source == 'Hatch') {
                    check_Hatch_count = check_Hatch_count + 1;
                    check_Hatch_count_leak = check_Hatch_count_leak + setVideoValues.noOfLeaks;


                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setHatch_video_data(video_array);


                    setHatch_count(check_Hatch_count);
                    setHatch_count_leak(check_Hatch_count_leak);
                }
                else if (setVideoValues.source == 'NoEmission') {
                    check_NoEmission_count = check_NoEmission_count + 1;
                    check_NoEmission_count_leak = check_NoEmission_count_leak + setVideoValues.noOfLeaks;



                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setNoEmission_video_data(video_array);


                    setNoEmission_count(check_NoEmission_count);
                    setNoEmission_count_leak(check_NoEmission_count_leak);
                }
                temp_val = temp_val + setVideoValues.noOfLeaks
                // temp_val = temp_val + setVideoValues.noOfLeaks
                setNoOfLeaks(temp_val);
            } else {
                if (setVideoValues.source == 'Flare') {
                    check_flare_count = check_flare_count + 1;


                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];
                    setflare_video_data(video_array);

                    setflare_count(check_flare_count);
                    check_flare_count_leak = check_flare_count_leak + 1;
                    setflare_count_leak(check_flare_count_leak);
                } else if (setVideoValues.source == 'Vent') {
                    check_Vent_count = check_Vent_count + 1;


                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setvent_video_data(video_array);


                    setVent_count(check_Vent_count);
                    check_Vent_count_leak = check_Vent_count_leak + 1;
                    setVent_count_leak(check_Vent_count_leak);
                } else if (setVideoValues.source == 'Other') {
                    check_other_count = check_other_count + 1;


                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setother_video_data(video_array);


                    setother_count(check_other_count);
                    check_other_count_leak = check_other_count_leak + 1;
                    setother_count_leak(check_other_count_leak);
                } else if (setVideoValues.source == 'EnardoValve') {
                    check_EnardoValve_count = check_EnardoValve_count + 1;


                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setEnardoValve_video_data(video_array);


                    setEnardoValve_count(check_EnardoValve_count);
                    check_EnardoValve_count_leak = check_EnardoValve_count_leak + 1;
                    setEnardoValve_count_leak(check_EnardoValve_count_leak);
                } else if (setVideoValues.source == 'Hatch') {
                    check_Hatch_count = check_Hatch_count + 1;


                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setHatch_video_data(video_array);

                    setHatch_count(check_Hatch_count);
                    check_Hatch_count_leak = check_Hatch_count_leak + 1;
                    setHatch_count_leak(check_Hatch_count_leak);
                } else if (setVideoValues.source == 'NoEmission') {
                    check_NoEmission_count = check_NoEmission_count + 1;


                    let video_array = [setVideoValues.video, customerId, surveyId, setVideoValues?.videoID];

                    setNoEmission_video_data(video_array);


                    setNoEmission_count(check_NoEmission_count);

                    check_NoEmission_count_leak = check_NoEmission_count_leak + 1;
                    setNoEmission_count_leak(check_NoEmission_count_leak);
                }
                temp_val = temp_val + 1;
                setNoOfLeaks(temp_val);
            }
            setpopup_video_title_group_icons(setVideoValues.video);

            // setpopup_video_url_group_icons(`${process.env.REACT_APP_SERVER_ENDPOINT}/${ApiConstant.dowloadFiles}?id=${setVideoValues?.videoID}&Filename=${setVideoValues?.video}&container=aogi-survey`);

        });


    }, [para])

    var uniData = para?.emission.map((ems: { source: any; }) => ems.source).filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index)

    var resImg: any;
    var unique = uniData.filter((value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    })
    let checkIfNoEmission = 0;
    resImg = unique.map((val: any, i: any) => {

        if (val == 'Flare') {
            return <img title={`${flare_count_leak as any} Flare`} key={i} src={Flare} width="20px" height="20px" onClick={(e) => {
                if (flare_count == 1) {
                    let title = flare_video_data[0];
                    let cust_name = flare_video_data[1];
                    let surv_id = flare_video_data[2];
                    let vid_id = flare_video_data[3];
                    setpopup_video_title_group_icons(`${title} (${flare_count_leak} Flare)`);
                    SurveyDetailsService.GetBlobURI(cust_name, surv_id, vid_id).then((res: any) => {
                        setpopup_video_url_group_icons(res);

                    })
                    setTimeout(() => {
                        setDisplayVideo_group_icons(true);
                    }, 1000)
                } else {
                    const target = e.target as HTMLTextAreaElement
                    var closestParent: any = target.closest("#cell-7-undefined");
                    closestParent.click();
                }

            }} style={{ marginRight: '5px', marginTop: '-0.5vh' }}></img>
        } else if (val == 'Other') {
            return <img onClick={(e) => {
                if (other_count == 1) {
                    let title = other_video_data[0];
                    let cust_name = other_video_data[1];
                    let surv_id = other_video_data[2];
                    let vid_id = other_video_data[3];
                    setpopup_video_title_group_icons(`${title} (${other_count_leak} Other)`);
                    SurveyDetailsService.GetBlobURI(cust_name, surv_id, vid_id).then((res: any) => {
                        setpopup_video_url_group_icons(res);

                    })
                    setTimeout(() => {
                        setDisplayVideo_group_icons(true);
                    }, 1000)
                } else {
                    const target = e.target as HTMLTextAreaElement
                    var closestParent: any = target.closest("#cell-7-undefined");
                    closestParent.click();
                }
            }} title={`${other_count_leak as any} Other`} key={i} src={OtherLeak} width="20px" height="20px" style={{ marginRight: '5px', marginTop: '-0.5vh' }}></img>
        } else if (val == 'Leak') {
            return <img key={i} src={Leak} width="20px" height="20px" style={{ marginRight: '5px', marginTop: '-0.5vh' }}></img>
        } else if (val == 'NoEmission') {
            checkIfNoEmission++;
            return <img onClick={(e) => {
                if (NoEmission_count == 1) {
                    let title = NoEmission_video_data[0];
                    let cust_name = NoEmission_video_data[1];
                    let surv_id = NoEmission_video_data[2];
                    let vid_id = NoEmission_video_data[3];
                    setpopup_video_title_group_icons(`${title} (${NoEmission_count_leak} No Emission)`);
                    SurveyDetailsService.GetBlobURI(cust_name, surv_id, vid_id).then((res: any) => {
                        setpopup_video_url_group_icons(res);

                    })
                    setTimeout(() => {
                        setDisplayVideo_group_icons(true);
                    }, 1000)
                } else {
                    const target = e.target as HTMLTextAreaElement
                    var closestParent: any = target.closest("#cell-7-undefined");
                    closestParent.click();
                }
            }} title={`${NoEmission_count_leak as any} No Emission`} key={i} src={NoEmission} width="20px" height="20px" style={{ marginRight: '5px', marginTop: '-0.3vh' }}></img>
        } else if (val == 'EnardoValve') {

            return <img onClick={(e) => {
                if (EnardoValve_count == 1) {
                    let title = EnardoValve_video_data[0];
                    let cust_name = EnardoValve_video_data[1];
                    let surv_id = EnardoValve_video_data[2];
                    let vid_id = EnardoValve_video_data[3];
                    setpopup_video_title_group_icons(`${title} (${EnardoValve_count_leak} EnardoValve)`);
                    SurveyDetailsService.GetBlobURI(cust_name, surv_id, vid_id).then((res: any) => {
                        setpopup_video_url_group_icons(res);

                    })
                    setTimeout(() => {
                        setDisplayVideo_group_icons(true);
                    }, 1000)
                } else {
                    const target = e.target as HTMLTextAreaElement
                    var closestParent: any = target.closest("#cell-7-undefined");
                    closestParent.click();
                }
            }} title={`${EnardoValve_count_leak as any} Enardo Valve`} key={i} src={Leak} width="20px" height="20px" style={{ marginRight: '5px', marginTop: '-0.5vh' }}></img>
        } else if (val == 'Hatch') {
            return <img onClick={(e) => {
                if (Hatch_count == 1) {
                    let title = Hatch_video_data[0];
                    let cust_name = Hatch_video_data[1];
                    let surv_id = Hatch_video_data[2];
                    let vid_id = Hatch_video_data[3];
                    setpopup_video_title_group_icons(`${title} (${Hatch_count_leak} Hatch)`);
                    SurveyDetailsService.GetBlobURI(cust_name, surv_id, vid_id).then((res: any) => {
                        setpopup_video_url_group_icons(res);

                    })
                    setTimeout(() => {
                        setDisplayVideo_group_icons(true);
                    }, 1000)
                } else {
                    const target = e.target as HTMLTextAreaElement
                    var closestParent: any = target.closest("#cell-7-undefined");
                    closestParent.click();
                }
            }} title={`${Hatch_count_leak as any} Hatch`} key={i} src={Hatch} width="20px" height="20px" style={{ marginRight: '5px', marginTop: '-0.5vh' }}></img>
        }
        else if (val == 'Vent') {

            return <img onClick={(e) => {
                if (Vent_count == 1) {
                    let title = vent_video_data[0];
                    let cust_name = vent_video_data[1];
                    let surv_id = vent_video_data[2];
                    let vid_id = vent_video_data[3];
                    setpopup_video_title_group_icons(`${title} (${Vent_count_leak} Vent)`);
                    SurveyDetailsService.GetBlobURI(cust_name, surv_id, vid_id).then((res: any) => {
                        setpopup_video_url_group_icons(res);

                    })
                    setTimeout(() => {
                        setDisplayVideo_group_icons(true);
                    }, 1000)
                } else {
                    const target = e.target as HTMLTextAreaElement
                    var closestParent: any = target.closest("#cell-7-undefined");
                    closestParent.click();
                }
            }} title={`${Vent_count_leak as any} Vent`} key={i} src={Vent} width="20px" height="20px" style={{ marginRight: '5px', marginTop: '-0.5vh' }}></img>
        }
    });

    return <>
        <Dialog header={`Video - ${popup_video_title_group_icons}`} visible={DisplayVideo_group_icons} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => { setDisplayVideo_group_icons(false); }}>
            <video width="100%" height="auto" controls disablePictureInPicture controlsList="nodownload noplaybackrate" autoPlay>
                <source src={popup_video_url_group_icons} type="video/mp4" />
            </video>
        </Dialog>

        <span>{para.emission.length ? noOfLeaks : '0'} {para.emission.length ? '-' : ''}</span> <span style={{ cursor: "pointer" }}>{resImg}</span>

    </>
}

export default GroupIcons;