import { useState } from 'react';
import ChampionXLogo from "../../assets/img/ChampionX-logo.png"
import leftHeaderLogo from "../../assets/img/banner-dark.png"
import { confirmDialog } from 'primereact/confirmdialog';

export const Header = () => {
    const [theme_icon, themeIconIpdate] = useState('fa-moon-o')
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const mainSiteUrl = process.env.REACT_APP_MVC_URL;

    var toggleThemeButton = () => {
        if (theme_icon === 'fa-moon-o') {
            themeIconIpdate('fa-sun-o');
        } else {
            themeIconIpdate('fa-moon-o');
        }
    }

    const confirmLogout = () => {
        confirmDialog({
            message: 'Are you sure you want to logout from the portal ?',
            header: 'Confirm Logout',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                localStorage.clear();
                if (mainSiteUrl)
                  window.location.assign(mainSiteUrl)
            }
        });
    }

    document.addEventListener('mouseup', function (e) {
        let current_ele: any = e.target;

        var root_container: any = document.getElementById('root');
        var container: any = document.getElementById('popupmenu');
        let root: any = document.getElementById("root");
        if (!container.contains(e.target)) {
            //   container.style.display = 'none';
            if (root_container.classList.contains("mn--min")) {
                container.classList.remove("show")
            }
        }

        if (current_ele.classList.contains("nav-toggler")) {
            container.classList.add("show");

            if (window.innerWidth < 1024 || (!root.classList.contains("mn--min") && !root.classList.contains("mn--max"))) {
                root.classList.toggle("mn--show");
            } else {
                root.classList.toggle("mn--min");
                root.classList.toggle("mn--max");
            }

        }
    });


    

    return (
        <header className="header">
            <div className="header__inner">
                <div className={'d-none d-sm-block header__brand ' + (`${environment}` == 'local' ? '' : 'prod')}  >
                    <div className="brand-wrap-header">
                        <a href={mainSiteUrl} className="brand-img stretched-link">
                            <img src={leftHeaderLogo} alt="Enterprise Logo" className="left_header_logo" />
                        </a>
                        <div className="brand-title">ENTERPRISE  {(`${environment}` == 'local' ? ' - TEST' : '')}</div>
                    </div>
                </div>
                <div className="header__content">
                    <div className="header__content-start">
                        <button type="button" className="nav-toggler header__btn btn btn-icon btn-sm">
                            <i className="pli-list-view fa-lg"></i>
                        </button>
                    </div>
                    <div className="top-middle-header-logo">
                        <img src={ChampionXLogo} className="logo"></img>
                    </div>
                    <div className="header__content-end">
                        <button className="header__btn btn btn-icon btn-sm" type="button" aria-label="Logout button" onClick={confirmLogout}>
                            <i className="fa fa-sign-out fa-lg" ></i>
                        </button>
                    </div>
                </div>
            </div>
        </header>

    );
};

export default Header;