import ApiConstant from "../../constants/api.constants";
import AOGISite from "../../interfaces/aogi-site/aogi-site";
import Helpers from "../../utils/helpers";
import api from "../api";

const addOrUpdateAogiSite = async (aogiSites: AOGISite[]) => {
    return await api
        .post(ApiConstant.addOrUpdateAogiSite, aogiSites)
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
};

const removeAogiSite = async (aogiSiteId: string) => {
    return await api
        .post(ApiConstant.removeAogiSite, { AssetId: [aogiSiteId] })
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
};

const getAogiSite = async (customerId: string) => {

    return await api
        .get(ApiConstant.getAogiSites + "?customerId=" + customerId)
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
};

const getAogiSiteSurveyDetails = async (customerName: string, customerId: string) => {

     return await api
    
      .get(ApiConstant.GetSiteDetails + "?AssetName=" + customerName + "&customerId=" + customerId)
    
      .then(function (response: any) {
    
       return response.data;
    
      })
    
      .catch(function (error) {
    
       Helpers.ConsoleLogError(error);
    
      });
    
    };

const AogiSiteService = {
    addOrUpdateAogiSite,
    removeAogiSite,
    getAogiSite,
    getAogiSiteSurveyDetails
};

export default AogiSiteService;