import _ from "lodash"

const AppendSiteList = async (newData: any, oldData: any) => {
    // Redux


    return await new Promise((resolve, reject) => {

        // After API success then append data to redux store
        let merged_data = _.merge([ ...oldData, ...newData ]);

        // const converted_array = Object.values(merged_data);
        resolve(merged_data)


    })

}

const RemoveSiteList = async (id: any, ReduxData: any) => {
    return await new Promise((resolve, reject) => {

        // Send Data to API




        // After API success then remove from Redux Data
        ReduxData = ReduxData.filter(function (obj: any) {
            return obj._id !== id;
        });


        resolve(ReduxData);


    })

}


const UpdateSiteList = async (id: any, updatedData: any, ReduxData: any) => {

    return await new Promise((resolve, reject) => {

        // Send Data to API




        // After API success then remove from Redux Data
        let _ReduxData = JSON.parse(JSON.stringify(ReduxData)); //Created A Copy for rewrite object 

        _ReduxData.map(function (obj: any) {

            if (obj.id === id) {
                obj.name = updatedData.name;
            }
            return "";
        });


        resolve(_ReduxData);


    })

}


// Filters

// 1) By Customers
const FilterSiteList = async (CustID: any, ReduxData: any) => {

    return await new Promise((resolve, reject) => {


        ReduxData = ReduxData.filter(function (obj: any) {
            return obj._id === CustID;
        });


        resolve(ReduxData);


    })

}

const GetSiteListByID = async (id: any, ReduxData: any) => {
    return await new Promise((resolve, reject) => {

        let IdData = ReduxData.filter(function (obj: any) {
            return obj.customerID == id;
        });

        resolve(IdData);


    })

}



export { AppendSiteList, RemoveSiteList, UpdateSiteList, GetSiteListByID };