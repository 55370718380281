import ApiConstant from "../../constants/api.constants";
import Helpers from "../../utils/helpers";
import api from "../api";
import TokenService from "../autentication/token.service";

const getCurrentUser = async () => {
  return await api.post(ApiConstant.getUser).then((response: any) => {
    Helpers.ConsoleLog(response);
    TokenService.setUser(response?.data);
    return response.data;
  });;
};


const UserService = {
  getCurrentUser
};

export default UserService;