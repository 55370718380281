import ApiConstant from "../../constants/api.constants";
import EmissionNote from "../../interfaces/emission-note/emission-note";
import Helpers from "../../utils/helpers";
import api from "../api";

const addNotesToEmission = async (emissionNote: EmissionNote) => {
    return await api
        .post(ApiConstant.addNotesToEmission, emissionNote)
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
};

const removeNoteFromEmission = async (emissionNote: EmissionNote) => {
    return await api
        .post(ApiConstant.removeNotesToEmission, { AogiID: emissionNote.AogiID, EmissionID: emissionNote.EmissionID, NoteId: emissionNote.NoteId })
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
};

const EmissionNoteService = {
    addNotesToEmission,
    removeNoteFromEmission
};

export default EmissionNoteService;