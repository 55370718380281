import { useNavigate,useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MenuItemModel } from "../../../models/menu-item";

interface MenuItemProps {
    menuItems: MenuItemModel[];
}

export default function MenuItem({ menuItems }: MenuItemProps) {
    const { t } = useTranslation();
    const currentURLRouteName = useLocation ().pathname;
    const currentURLRouteNameToOBJ = currentURLRouteName.split("/");
    const currentURLRouteNameTitleCapit =currentURLRouteNameToOBJ[1].charAt(0).toUpperCase()+ currentURLRouteNameToOBJ[1].slice(1);
   
    var navigate = useNavigate();

    const navigateToPage = (menuItem: MenuItemModel) => {
        navigate(menuItem.isexternal ? menuItem.link : menuItem.link)
    };
    
    return (
        <ul className="mainnav__menu nav flex-column">
            {
                menuItems?.map((menuItem: MenuItemModel, i: number) =>
                    (menuItem?.menuitems && typeof menuItem?.menuitems !== 'undefined' && menuItem.menuitems.length > 0) ?

                        <li className="nav-item has-sub" key={i}>
                            <a href="javascript:void(0)" className={`mininav-toggle nav-link ${(currentURLRouteNameTitleCapit === menuItem.title) ? 'active' : ''}  active`}>

                                {typeof menuItem.icon !== "undefined" && menuItem.icon && <i className={menuItem.icon + " fs-5 me-2"}></i>}
                                <span className="nav-label dashboard-style">{t(menuItem.title)}</span>
                            </a>
                            <ul className="mininav-content nav collapse" id="popupmenu" style={{position:"absolute",zIndex:"999999"}}>
                                
                                <MenuItem menuItems={menuItem.menuitems} />
                            </ul>
                        </li>

                        :

                        <li className="nav-item" key={i}>
                            <a href="javascript:void(0)" onClick={() => { navigateToPage(menuItem) }} className={`nav-link ${((menuItem.link === currentURLRouteName)) ? 'active-link-custom' : ''} ${(currentURLRouteNameToOBJ[1] == '' && menuItem.link == '/aogi/survey') ? 'active-link-custom' : ''}`}>{t(menuItem.title)}</a>
                        </li>
                )
            }
        </ul>
    )
};