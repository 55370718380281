/* eslint-disable no-loop-func */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import { AppState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import AogiSiteService from "../../../../services/aogi-site/aogi-site.service";
import PopSurveyEmissionData from "../Map/pop-survey-emission-data";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

const EmissionBySource = (para: any) => {
    // Create chart instance
    const ResizeStoreToSetHeight = useSelector((state: AppState) => state.AOGICommon.StackedBarGraphHeight);
    const { t } = useTranslation();


    const [displayChart, displayChartUpdate] = useState("none");

    const [showPopUp, setShowPopUp] = useState(false);
    const [surveyData, setSurveyData] = useState([]);
    const [currentSurveyData, setcurrentSurveyData] = useState([]);
    const [titlePopUp, setTitlePopUp] = useState<string>("");
    const [tempLat, setTempLat] = useState<string>("");
    const [tempLon, setTempLon] = useState<string>("");
    const [tempGraphData, settempGraphData] = useState([]);

    /* Chart code */
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    useEffect(() => {
        displayChartUpdate("none");
        let arr: any = [];
        var GarphData: any = [];
        for (let i = 0; i < para?.filteredValues?.length; i++) {
            let mainData = JSON.parse(JSON.stringify(para?.filteredValues[i]?.data));
            const count = ({ emission }: any, type: any) => emission.reduce((r: any, { size }: any) => r + (type === size), 0)

            mainData.sort(
                (a: any, b: any) => {
                    return count(b, "Large") - count(a, "Large") || count(b, "Medium") - count(a, "Medium") || count(b, "Small") - count(a, "Small")
                }
            );

            mainData?.forEach((val: any, it: any) => {
                if (val?.emissionCount > 0) {
                    let flare_small = 0;
                    let flare_medium = 0;
                    let flare_large = 0;
                    let hatch_small = 0;
                    let hatch_medium = 0;
                    let hatch_large = 0;
                    let enardovalve_small = 0;
                    let enardovalve_medium = 0;
                    let enardovalve_large = 0;
                    let vent_small = 0;
                    let vent_medium = 0;
                    let vent_large = 0;
                    let other_small = 0;
                    let other_medium = 0;
                    let other_large = 0;
                    let siteName = '';
                    let lat = '';
                    let lon = '';
                    val.emission.forEach((emission_val: any) => {
                        if (emission_val?.source == "Flare") {
                            siteName = val?.name
                            lat = val?.gmap?.lat
                            lon = val?.gmap?.lon
                            if (emission_val?.size == "Small") {
                                if (emission_val.noOfLeaks != null) {
                                    flare_small = flare_small + emission_val.noOfLeaks;
                                } else {
                                    flare_small++;
                                }
                            }
                            if (emission_val?.size == "Medium") {
                                if (emission_val.noOfLeaks != null) {
                                    flare_medium = flare_medium + emission_val.noOfLeaks;
                                } else {
                                    flare_medium++;
                                }
                            }
                            if (emission_val?.size == "Large") {
                                if (emission_val.noOfLeaks != null) {
                                    flare_large = flare_large + emission_val.noOfLeaks;
                                } else {
                                    flare_large++;
                                }
                            }
                        }
                        if (emission_val?.source == "Hatch") {
                            siteName = val?.name
                            lat = val?.gmap?.lat
                            lon = val?.gmap?.lon
                            if (emission_val?.size == "Small") {
                                if (emission_val.noOfLeaks != null) {
                                    hatch_small = hatch_small + emission_val.noOfLeaks;
                                } else {
                                    hatch_small++;
                                }
                            }
                            if (emission_val?.size == "Medium") {
                                if (emission_val.noOfLeaks != null) {
                                    hatch_medium = hatch_medium + emission_val.noOfLeaks;
                                } else {
                                    hatch_medium++;
                                }
                            }
                            if (emission_val?.size == "Large") {
                                if (emission_val.noOfLeaks != null) {
                                    hatch_large = hatch_large + emission_val.noOfLeaks;
                                } else {
                                    hatch_large++;
                                }
                            }
                        }
                        if (emission_val?.source == "EnardoValve") {
                            siteName = val?.name
                            lat = val?.gmap?.lat
                            lon = val?.gmap?.lon
                            if (emission_val?.size == "Small") {
                                if (emission_val.noOfLeaks != null) {
                                    enardovalve_small = enardovalve_small + emission_val.noOfLeaks;
                                } else {
                                    enardovalve_small++;
                                }
                            }
                            if (emission_val?.size == "Medium") {
                                if (emission_val.noOfLeaks != null) {
                                    enardovalve_medium = enardovalve_medium + emission_val.noOfLeaks;
                                } else {
                                    enardovalve_medium++;
                                }
                            }
                            if (emission_val?.size == "Large") {
                                if (emission_val.noOfLeaks != null) {
                                    enardovalve_large = enardovalve_large + emission_val.noOfLeaks;
                                } else {
                                    enardovalve_large++;
                                }
                            }
                        }
                        if (emission_val?.source == "Vent") {
                            siteName = val?.name
                            lat = val?.gmap?.lat
                            lon = val?.gmap?.lon
                            if (emission_val?.size == "Small") {
                                if (emission_val.noOfLeaks != null) {
                                    vent_small = vent_small + emission_val.noOfLeaks;
                                } else {
                                    vent_small++;
                                }
                            }
                            if (emission_val?.size == "Medium") {
                                if (emission_val.noOfLeaks != null) {
                                    vent_medium = vent_medium + emission_val.noOfLeaks;
                                } else {
                                    vent_medium++;
                                }
                            }
                            if (emission_val?.size == "Large") {
                                if (emission_val.noOfLeaks != null) {
                                    vent_large = vent_large + emission_val.noOfLeaks;
                                } else {
                                    vent_large++;
                                }
                            }
                        }
                        if (emission_val?.source == "Other") {
                            siteName = val?.name
                            lat = val?.gmap?.lat
                            lon = val?.gmap?.lon
                            if (emission_val?.size == "Small") {
                                if (emission_val.noOfLeaks != null) {
                                    other_small = other_small + emission_val.noOfLeaks;
                                } else {
                                    other_small++;
                                }
                            }
                            if (emission_val?.size == "Medium") {
                                if (emission_val.noOfLeaks != null) {
                                    other_medium = other_medium + emission_val.noOfLeaks;
                                } else {
                                    other_medium++;
                                }
                            }
                            if (emission_val?.size == "Large") {
                                if (emission_val.noOfLeaks != null) {
                                    other_large = other_large + emission_val.noOfLeaks;
                                } else {
                                    other_large++;
                                }
                            }
                        }
                    });
                    if (siteName != '') {
                        GarphData.push({
                            'siteName': siteName,
                            "flare_small": flare_small,
                            "hatch_small": hatch_small,
                            "enardovalve_small": enardovalve_small,
                            "vent_small": vent_small,
                            "other_small": other_small,
                            "flare_medium": flare_medium,
                            "hatch_medium": hatch_medium,
                            "enardovalve_medium": enardovalve_medium,
                            "vent_medium": vent_medium,
                            "other_medium": other_medium,
                            "flare_large": flare_large,
                            "hatch_large": hatch_large,
                            "enardovalve_large": enardovalve_large,
                            "vent_large": vent_large,
                            "other_large": other_large,
                            "lat": lat,
                            "lon": lon
                        })
                    }
                }
            });
        }
        // Create chart instance
        let chart = am4core.create("chartsourcediv", am4charts.XYChart);
        GarphData.sort((a: any, b: any) => b - a);

        if (GarphData.length > 20) {
            GarphData = GarphData.slice(0, 20);
        }
        GarphData = GarphData.reverse()
        settempGraphData(GarphData);
        // Add data
        chart.data = GarphData;

        chart.legend = new am4charts.Legend();
        chart.legend.disabled = true;

        // Create axes
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "siteName";
        categoryAxis.renderer.grid.template.opacity = 0;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.grid.template.opacity = 0;
        valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
        valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        valueAxis.renderer.ticks.template.length = 10;
        valueAxis.renderer.line.strokeOpacity = 0.5;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.minGridDistance = 30;
        valueAxis.maxPrecision = 0;
        if (chart.logo) {
            chart.logo.disabled = true;
        }

        chart.responsive.enabled = true;
        categoryAxis.renderer.minGridDistance = 22;
        categoryAxis.renderer.fontSize = 9;
        categoryAxis.renderer.labels.template.tooltipText = t("ClickTooltipText");
        categoryAxis.renderer.labels.template.events.on("hit", async function (event) {
            let tempGLatLon = chart?.data?.filter((itm: any) => itm.siteName == event?.target?.currentText)
            setShowPopUp(true)
            if (tempGLatLon[0]) {
                await AogiSiteService.getAogiSiteSurveyDetails(event?.target?.currentText as string, para?.customer?.customerID as string).then((res) => {
                    if (res?.length > 0) {
                        const updatedData = res.sort((a: any, b: any) => {
                            return (new Date(b.date) as any) - (new Date(a.date) as any); // ascending
                        })
                        setSurveyData(updatedData)
                    }
                })
                setTitlePopUp(event?.target?.currentText)
                setTempLat(tempGLatLon[0]?.lat)
                setTempLon(tempGLatLon[0]?.lon)
                //let siteUrl = '/aogi/site-details/' + event?.target?.currentText + '/' + tempGLatLon[0]?.map?.lat + '/' + tempGLatLon[0]?.map?.lon + '/' + para?.customer?.name + '/' + para?.customer?.customerID;
                //window.open(siteUrl)
            }
        })

        // Create series
        function createSeries(field: any, name: any, color: any) {
            let strokeColor: any = "#fff";
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueX = field;
            series.dataFields.categoryY = "siteName";
            series.stacked = true;
            series.name = name;
            series.columns.template.fill = color;
            series.columns.template.stroke = strokeColor;

            series.columns.template.tooltipText = "{categoryY} : {name} {valueX}";
            var labelBullet: any = series.bullets.push(new am4charts.LabelBullet());
            labelBullet.label.text = "{valueX}";
            labelBullet.label.horizontalCenter = "right";
            labelBullet.label.paddingRight = 10;
            labelBullet.label.fill = am4core.color("#fff");
            series.columns.template.events.on("hit", async function (ev: any) {
                let currentTextVal: string = '';
                if (ev?.target?.dataItem?.dataContext?.siteName) {
                    currentTextVal = ev?.target?.dataItem?.dataContext?.siteName
                    let tempGLatLon = chart?.data?.filter((itm: any) => itm.siteName == currentTextVal)
                    setShowPopUp(true)
                    if (tempGLatLon[0]) {
                        await AogiSiteService.getAogiSiteSurveyDetails(currentTextVal as string, para?.customer?.customerID as string).then((res) => {
                            if (res?.length > 0) {
                                const updatedData = res.sort((a: any, b: any) => {
                                    return (new Date(b.date) as any) - (new Date(a.date) as any); // ascending
                                })
                                setSurveyData(updatedData)
                            }
                        })
                        setTitlePopUp(currentTextVal)
                        setTempLat(tempGLatLon[0]?.lat)
                        setTempLon(tempGLatLon[0]?.lon)
                    }
                }
            });
            labelBullet.label.adapter.add("textOutput", function (text: any, target: any) {
                // Hide labels with 0 value
                if (target.dataItem && target.dataItem.valueX == 0) {
                    return "";
                }
                return text;
            });
        }

        createSeries("flare_large", "Flare", "#f44336");
        createSeries("hatch_large", "Hatch", "#f44336");
        createSeries("enardovalve_large", "enardovalve", "#f44336");
        createSeries("vent_large", "vent", "#f44336");
        createSeries("other_large", "other", "#f44336");

        createSeries("flare_medium", "Flare", "#fc7a0f");
        createSeries("hatch_medium", "Hatch", "#fc7a0f");
        createSeries("enardovalve_medium", "enardovalve", "#fc7a0f");
        createSeries("vent_medium", "vent", "#fc7a0f");
        createSeries("other_medium", "other", "#fc7a0f");

        createSeries("flare_small", "Flare", "#ffb300");
        createSeries("hatch_small", "Hatch", "#ffb300");
        createSeries("enardovalve_small", "enardovalve", "#ffb300");
        createSeries("vent_small", "vent", "#ffb300");
        createSeries("other_small", "other", "#ffb300");
        setTimeout(() => {
            displayChartUpdate("block")
        }, 3000);
    }, [para])

    return <>
        {
            tempGraphData?.length > 0 ? "" : <div style={{ padding: '24px', textAlign: 'center', marginTop: '40px', color: 'rgba(0,0,0,0.87)' }}>{t("NoRecordsToDisplay")}</div>
        }

        <div id="chartsourcediv" style={{ display: displayChart, width: "100%", height: (ResizeStoreToSetHeight) ? "550px" : "350px" }}></div>

        <Dialog header={titlePopUp} visible={showPopUp} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => setShowPopUp(false)} className={'create-modal-popup'}>
            <PopSurveyEmissionData surveyData={surveyData} cid={para?.customer?.customerID} cusname={para?.customer?.name} siteName={titlePopUp} lat={tempLat} lon={tempLon} dropdownValue={[{ 'value': para?.filteredValues[0]?.id, 'label': para?.filteredValues[0]?.name }]} />
        </Dialog>
    </>
}

export default EmissionBySource;