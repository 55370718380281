
import { Header } from './components/common/header';
import { Footer } from './components/common/footer';
import MainNav from './components/common/main-navigator/main-nav';
import SideBar from './components/common/sidebar';
import Operations from "./components/dashboard/operations";
import ContentHeader2 from "./components/content-header/content-header2";
import ReduxSandbox from "./components/content-header/redux-sandbox";



// AOGI Components
import SiteUpload from "./components/AOGI/site-upload/index";
import SurveyIndex from "./components/AOGI/survey/index";
import SurveyDetails from "./components/AOGI/survey-details/index";
import AOGIDashboard from "./components/AOGI/dashboard/dashboard";
import SurveyEdit from './components/AOGI/survey-details/survey-edit';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Component, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from './store/store';
import { isAuth } from './services/AOGI/common';
import React from 'react';

import './assets/css/navy.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import BrokenUrl from './components/common/BrokenUrl';
import { PermissionsProvider } from './providers/PermissionsProvider';
import { useIdleTimer } from 'react-idle-timer';
import SiteDetails from './components/AOGI/site-details';

const idleTimeInMinutes: number = parseInt(process.env.REACT_APP_IDLE_TIME_IN_MINUTES ?? '10');
const mainSiteUrl = process.env.REACT_APP_MVC_URL;

function App(): JSX.Element {
  const [setT, setTest] = useState(false);
  // Check for isAuth (isAuth true after login)
  let authStatus = useSelector((state: AppState) => state.AOGICommon.isAuth);
  isAuth(authStatus);


  const onIdle = () => {
    localStorage.clear();
    if (mainSiteUrl)
      window.location.assign(mainSiteUrl)
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/nifty.min.js";
    script.async = true;
    document.body.appendChild(script);

  }, []);

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * idleTimeInMinutes,
    throttle: 500
  })


  return <>
    <Router>
      <PermissionsProvider>
        <section id="content" className="content">
          <Routes>
            <Route path="/" element={<SurveyIndex />} />
            <Route path="/store" element={<ReduxSandbox setTest={setTest} />} />
            <Route path="/dashboard/Operations" element={<Operations />} />
            <Route path="/dashboard/component2" element={<ContentHeader2 />} />

            {/* AOGI Routes */}
            <Route path="/aogi/siteupload" element={<SiteUpload />} />
            <Route path="/aogi/site-details/:name/:lat/:lon/:cust/:customerId" element={<SiteDetails />} />
            <Route path="/aogi/survey" element={<SurveyIndex />} />
            <Route path="/aogi/dashboard" element={<AOGIDashboard />} />
            <Route path="/aogi/survey-details/:id/:name/:customerId" element={<SurveyDetails />} />
            <Route path="/aogi/survey-details-edit" element={<SurveyEdit />} />
            <Route path='/aogi/broken_url' element={<BrokenUrl />} />



          </Routes>
          <Footer />
        </section>
        <Header />
        <MainNav />
        <SideBar />
        <div className="scroll-container">
          <a href="#root" className="scroll-page rounded-circle ratio ratio-1x1"></a>
        </div>
      </PermissionsProvider>
    </Router>
  </>

}
export default App;