// Loading image
import loadingMid from "../../assets/img/loader-mid.png";
import loadingHELI from "../../assets/img/helicopter-transparent.gif";
import loadingInner from "../../assets/img/loader-inner.png";

const Loading = (loadingCondtion: any) => {
    // Helpers.ConsoleLog(loadingCondtion);
    let LoadingText = loadingCondtion.text;
    if(LoadingText == ""){
        LoadingText = "Loading...";
    }
    if (loadingCondtion.isLoading) {
        document.body.style.overflow = "hidden";
        document.body.style.height = "100%";
        return <>
            <div className="div-loading" >
                <div className="div-loading-inner-div">
                    <div className="div-loading-mid" style={{
                        background: `url(${loadingHELI})`, backgroundSize: "120px 110px",
                        backgroundRepeat: "no-repeat"
                    }}></div>
                
                    {/* <div className="div-loading-mid" style={{ background: `url(${loadingHELI})` }}></div> */}
                    {/* <div className="div-loading-inner" style={{ background: `url(${loadingInner})` }}></div> */}
                </div>
                <div className="div-loading-text">{LoadingText}</div>
            </div>
        </>
    } else {

        document.body.style.overflow = "scroll";
        document.body.style.height = "0%";
        return <></>
    }


}


export default Loading;