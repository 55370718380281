import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Todo {
    message: string;
}

const DashboardOperations = createSlice({
    name: "DashboardOperations",
    initialState: {
        sampleText:""
    },
    reducers: {
        addData: (state: any, action: any) => {
            state.sampleText = action.payload;
            return state;
        }
    }
});

export default DashboardOperations;