import ApiConstant from "../../constants/api.constants";
import ConsoleType from "../../constants/console-type.constant";
import LocalStorageConstant from "../../constants/localstroage.constant";
import AOGISurvey from "../../interfaces/aogi-survey/aogi-survey";
import Helpers from "../../utils/helpers";
import api from "../api";
import axios from 'axios';

const getSurveyList = async (CustID: any, Year: any) => {
  
    let apiValues = '?Year=' + Year;
    
    if (CustID) {
        apiValues = apiValues + '&CustomerID=' + CustID;
    }
    return await api
        .get(ApiConstant.GetAOGIAllSurveyDetails + apiValues)
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLog(error, ConsoleType.Error);
        });



    // let apiValues = '?SurveyYear=';
    //   let apiValues = '?SurveyYear=' + survey.surveyDate?.getFullYear();

    //   if (survey.customerID) {
    //     apiValues = apiValues + '&customerId=' + survey.customerID;
    //   }

    //   return await api
    //     .get(ApiConstant.getAOGISurveys + apiValues)
    //     .then(function (response: any) {
    //       return response.data;
    //     })
    //     .catch(function (error) {
    //       Helpers.ConsoleLog(error, ConsoleType.Error);
    //     });
};








const DashboardService = {
    getSurveyList
};

export default DashboardService;