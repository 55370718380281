// Localisation
import { useTranslation } from "react-i18next";
import './site-upload.css'
import AOGISiteTable from "./aogi-site-table";
import CommonPanel from "../../common/common-panel";
import { useEffect, useState } from "react";
import { addCustomersData } from "../../../store/AOGI/common";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, AppState } from "../../../store/store";
import { Dropdown } from "primereact/dropdown";
import CustomerService from "../../../services/customer/customer.service";
import Helpers from "../../../utils/helpers";
import Customer from "../../../interfaces/customer/customer";
import PersistantService from "../../../services/autentication/persistant.service";


const SiteUpload = () => {
    document.title = "AOGI-Site Upload"
    const { t } = useTranslation();
    let customer = PersistantService.getPersistSelectedCustomer()?.siteUploadSelectedcustomer;

    const [selectedCustomer, setSelectedCustomer] = useState<any>(customer);
    const dispatch: AppDispatch = useDispatch();
    const CustData_Redux = useSelector((state: AppState) => state.AOGICommon.CustData);
    const [customerList, customerListUpdate] = useState<any>([]);
    let [downloadSiteUploadData,setDownloadSiteUploadData] = useState<number>(0);
 
    useEffect(() => {
        CustomerService.getCustomerList().then((res: any) => {
            Helpers.ConsoleLog("create site customers data");
            Helpers.ConsoleLog(res);
            // here will be the cus list

            if (CustData_Redux == "") {
                customerListUpdate(res);
                dispatch(addCustomersData(res as any));
                if (res.length == 1) {
                    setSelectedCustomer(res[0]);
                }
            } else {
                customerListUpdate(CustData_Redux);
                if (CustData_Redux.length == 1) {
                    setSelectedCustomer(CustData_Redux[0]);
                }
            }
            Helpers.ConsoleLog(customerList);
            
        })
    }, []);

    const downloadSiteData =()=>{
        let count = ++downloadSiteUploadData;
        setDownloadSiteUploadData(count);
    }


    const onCustomerChange = (option: any) => {
        setSelectedCustomer(option);
        PersistantService.setPersistSelectedCustomer({siteUploadSelectedcustomer: option});
    }

    return (
        <>
            <section id="content" className="content">
                <div className="content__header content__boxed overlapping">
                    <div className="content__wrap pt-3 pb-4">
                        <p className="lead text-overflow">
                            {t( "SiteUpload.HeaderText" )}
                        </p>
                        <div className="page-heading">
                            <div className="row bg-primary" id="divCustFilter">
                                <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <label className="title"  >{t("CustomerLabel")}</label> &nbsp;
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                            <Dropdown className="siteDropdown" value={selectedCustomer} options={customerList.filter((x: Customer) => x._id != "000000000000000000000000")} onChange={(e: any) => onCustomerChange(e.value)} optionLabel="name" filter filterBy="name" placeholder={t("SiteUpload.IndexCustomer")} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content__boxed">
                    <div className="content__wrap">
                        <div className="card">
                            <CommonPanel downloadData={downloadSiteData} InnerComponent={<AOGISiteTable downloadSiteUploadData={downloadSiteUploadData} selectedCustomer={selectedCustomer} updateSelectedCustomer={setSelectedCustomer} />} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SiteUpload;




