import React from "react";
import InfoIcon from "../../common/InfoIcon";
import { useTranslation } from 'react-i18next';
const FilterComponent = ({ filterText, onFilter, onClear }: { filterText: any, onFilter: any, onClear: any }) => {
  const { t } = useTranslation();

  return (
    <>

      {/* New */}
      <div className="d-flex flex-row  justify-content-start align-items-center" >
        <div className="mobile_survey_details">
          <label className="custom_common_label">{t("SearchLabel")}: &nbsp;&nbsp;&nbsp; </label>
        </div>
        <input type="text" name="sitebulkuploadsearch" value={filterText} id="survery-details-page-search" className="form-control" onChange={onFilter} />
        <div className="surveyDetailssearchClear">
          <button type="button" onClick={onClear} className="btn btn-primary" > X</button >
          <InfoIcon />
        </div>
      </div>
      <br />
    </>
  )

};

export default FilterComponent;