import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ApiConstant from '../../../../constants/api.constants';
//Images
import Leak from "../../../../assets/img/icons/leak.png";
import Vent from "../../../../assets/img/vent.png";
import Flare from "../../../../assets/img/icons/flare.png"
import Hatch from "../../../../assets/img/icons/hatch.png";
import NotesIcon from "../../../../assets/img/notes-image.png";
import OtherLeak from "../../../../assets/img/icons/otherleak.png"
import NoEmission from "../../../../assets/img/icons/onlineCircle.png";
import { Dialog } from 'primereact/dialog';
import SurveyDetailsService from '../../../../services/survey_details/surevy_details.service';
import './pop-emission-data-custom.css';
import { ProgressSpinner } from 'primereact/progressspinner';

const PopEmissionData = (props: any) => {
  const [DisplayVideo, setDisplayVideo] = useState(false);
  const [DisplayImage, setDisplayImage] = useState(false);

  const [popup_video_url, setpopup_video_url] = useState("");
  const [popup_video_loader, setpopup_video_loader] = useState("Loading...");
  const [popup_video_title, setpopup_video_title] = useState("");

  const [popup_image_url, setpopup_image_url] = useState("");
  const [popup_image_title, setpopup_image_title] = useState("");

  const { t } = useTranslation();
  // console.log(props)
  const openVideoForPoup = async (expData: any) => {
    setpopup_video_url("");
    await SurveyDetailsService.GetBlobURI(props?.eData?.cid, props?.eData?.id, expData?.videoID).then((res: any) => {
      setpopup_video_loader("");
      setpopup_video_url(res);
    });
    setpopup_video_title(`${expData?.video} (${(expData?.noOfLeaks == null) ? 1 : expData?.noOfLeaks} ${expData?.source})`);
    setDisplayVideo(true);
  }

  const openSite = () => {
    let siteUrl = "/aogi/site-details/" + encodeURIComponent(props?.eData?.rdata?.title) + '/' + props?.eData?.rdata?.lat + '/' + props?.eData?.rdata?.lng + '/' + encodeURIComponent(props?.eData?.customerName) + '/' + props?.eData?.cid;
    window.open(siteUrl);
  }
  return (
    <div className="">
      <section>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="card h-100">
              <div className="card-body">
                <div className="table-responsive" style={{ border: '15px solid #EEEE' }}>
                  <table className="table table-bordered table-striped ">
                    <thead>
                      <tr className={'col-md-12'}>
                        <th className={'col-md-2'}>{t("SurveyDetails.SourceLabel")}</th>
                        <th className={'col-md-1'}>{t("SurveyDetails.SizeLabel")}</th>
                        <th className={'col-md-1'}>{t("SurveyDetails.LeakPersistanceLabel")}</th>
                        <th className={'col-md-2'}>{t("SurveyDetails.SurveyDateTimeLabel")}</th>
                        <th className={'col-md-1'}>{t("SurveyDetails.PictureLabel")}</th>
                        <th className={'col-md-1'}>{t("SurveyDetails.VideoLabel")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        props?.eData?.rdata?.emission?.length > 0 ? props?.eData?.rdata?.emission?.map((expData: any, i: any) => {
                          var emsFimg: any = '';
                          if (expData?.source == 'Flare') {
                            emsFimg = Flare
                          } else if (expData?.source == 'Other') {
                            emsFimg = OtherLeak
                          } else if (expData?.source == 'Hatch') {
                            emsFimg = Hatch
                          } else if (expData?.source == 'Leak') {
                            emsFimg = Leak
                          } else if (expData?.source == 'EnardoValve') {
                            emsFimg = Leak
                          } else if (expData?.source == 'NoEmission') {
                            emsFimg = NoEmission
                          }
                          else if (expData?.source == 'Vent') {
                            emsFimg = Vent;
                          }

                          return (
                            <tr key={expData?.id} id={expData?.id} className={'col-md-12'}>
                              <td className={'col-md-2'}>
                                {
                                  <img src={emsFimg} /*width={'20px'}*/ height={'20px'} style={{ marginTop: '-0.5vh' }} />
                                }
                                &nbsp;{expData?.source == 'NoEmission' ? 'No Emission' : (expData?.source == 'Vent') ? 'Vent' : expData?.source}
                                &nbsp;- {(expData.noOfLeaks != null) ? expData.noOfLeaks : 1}
                              </td>
                              <td className={'col-md-2'}>{expData.size}</td>
                              <td className={'col-md-1'}>{expData.leakPersistence}</td>
                              <td className={'col-md-2'}>{expData.userLocDateTime ? moment(expData.userLocDateTime).local().format('DD-MMM-YYYY hh:mm A') : ""}</td>
                              <td className={'col-md-1'}>
                                {
                                  (expData.picture == 'null' || expData.picture == null || expData.pictureID == null) ? "-" :
                                    <>
                                      <a
                                        className="btn-link"
                                        onClick={(e) => {
                                          setpopup_image_url("");
                                          setpopup_image_title(expData?.picture);
                                          setpopup_image_url(encodeURI(`${process.env.REACT_APP_SERVER_ENDPOINT}${ApiConstant.dowloadFiles}?id=${props?.eData?.cid}/${props?.eData?.id}/${expData?.pictureID}&Filename=${expData?.picture}&container=aogi-survey`));
                                          setDisplayImage(true);
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className='fa fa-2x fa-image videoCameraIcon' title={expData.picture} />
                                      </a>
                                      &nbsp;&nbsp;
                                      <a className="btn-link" href={encodeURI(`${process.env.REACT_APP_SERVER_ENDPOINT}${ApiConstant.dowloadFiles}?id=${props?.eData?.cid}/${props?.eData?.id}/${expData?.pictureID}&Filename=${expData?.picture}&container=aogi-survey`)} style={{ cursor: "pointer" }}>
                                        <i className="fa fa-2x fa-download text-primary videoCameraIconDownload" aria-hidden="true"></i>
                                      </a>
                                    </>
                                }

                              </td>
                              <td className={'col-md-1'}>
                                {
                                  (expData.video == 'null' || expData?.video == null || expData?.videoID == null) ? "-" : <><a className="btn-link" onClick={(e) => openVideoForPoup(expData)} style={{ cursor: "pointer" }}>
                                    <i className='fa fa-2x fa-video-camera videoCameraIcon' title={expData.video} />
                                  </a>
                                    &nbsp;&nbsp;
                                    <a href={encodeURI(`${process.env.REACT_APP_SERVER_ENDPOINT}${ApiConstant.dowloadFiles}?id=${props?.eData?.cid}/${props?.eData?.id}/${expData?.videoID}&Filename=${expData?.video}&container=aogi-survey`)}>
                                      <i className="fa fa-2x fa-download text-primary videoCameraIconDownload" aria-hidden="true"></i>
                                    </a>
                                  </>
                                }
                              </td>
                            </tr>
                          )
                        })
                          :
                          <>
                            <tr className='no-records' style={{ textAlign: 'center' }}>
                              <td colSpan={6}>{t("SurveyDetails.NullMessage")}</td>
                            </tr>
                          </>
                      }
                    </tbody>
                  </table>
                </div>
                {
                  props?.displaySiteBtn == true ? <div className='mt-3 float-right pull-right'>
                    <button className='btn btn-primary' onClick={() => openSite()}>Goto Site Details</button>
                  </div> : ""
                }
              </div>
            </div>
          </div>

        </div>

        <Dialog header={`Video - ${popup_video_title}`} visible={DisplayVideo} style={{ width: '50vw' }} onHide={() => { setDisplayVideo(false); setpopup_video_url("") }}>
          {
            (popup_video_url == "") ?
              <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> : <video width="100%" height="auto" controls disablePictureInPicture controlsList="nodownload noplaybackrate" autoPlay>
                <source src={popup_video_url} type="video/mp4" />
              </video>
          }
        </Dialog>
        <Dialog header={`Picture - ${popup_image_title}`} visible={DisplayImage} style={{ width: '50vw' }} onHide={() => setDisplayImage(false)}>
          <img src={popup_image_url} width="100%" height="auto"></img>
        </Dialog>

      </section>
    </div>

  )
}

export default PopEmissionData
