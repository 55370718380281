
const ApiConstant: any = {
    getToken: "/api/token",
    getAllCustomerList: "/api/customer/getallcustomers",
    getUser: "/api/auth/getuser",
    addOrUpdateAogiSite: "/api/assets/uploadaogisites",
    getSurveyDetails: "/api/assets/GetAOGISurveyDetails",
    addOrUpdateSurveyDetails: "/api/assets/UpdateSurveyEmission",
    addOrUpdateSurveyDetailsNew: "/api/assets/UpdateSurveyEmissionNew",
    dowloadFiles: "/api/assets/DownloadBlobByID",
    removeSiteDetails: "/api/assets/RemoveAOGISurveySites",
    removeEmissionDetails: "/api/assets/RemoveSurveyEmission",
    getAogiSites: "/api/assets/getaogisites",
    removeAogiSite: "/api/assets/removeaogisite",
    addSurvey: "/api/assets/createaogisurvey",
    getAOGISurveys: "/api/assets/getaogisurveys",
    updateAogiSurvey: "/api/assets/updateaogisurvey",
    removeAogiSurvey: "/api/assets/removeaogisurvey",
    GetAOGIAllSurveyDetails: "/api/assets/GetAOGIAllSurveyDetails",
    getPermissionsOfCurrentUser: "api/auth/getUserPermissions",
    GetBlobURI: "/api/assets/GetBlobURI",
    GetSiteDetails: "/api/assets/GetSiteDetails",
    removeNotesToEmission: "/api/assets/removesurveyemissionnotes",
    addNotesToEmission: "/api/assets/AddSurveyEmissionNotes"
};

export default ApiConstant;
