import React from 'react'
import { Tooltip } from 'primereact/tooltip';
const InfoIcon = () => {
  return (
    <>
      <Tooltip target=".custom-target-icon" />
      <i
        className='pi pi-question-circle custom-target-icon ms-2'
        placeholder="Right"
        data-pr-tooltip='Use "" to search accurately'
        data-pr-position="right"
        data-pr-at="right+5 top"
        data-pr-my="left center-2" />
    </>
  )
}

export default InfoIcon