import axios from "axios";

const GetSurveyList = async (para: any) => {

    return await new Promise((resolve, reject) => {

        // check redux data
        if (para === "") {
            // No data in Redux
            axios
                .get("../assets/sample_json_data/survey.json")
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        } else {
            // Data present in Redux so send as it is
            resolve(para)
        }


    })


    return;
}





const AppendSurvey = async (newData: any, oldData: any) => {

    // let new_survey_data = JSON.parse(newData);
    return await new Promise((resolve, reject) => {

        // After API success then append data to redux store
        let merged_data = [
            ...oldData,
            ...[newData]
        ]

        resolve(merged_data)


    })

}

const UpdateSurvey = async (id: any, updatedData: any, ReduxData: any) => {

    return await new Promise((resolve, reject) => {
        // Send Data to API
        // After API success then remove from Redux Data
        let _ReduxData = JSON.parse(JSON.stringify(ReduxData)); //Created A Copy for rewrite object 
        let _updatedData = updatedData[0]; //Created A Copy for rewrite object 
        _ReduxData.map(function (obj: any) {
            if (obj.id === id) {
                obj.cust_name = _updatedData.cust_name;
                obj.survey_name = _updatedData.survey_name;
                obj.survey_date = _updatedData.survey_date;
                obj.site_id = _updatedData.site_id;
                obj.site_count = _updatedData.site_count;
                obj.requested_by = _updatedData.requested_by;
                obj.requested_on = _updatedData.requested_on;
                obj.status = _updatedData.status;
                obj.surveyTimeZone = _updatedData.surveyTimeZone;

            }
        });
        resolve(_ReduxData);
    })

}

const RemoveSurvey = async (id: any, ReduxData: any) => {
    return await new Promise((resolve, reject) => {

        // Send Data to API




        // After API success then remove from Redux Data
        ReduxData = ReduxData.filter(function (obj: any) {
            return obj.id !== id;
        });


        resolve(ReduxData);


    })

}

const UpdateSurveyList = async (id: any, updatedData: any, ReduxData: any) => {

    return await new Promise((resolve, reject) => {

        // Send Data to API




        // After API success then remove from Redux Data
        let _ReduxData = JSON.parse(JSON.stringify(ReduxData)); //Created A Copy for rewrite object 

        _ReduxData.map(function (obj: any) {

            if (obj.id === id) {
                obj.status = updatedData.status;
            }

        });


        resolve(_ReduxData);


    })

}

// Filters

// By 1) Customers
//    2) Survey Date (only Year)
//    3) Survey Name
//    4) Status (All, Completed, Inprogress,open)
const FilterSurveyList = async (CustID: any, SurveyDate: any, SurveyName: any, Status: any, ReduxData: any) => {

    return await new Promise((resolve, reject) => {


        ReduxData = ReduxData.filter(function (obj: any) {

            // Date Logic Before Filter 
            return (obj.cust_id == CustID || obj.survey_date == SurveyDate || obj.survey_name == SurveyName || obj.status == Status);
        });


        resolve(ReduxData);


    })

}


// Get Data by ID
const GetSurveyByID = async (id: any, ReduxData: any) => {
    return await new Promise((resolve, reject) => {


        let IdData = ReduxData.filter(function (obj: any) {
            return obj.id == id;
        });


        resolve(IdData);


    })

}

export { GetSurveyList, AppendSurvey, RemoveSurvey, UpdateSurveyList, GetSurveyByID, UpdateSurvey };