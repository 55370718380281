import CommonConstant from "../constants/common.constant";
import * as XLSX from 'xlsx';
import { utils, writeFileXLSX } from 'xlsx';

const ConsoleLog = (message: any, data?: string) => {
    if (`${process.env.ENVIRONMENT}` == CommonConstant.localEnvironemt) {
        if (data)
            console.log(message, data);
        else
            console.log(message, data);
    }
};

const ConsoleLogError = (message: any, data?: string) => {
    if (`${process.env.ENVIRONMENT}` == CommonConstant.localEnvironemt) {
        if (data)
            console.error(message, data);
        else
            console.error(message, data);
    }
};

const exportToCSV = (jsonData: any, fileName: string, sheetName?: string, headersRow?: any, keysWhichNeedsTobeFilteredFromJSON?: any) => {
    const fileExtension = '.xlsx';
    var jsonObjectForExcelCreation = jsonData;
    var excelOptions = {};
    var skipHeaderRow = false;

    if (headersRow) {
        skipHeaderRow = true;
    }

    if (keysWhichNeedsTobeFilteredFromJSON?.length > 0) {
        jsonObjectForExcelCreation = jsonObjectForExcelCreation.map((obj: any) => Object.fromEntries(Object.entries(obj).filter((r: any) => keysWhichNeedsTobeFilteredFromJSON?.indexOf(r[0]) > -1)));
        excelOptions = { header: keysWhichNeedsTobeFilteredFromJSON, skipHeader: skipHeaderRow }
    }

    if (headersRow) {
        jsonObjectForExcelCreation.unshift(headersRow);
    }

    var ws: XLSX.WorkSheet = utils.json_to_sheet(jsonObjectForExcelCreation, excelOptions);

    var wb: XLSX.WorkBook = utils.book_new();
    utils.book_append_sheet(wb, ws, sheetName ?? 'Data');

    writeFileXLSX(wb, fileName + "-" + new Date().toISOString() + fileExtension);

}

const getDeviceName = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'TAB'
    } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
        return 'MOBILE'
    } else {
        return 'PC'
    }
}

const Helpers = {
    ConsoleLog,
    ConsoleLogError,
    exportToCSV,
    getDeviceName
};

export default Helpers;