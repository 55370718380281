import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Todo {
    message: string;
}

const AOGISurveyIndex = createSlice({
    name: "AOGISurveyIndex",
    initialState: {
        SurveyData: ""
    },
    reducers: {
        addSurveyData: (state, action: any) => {
            state.SurveyData = action.payload;
            return state;
        }
    }
});
export const { addSurveyData } = AOGISurveyIndex.actions;
export default AOGISurveyIndex;