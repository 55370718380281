import { configureStore, combineReducers } from '@reduxjs/toolkit';
import DashboardOperations from './dashboard-operations';
import AOGISurveyDetails from './AOGI/survey-details';
import AOGICommon from './AOGI/common';
import AOGISurveyIndex from './AOGI/survey';
import AOGISiteUpload from './AOGI/site-upload';
import DashboardStore from './AOGI/dashboard.store';


const rootReducer = combineReducers({
    DashboardOperations: DashboardOperations.reducer,
    AOGISurveyDetails: AOGISurveyDetails.reducer,
    AOGICommon: AOGICommon.reducer,
    AOGISurveyIndex: AOGISurveyIndex.reducer,
    AOGISiteUpload: AOGISiteUpload.reducer,
    DashboardStore: DashboardStore.reducer
});


const store = configureStore({
    reducer: rootReducer
});


export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;

export default store;