import { useContext } from 'react';
import { PermissionsContext } from '../providers/PermissionsProvider';
import Helpers from '../utils/helpers';

export default function usePermissionsContext() {
  const context = useContext(PermissionsContext);
  if (!context) {
    Helpers.ConsoleLogError('usePermissionsContext must be used within a PermissionsProvider');
  }
  return context;
}
