import axios from "axios";

// Redux
import { useDispatch, useSelector } from "react-redux";
import AOGISurveyDetails from "../../store/AOGI/survey-details";
import AOGICommon from "../../store/AOGI/common";
import { AppDispatch, AppState } from "../../store/store";



const GetCustomerList = async (para: any) => {


    return await new Promise((resolve, reject) => {

        // check redux data
        if (para === "") {
            // No data in Redux
            axios
                .get("../assets/sample_json_data/customers.json")
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        } else {
            // Data present in Redux so send as it is
            resolve(para)
        }


    })


    return;
}




//  move to site upload later
const GetSiteList = async (para: any) => {

    return await new Promise((resolve, reject) => {

        // check redux data
        if (para === "") {
            // No data in Redux
            axios
                .get("../assets/sample_json_data/sites.json")
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        } else {
            // Data present in Redux so send as it is
            resolve(para)
        }


    })

}
const AppendSiteList = async (newData: any, oldData: any) => {
    return await new Promise((resolve, reject) => {

        // Send Data to API




        // After API success then append data to redux store
        let merged_data = [
            ...oldData,
            ...newData
        ]

        resolve(merged_data)


    })

}




// Token Based Auth

const isAuth = async (authStatus: boolean) => {
    
    if (authStatus) {
        // will redirect to testenterprise login page
        window.location.href = "https://testenterprise.apergy.com/";
    }
}


const checkTokenForRefresh = () => {

}

export { GetCustomerList, GetSiteList, AppendSiteList, isAuth };