// React
import { useEffect, useMemo, useRef, useState } from 'react';

// React Router
import { useNavigate, useParams } from "react-router-dom";

//Images
import Leak from "../../../assets/img/icons/leak.png";
import Vent from "../../../assets/img/vent.png";

import Flare from "../../../assets/img/icons/flare.png"
import Hatch from "../../../assets/img/icons/hatch.png";
import NotesIcon from "../../../assets/img/notes-image.png";
import OtherLeak from "../../../assets/img/icons/otherleak.png"
import NoEmission from "../../../assets/img/icons/onlineCircle.png";
import Marker from '../../../assets/img/marker.png'
import { BsSortDown } from 'react-icons/bs';
// Datatable
import DataTable, { ExpanderComponentProps, TableStyles } from 'react-data-table-component';

// Prime UI
import { Dialog } from 'primereact/dialog';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

// Google Map
import GoogleMap from "./map/google-map";

// css
import SurveyDetailsIndexCSS from "./css/index.module.scss";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import './DialogDemo.css';
import './css/jm.css';

//  Components
import FilterComponent from './FilterComponent';
import SurveyEdit from './survey-edit';
import Group_icons from "./group_icons"
import { Toast } from 'primereact/toast';

// Store 
import { AppDispatch, AppState } from '../../../store/store';
import { AddSurveyDetails } from '../../../store/AOGI/survey-details';


// Services
import { FilterSurveyDetailList, FilterSurveyDetailListNew, RemoveSurveyDetails, RemoveSurveyDetailsMultiple, DeleteSubSurveyDetails } from '../../../services/AOGI/survey-details';
import SurveyDetailsService from "../../../services/survey_details/surevy_details.service";

// React Redux
import { useDispatch, useSelector } from "react-redux";
import ApiConstant from '../../../constants/api.constants';
import usePermissionsContext from '../../../hooks/usePermissionContext';
// loading screen
import LoadingScreen from "../../common/Loading";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import Select from 'react-select';
import SiteNotes from './components/site-notes.component';
import Helpers from '../../../utils/helpers';

function Table(para: any) {

    let { canAccessEditSuveyDetails } = usePermissionsContext();
    const { t } = useTranslation();
    const [popup_video_url, setpopup_video_url] = useState("");
    const [popup_video_title, setpopup_video_title] = useState("");

    const [popup_image_url, setpopup_image_url] = useState("");
    const [popup_image_title, setpopup_image_title] = useState("");

    const [isLoading, isLoadingUpdate] = useState(false);
    const [loadingText, loadingTextUpdate] = useState("");
    const [DisplayVideo, setDisplayVideo] = useState(false);
    const [DisplayImage, setDisplayImage] = useState(false);
    const [tempSurveyTimeZone, setTempSurveyTimeZone] = useState("");
    const [notesSuccess, setNotesSuccess] = useState(false);
    const [surveyNotesUpdate, setSurveyNotesUpdate] = useState<any>("");

    const { id } = useParams();
    const { customerId } = useParams();
    const sortIcon = <BsSortDown />;
    const toast = useRef<any>(null);
    const dataTableStyles: TableStyles = {

        headCells: {
            style: {
                borderTop: "1px solid rgba(0,0,0,0.07)",
                borderLeft: "1px solid rgba(0,0,0,0.07)",
                '&:last-of-type': {
                    borderRight: "1px solid rgba(0,0,0,0.07)",
                },
                justifyContent: "space-between",
                fontWeight: "bold",
                paddingRight: "5px",
                paddingBottom: "0px !important",

            }
        },
        cells: {
            style: {
                borderLeft: "1px solid rgba(0,0,0,0.07)",
                '&:last-of-type': {
                    borderRight: "1px solid rgba(0,0,0,0.07)",
                }
            }
        }
    }
    const [remove_selected_array_ids, Updateremove_selected_array_ids] = useState([]);
    const [SurveyDetailsData, UpdateSurveyDetailsData] = useState<any>("");
    const [SurveyUpdatedDetailsData, UpdateSurveyUpdatedDetailsData] = useState<any>("");
    const [customToast, UpdateCustomToast] = useState<any>("");
    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    const [statusOptValue, setStatusOptValue] = useState<any>("");
    const [selectedCompStatus, setSelectedCompStatus] = useState(null);
    var customToastFlag = customToast;
    const [currentEmissionData, setCurrentEmissionData] = useState(null);
    const [selectedEmissionSurveyId, setSelectedEmissionSurveyId] = useState("");
    const groupedDpdowns = [
        {
            label: 'Size',
            items: [
                { label: 'Small', value: 'Small' },
                { label: 'Medium', value: 'Medium' },
                { label: 'Large', value: 'Large' },
            ]
        },
        {
            label: 'Source',
            items: [
                { label: 'Flare', value: 'Flare' },
                { label: 'Hatch', value: 'Hatch' },
                { label: 'Enardo Valve', value: 'Enardo Valve' },
                { label: 'Vent', value: 'Vent' },
                { label: 'Other', value: 'Other' },
                { label: 'No Emission', value: 'No Emission' },
            ]
        },
        {
            label: 'Leak Persistence',
            items: [
                { label: 'Persistent', value: 'Persistent' },
                { label: 'Intermittent', value: 'Intermittent' },
            ]
        }
    ];
    const groupedItemTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{option.label}</div>
            </div>
        );
    };
    // data provides access to your row data
    const [CurrentPageFilteredData, UpdateCurrentPageFilteredData] = useState<any>("");

    const ExpandedComponent: React.FC<ExpanderComponentProps<any>> = ({ data }) => {
        const navigate = useNavigate();
        const [displaySubBasic, setDisplaySubBasic] = useState(false);

        const [TempDataForUpdateSite, UpdateTempDataForUpdateSite] = useState<any>("");

        let emissionData = data.emission;

        const editShowSubPopUp = (eVal: any) => {
            UpdateTempDataForUpdateSite(eVal)
            setDisplaySubBasic(true)
        }

        const openNotes = (data: any, pid: any) => {
            setCurrentEmissionData(data);
            setSelectedEmissionSurveyId(pid);
            setDisplayNotesPopup(true);
            setSendDispNote(true)
        }

        useEffect(() => {
            if (notesSuccess == true) {
                dispatch(AddSurveyDetails(surveyNotesUpdate as any));
            }
        }, [notesSuccess])


        const deleteSubTblData = (rec: any, pid: any) => {
            confirmDialog({
                message: 'Are you sure you want to remove ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    loadingTextUpdate("Processing...");
                    isLoadingUpdate(true);
                    DeleteSubSurveyDetails(rec?.id, SurveyDetailsData, pid).then((res: any) => {

                        dispatch(AddSurveyDetails(res as any));
                        UpdateSurveyDetailsData(res);
                        UpdateCurrentPageData(res[0]?.data);
                        UpdateCurrentPageFilteredData(res[0]?.data)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Removed Successfully', life: 10000 });
                        loadingTextUpdate("");
                        isLoadingUpdate(false);
                    });

                    setTimeout(() => {
                        loadingTextUpdate("");
                        isLoadingUpdate(false);
                    }, 15000)
                },
                // reject: () => rejectFunc()
            });
        }

        const downloadPictureVideo = (e: any, udata: any, fname: any) => {
            e.preventDefault()
            confirmDialog({
                message: 'Are you sure to download ' + fname + ' ?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: "Yes",
                rejectLabel: "No",
                acceptClassName: 'btn btn-primary',
                accept: () => {
                    window.location.href = udata
                }
            });
        }
        let _emissionData = JSON.parse(JSON.stringify(emissionData));

        var sortByPosition = function (obj1: any, obj2: any) {
            return obj2.noOfLeaks - obj1.noOfLeaks;
        };

        var grouped: any = {};

        for (var i = 0; i < _emissionData.length; i += 1) {
            if (!grouped[_emissionData[i].source]) {
                grouped[_emissionData[i].source] = [];
            }
            grouped[_emissionData[i].source].push(_emissionData[i]);
        }

        for (var group in grouped) {
            grouped[group] = grouped[group].sort(sortByPosition);
        }
        grouped = Object.values(grouped);
        let _Emission_data: any = [];
        grouped.map((valMerge: any) => {
            _Emission_data.push(...valMerge);
        });

        const openVideoForPoup = async (expData: any) => {

            setpopup_video_url("");

            await SurveyDetailsService.GetBlobURI(customerId, id, expData?.videoID).then((res: any) => {
                setpopup_video_url(res);
            });
            setpopup_video_title(`${expData?.video} (${(expData?.noOfLeaks == null) ? 1 : expData?.noOfLeaks} ${expData?.source})`);

            setDisplayVideo(true);

        }

        return (
            <>
                <div className="table-responsive" style={{ border: '15px solid #EEEE' }}>
                    <table className="table table-bordered table-striped customSubTbl">
                        <thead>
                            <tr className={'col-md-12'}>
                                <th className={'col-md-2'}>{t("SurveyDetails.SourceLabel")}</th>
                                <th className={'col-md-1'}>{t("SurveyDetails.SizeLabel")}</th>
                                <th className={'col-md-2'}>{t("SurveyDetails.DescriptionLabel")}</th>
                                <th className={'col-md-1'}>{t("SurveyDetails.LeakPersistanceLabel")}</th>
                                <th className={'col-md-2'}>{t("SurveyDetails.SurveyDateTimeLabel")}</th>
                                <th className={'col-md-1'}>{t("SurveyDetails.PictureLabel")}</th>
                                <th className={'col-md-1'}>{t("SurveyDetails.VideoLabel")}</th>
                                <th className={'col-md-2'}>{t("SurveyDetails.TableActionLabel")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                emissionData?.length > 0 ? _Emission_data?.map((expData: any, i: any) => {
                                    var emsFimg: any = '';
                                    if (expData?.source == 'Flare') {
                                        emsFimg = Flare
                                    } else if (expData?.source == 'Other') {
                                        emsFimg = OtherLeak
                                    } else if (expData?.source == 'Hatch') {
                                        emsFimg = Hatch
                                    } else if (expData?.source == 'Leak') {
                                        emsFimg = Leak
                                    } else if (expData?.source == 'EnardoValve') {
                                        emsFimg = Leak
                                    } else if (expData?.source == 'NoEmission') {
                                        emsFimg = NoEmission
                                    }
                                    else if (expData?.source == 'Vent') {
                                        emsFimg = Vent;
                                    }
                                    return (
                                        <tr key={expData?.id} id={expData?.id} className={'col-md-12'}>
                                            <td className={'col-md-2'}>
                                                {
                                                    <img src={emsFimg} /*width={'20px'}*/ height={'20px'} style={{ marginTop: '-0.5vh' }} />
                                                }
                                                &nbsp;{expData?.source == 'NoEmission' ? 'No Emission' : (expData?.source == 'Vent') ? 'Vent' : expData?.source}
                                                &nbsp;- {(expData.noOfLeaks != null) ? expData.noOfLeaks : 1}
                                            </td>
                                            <td className={'col-md-1'}>{expData.size}</td>
                                            <td className={'col-md-2'}>
                                                {
                                                    (expData.description == "null" || expData.description == null) ? "" : expData.description
                                                }

                                            </td>
                                            <td className={'col-md-1'}>{expData.leakPersistence}</td>
                                            <td className={'col-md-2'}>{expData.userLocDateTime ? moment(expData.userLocDateTime).local().format('DD-MMM-YYYY hh:mm A') : ""}</td>
                                            <td className={'col-md-1'}>
                                                {
                                                    (expData.picture == 'null' || expData.picture == null || expData.pictureID == null) ? "-" :
                                                        <><a className="btn-link" onClick={(e) => {
                                                            setpopup_image_url("");
                                                            setpopup_image_title(expData?.picture);
                                                            setpopup_image_url(encodeURI(`${process.env.REACT_APP_SERVER_ENDPOINT}${ApiConstant.dowloadFiles}?id=${customerId}/${id}/${expData?.pictureID}&Filename=${expData?.picture}&container=aogi-survey`));
                                                            setDisplayImage(true);
                                                        }} style={{ cursor: "pointer" }}>
                                                            {/* {expData.picture}  */}
                                                            <i className='fa fa-2x fa-image' title={expData.picture} style={{ fontSize: '15px', cursor: "pointer", color: "#25476a" }} />
                                                        </a>&nbsp;&nbsp;
                                                            <i onClick={(e) => downloadPictureVideo(e, `${process.env.REACT_APP_SERVER_ENDPOINT}${ApiConstant.dowloadFiles}?id=${customerId}/${id}/${expData?.pictureID}&Filename=${expData?.picture}&container=aogi-survey`, expData.picture)} className="fa fa-2x fa-download text-primary" aria-hidden="true" style={{ fontSize: '15px', marginRight: '1%', marginLeft: "4%", cursor: "pointer" }}></i>
                                                        </>
                                                }
                                            </td>
                                            <td className={'col-md-1'}>
                                                {
                                                    (expData.video == 'null' || expData?.video == null || expData?.videoID == null) ? "-" : <><a className="btn-link" onClick={(e) => openVideoForPoup(expData)} style={{ cursor: "pointer" }}>
                                                        <i className='fa fa-2x fa-video-camera' title={expData.video} style={{ fontSize: '15px', cursor: "pointer", color: "#25476a" }} />
                                                    </a>
                                                        &nbsp;&nbsp;
                                                        <i onClick={(e) => downloadPictureVideo(e, `${process.env.REACT_APP_SERVER_ENDPOINT}${ApiConstant.dowloadFiles}?id=${customerId}/${id}/${expData?.videoID}&Filename=${expData?.video}&container=aogi-survey`, expData.video)} className="fa fa-2x fa-download text-primary" aria-hidden="true" style={{ fontSize: '15px', marginRight: '1%', marginLeft: "4%", cursor: "pointer" }}></i></>
                                                }
                                            </td>

                                            <td className={'col-md-2'}>
                                                {canAccessEditSuveyDetails && <>
                                                    <i className="fa fa-2x fa-edit text-primary" aria-hidden="true" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={(e) => editShowSubPopUp(expData)}></i>
                                                    &nbsp;&nbsp;
                                                    <i className="fa fa-2x fa-trash text-danger" aria-hidden="true" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={(e) => deleteSubTblData(expData, data?.id)}></i>
                                                    &nbsp;&nbsp;
                                                </>
                                                }

                                                <i className="fa fa-2x fa-solid fa-sticky-note-o  " aria-hidden="true" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={(e) => openNotes(expData, data?.id)}>
                                                    {expData?.notes?.length > 0 ? <><span className='badge_survey_details badge-super rounded bg-danger' >{expData?.notes?.length}</span></> : ""}
                                                </i>
                                            </td>
                                        </tr>
                                    )
                                })
                                    :
                                    <>
                                        <tr className='no-records' style={{ textAlign: 'center' }}>
                                            <td colSpan={6}>{t("SurveyDetails.NullMessage")}</td>
                                        </tr>
                                    </>
                            }
                        </tbody>
                    </table>
                    <Dialog header={t("SurveyDetails.TableEditPopupHeader")} visible={displaySubBasic} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => setDisplaySubBasic(false)} className={'create-modal-popup'}>

                        <SurveyEdit UpdateSurveyUpdatedDetailsData={UpdateSurveyUpdatedDetailsData} editData={TempDataForUpdateSite} data={data} mainData={SurveyDetailsData} type="inner" id="" customerId={customerId} siteName={TempSiteName} frmTitle={'Update'} setDisplaySubBasic={setDisplaySubBasic} UpdateCustomToast={UpdateCustomToast} surveyId={id} surveyTimeZone={tempSurveyTimeZone} />
                        <br />
                    </Dialog>
                </div>
            </>
        );
    };

    // Expandable Table cmponent
    const [CurrentPageData, UpdateCurrentPageData] = useState<any>("");

    const [PageID, UpdateCurrentPageID] = useState<any>("");
    const [displayBasic, setDisplayBasic] = useState(false);
    const [showRmvSteBtn, setShowRmvSteBtn] = useState(false);
    const [TempIDForDeletUpdate, UpdateTempIDForDeletUpdate] = useState("");
    const [TempSiteName, setTempSiteName] = useState("");

    // Filters
    const [StatusOpen, UpdateStatusOpen] = useState<any>("btn-primary");
    const [StatusCompleted, UpdateStatusCompleted] = useState<any>("btn-primary");
    const [StatusOthers, UpdateStatusOthers] = useState<any>("btn-primary");

    // Size
    const [SizeSmall, UpdateSizeSmall] = useState<any>("btn-primary");
    const [SizeMedium, UpdateSizeMedium] = useState<any>("btn-primary");
    const [SizeLarge, UpdateSizeLarge] = useState<any>("btn-primary");

    // Source
    const [SourceNoEmission, UpdateSourceNoEmission] = useState<any>("btn-primary");
    const [SourceFlare, UpdateSourceFlare] = useState<any>("btn-primary");
    const [SourceHatch, UpdateSourceHatch] = useState<any>("btn-primary");
    const [SourceEnardoValve, UpdateSourceEnardoValve] = useState<any>("btn-primary");
    const [SourceVent, UpdateSourceVent] = useState<any>("btn-primary");


    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [displayNotesPopup, setDisplayNotesPopup] = useState(false);
    const [sendDispNote, setSendDispNote] = useState(displayNotesPopup);

    // map
    const [displayMap, setDisplayMap] = useState(false);
    const [MapLat, setMapLat] = useState(false);
    const [MapLon, setMapLon] = useState(false);
    const [MapName, setMapName] = useState("");

    const dispatch: AppDispatch = useDispatch();
    const SurveyDetails_Redux = useSelector((state: AppState) => state.AOGISurveyDetails.SurveyDetailsData);

    useEffect(() => {

        isLoadingUpdate(true)
        setTimeout(() => {
            isLoadingUpdate(false)
        }, 1000);

        // Current Page ID will catch as parameter
        let catched_id = id;
        UpdateCurrentPageID(id);
        // Here it will check the survey details data by current page id, if available then no action.
        isLoadingUpdate(true)

        if (SurveyDetails_Redux.length == 0) {
            // GetSurveyDetails(SurveyDetails_Redux).then((res: any) => {
            //     dispatch(AddSurveyDetails(res as any));
            //     UpdateSurveyDetailsData(res);


            //     let Temp_Data = res.filter((obj: any) => {
            //         if (obj.id == id) {
            //             return true;
            //         }
            //     });
            //     UpdateCurrentPageData(Temp_Data[0]?.data);
            //     Updatka haiaieCurrentPageFilteredData(Temp_Data[0]?.data)
            //     isLoadingUpdate(false)

            // });

            // dispatch(AddSurveyDetails(SurveyDetails_Redux as any));
            // UpdateSurveyDetailsData(SurveyDetails_Redux);
            // var _SurveyDetails_Redux: any = SurveyDetails_Redux;
            // let Temp_Data = _SurveyDetails_Redux?.filter((obj: any) => {
            //     if (obj.id == id) {
            //         return true;
            //     }
            // });
            // UpdateCurrentPageData(Temp_Data[0]?.data);
            // UpdateCurrentPageFilteredData(Temp_Data[0]?.data)
            // isLoadingUpdate(false)

        } else {
            dispatch(AddSurveyDetails(SurveyDetails_Redux as any));
            UpdateSurveyDetailsData(SurveyDetails_Redux);
            var _SurveyDetails_Redux: any = SurveyDetails_Redux;
            let Temp_Data = _SurveyDetails_Redux?.filter((obj: any) => {
                if (obj.id == id) {
                    return true;
                }
            });
            UpdateCurrentPageData(Temp_Data[0]?.data);
            UpdateCurrentPageFilteredData(Temp_Data[0]?.data)
            isLoadingUpdate(false)
            setTempSurveyTimeZone(Temp_Data?.[0]?.surveyTimeZone)
        }

        if (customToastFlag == "Add") {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Added Successfully', life: 10000 });
            UpdateCustomToast("")
        } else if (customToastFlag == "Update") {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Updated Successfully', life: 10000 });
            UpdateCustomToast("")
        }
    }, [para]);



    // Add/Edit POPUP
    useEffect(() => {
        FilterSurveyDetailListNew(CurrentPageData, statusOptValue, selectedCompStatus).then((res) => {
            UpdateCurrentPageFilteredData(res)
        })
    }, [statusOptValue, selectedCompStatus/*, StatusOpen, StatusCompleted, StatusOthers, SizeSmall, SizeMedium, SizeLarge, SourceNoEmission, SourceFlare, SourceHatch, SourceEnardoValve, SourceVent*/]);


    useEffect(() => {
        if (popup_video_url != "") {
            setTimeout(() => {
            }, 500)

        }

    }, [popup_video_url]);

    useEffect(() => {
        if (popup_image_url != "") {
            setDisplayImage(true);
        }

    }, [popup_image_url]);

    function openMapPopup(prop: any) {
        setDisplayMap(true);
    }


    const mainTblAdd = (rec: any) => {
        UpdateTempIDForDeletUpdate(rec?.id)
        setDisplayBasic(true);
        setTempSiteName(rec?.name)
    }

    const mainTblDelete = (rec: any) => {
        UpdateTempIDForDeletUpdate(rec?.id)
        confirmDialog({
            message: 'Are you sure you want to remove this site ' + rec?.name + ' ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Yes",
            rejectLabel: "No",
            acceptClassName: 'btn btn-primary',
            accept: () => {
                loadingTextUpdate("Processing...");
                isLoadingUpdate(true);

                RemoveSurveyDetails(rec?.id, id, SurveyDetailsData).then((res: any) => {
                    if (res == false) {
                        loadingTextUpdate("");
                        isLoadingUpdate(false);
                        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Cannot Remove Survey ' + rec?.name, life: 10000 });
                    } else {
                        dispatch(AddSurveyDetails(res as any));
                        UpdateSurveyDetailsData(res);
                        UpdateCurrentPageData(res[0]?.data);
                        UpdateCurrentPageFilteredData(res[0]?.data)
                        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Removed Successfully', life: 10000 });
                        loadingTextUpdate("");
                        isLoadingUpdate(false);
                    }
                })
            },
        });
    }

    const subTblEdit = (rec: any) => {
        UpdateTempIDForDeletUpdate(rec?.id)
        setDisplayBasic(true);
    }

    const subTblDelete = (rec: any) => {
        UpdateTempIDForDeletUpdate(rec?.id)
        confirmDialog({
            message: 'Are you sure you want to remove ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Yes",
            rejectLabel: "No",
            acceptClassName: 'btn btn-primary',
            accept: () => {
                loadingTextUpdate("Processing...");
                isLoadingUpdate(true);
                RemoveSurveyDetails(rec?.id, id, SurveyDetailsData).then((res: any) => {
                    dispatch(AddSurveyDetails(res as any));
                    UpdateSurveyDetailsData(res);
                    UpdateCurrentPageData(res[0]?.data);
                    UpdateCurrentPageFilteredData(res[0]?.data);
                    loadingTextUpdate("");
                    isLoadingUpdate(false);
                });
                setTimeout(() => {
                    loadingTextUpdate("");
                    isLoadingUpdate(true);
                }, 15000)
            },
            // reject: () => { }
        });
    }


    const columns: any = [
        {
            name: t("SurveyDetails.TableFID"),
            selector: (row: any) => {
                return row.fid
            },
            sortable: true,
            width: "70px",
        }, {
            name: t("SurveyDetails.TableName"),
            selector: (row: any) => row.name,
            sortable: true,
        }, {
            name: t("SurveyDetails.TableType"),
            selector: (row: any) => row.type,
            sortable: true,
        }
        ,
        {
            name: t("SurveyDetails.TableCounty"),
            selector: (row: any) => row.county,
            sortable: true,
            width: "100px",
        },
        {
            name: t("SurveyDetails.TableState"),
            selector: (row: any) => row.state,
            sortable: true,
            width: "100px",
        },
        {
            name: t("SurveyDetails.TableMap"),
            selector: (row: any) => (<>{<img src={Marker} width="50%" height="50%" style={{ textAlign: 'center', display: 'block', justifyContent: 'center', alignItems: 'center', margin: 'auto', cursor: 'pointer' }} onClick={() => {
                let gmap = row.gmap;

                let lat = gmap.lat;
                let lon = gmap.lon;
                setMapLat(lat)
                setMapLon(lon);
                setMapName(row.name);
                openMapPopup(row.gmap)
            }} />}</>

            ),
            width: "80px",
        },
        {
            name: t("SurveyDetails.TableEmission"),
            selector: (row: any) =>
            (
                <Group_icons row={row} customerId={customerId} surveyId={id} />
            )
        },
        {
            name: t("SurveyDetails.TableAction"),
            selector: (row: any) => (
                <>
                    {canAccessEditSuveyDetails &&
                        <i className="fa fa-2x fa-plus text-primary" aria-hidden="true" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={(e) => mainTblAdd(row)}></i>
                    }
                    &nbsp;&nbsp;
                    {canAccessEditSuveyDetails &&
                        <i className="fa fa-2x fa-trash text-danger" aria-hidden="true" style={{ fontSize: '20px', cursor: 'pointer' }} onClick={(e) => mainTblDelete(row)}></i>
                    }

                </>
                // <SplitButton label="&nbsp;" dropdownIcon="pi pi-ellipsis-v" onShow={() => { UpdateTempIDForDeletUpdate(row.id) }} model={mainActionItems} className="p-button-text p-button-sm p-button-secondary"></SplitButton>
            ),
            width: "100px",
            omit: canAccessEditSuveyDetails ? false : true
        },
    ];



    // Leak Flare Hatch OtherLeak
    const data = CurrentPageFilteredData?.length > 0 ? CurrentPageFilteredData : [];



    const filteredItems = data.filter((item: {
        type: any;
        name: { toString: () => string; };
        state: { toString: () => string; };
        county: { toString: () => string; };
        fid: { toString: () => string; };
    }) => {

        let regex = /"(\\.|[^"\\])*"/g;;
        var exactMatchText: any = filterText != null ? filterText.match(regex)?.[0].replace(/"/g, "") : null;

        if (exactMatchText) {
            return (item.name.toString().toLowerCase() == exactMatchText.toLowerCase() ||
                item.county.toString().toLowerCase() == exactMatchText.toLowerCase() ||
                item.state.toString().toLowerCase() == exactMatchText.toLowerCase() ||
                item.type.toString().toLowerCase() == exactMatchText.toLowerCase() ||
                item?.fid?.toString().toLowerCase() == exactMatchText.toLowerCase())
        } else {
            return (item.name.toString().toLowerCase().includes(filterText.toLowerCase()) ||
                item.county.toString().toLowerCase().includes(filterText.toLowerCase()) ||
                item.state.toString().toLowerCase().includes(filterText.toLowerCase()) ||
                item.type.toString().toLowerCase().includes(filterText.toLowerCase()) ||
                item?.fid?.toString()?.includes(filterText))
        }

    })
    const handleCheckChecked = (state: any) => {

        if (state?.selectedCount > 0) {
            setShowRmvSteBtn(true);
            if (state.selectedRows.length === 1) {
                let temp = Array(state.selectedRows[0]?.id);
                Updateremove_selected_array_ids(temp as any)

            } else {
                let temp = state.selectedRows.map((a: any) => a.id);
                Updateremove_selected_array_ids(temp as any)

            }

        } else {
            setShowRmvSteBtn(false)
            Updateremove_selected_array_ids([])
        }
    }

    const handleStatusChange = (e: any) => {
        setStatusOptValue(e?.value)
        if (e?.value == "Completed") {
            setShowCompleted(true)
        } else {
            setShowCompleted(false)
            setSelectedCompStatus(null)
        }
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return (
            <>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-4 col-sm-12'>
                            <FilterComponent onFilter={(e: any) => setFilterText(e?.target?.value)} onClear={handleClear} filterText={filterText} />
                        </div>

                        <div className="col-md-6 col-sm-12">

                            {/* New */}
                            <div className="d-flex flex-row  justify-content-start align-items-center" style={{ marginTop: "0.5rem" }}>
                                <div className="mobile_survey_details" >
                                    <label className="custom_common_label">{t("Survey.TableStatus")}: &nbsp;&nbsp;&nbsp; </label>
                                </div>
                                <div style={{ width: '150px' }}>
                                    <Select
                                        className={'basic-single'}
                                        classNamePrefix="select"
                                        placeholder={'Choose an item'}
                                        id="source"
                                        name="source"
                                        options={[{ value: 'All', label: 'All' }, { value: 'Open', label: 'Open' }, { value: 'Completed', label: 'Completed' }]}
                                        onChange={(e) => handleStatusChange(e)}
                                    />
                                </div>

                                {(() => {
                                    if (showCompleted == true) {
                                        if (Helpers.getDeviceName() == "MOBILE" || Helpers.getDeviceName() == "TAB") {
                                            return (
                                                <>
                                                    <div className="surveyDetailssearchClear" style={{ marginTop: "0px", maxWidth: '100px' }}>
                                                        <MultiSelect
                                                            value={selectedCompStatus}
                                                            options={groupedDpdowns}
                                                            onChange={(e) => setSelectedCompStatus(e.value)}
                                                            optionLabel="label"
                                                            optionGroupLabel="label"
                                                            optionGroupChildren="items"
                                                            optionGroupTemplate={groupedItemTemplate}
                                                            placeholder="Choose an item"
                                                            display="chip"
                                                            className=""
                                                            style={{ maxWidth: '100px' }}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        } else {
                                            return (
                                                <>
                                                    <div className="surveyDetailssearchClear" style={{ marginTop: "0px", maxWidth: '200px' }}>
                                                        <MultiSelect
                                                            value={selectedCompStatus}
                                                            options={groupedDpdowns}
                                                            onChange={(e) => setSelectedCompStatus(e.value)}
                                                            optionLabel="label"
                                                            optionGroupLabel="label"
                                                            optionGroupChildren="items"
                                                            optionGroupTemplate={groupedItemTemplate}
                                                            placeholder="Choose an item"
                                                            display="chip"
                                                            className=""
                                                            style={{ width: '300px' }}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        }

                                    }

                                })()}

                                {/* {
                                    showCompleted == false ? "" :

                                        <div className="surveyDetailssearchClear" style={{ marginTop: "0px", maxWidth: '250px' }}>
                                            <MultiSelect
                                                value={selectedCompStatus}
                                                options={groupedDpdowns}
                                                onChange={(e) => setSelectedCompStatus(e.value)}
                                                optionLabel="label"
                                                optionGroupLabel="label"
                                                optionGroupChildren="items"
                                                optionGroupTemplate={groupedItemTemplate}
                                                placeholder="Choose an item"
                                                display="chip"
                                                className=""
                                                style={{ maxWidth: '300px' }}
                                            />
                                        </div>
                                } */}


                            </div>

                            <br />

                        </div>
                        <div className="col-md-2 col-sm-12">
                            {
                                showRmvSteBtn == false ? '' : canAccessEditSuveyDetails ?
                                    <div className={'float-right pull-right'}>
                                        <button className="btn btn-sm btn-primary" style={{ fontWeight: '500', borderRadius: "0px", marginTop: '8px' /*position: "absolute", left: "85%", zIndex: "999"*/ }} onClick={() => {
                                            confirmDialog({
                                                message: t("SurveyDetails.DeleteConfirmationMessage"),
                                                header: 'Confirmation',
                                                icon: 'pi pi-exclamation-triangle',
                                                accept: () => {
                                                    loadingTextUpdate("Processing...");
                                                    isLoadingUpdate(true);
                                                    RemoveSurveyDetailsMultiple(remove_selected_array_ids, id, SurveyDetailsData).then((res: any) => {
                                                        if (res == false) {
                                                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Cannot Remove Selected Surveys', life: 10000 });
                                                            loadingTextUpdate("");
                                                            isLoadingUpdate(false);
                                                        } else {
                                                            dispatch(AddSurveyDetails(res as any));
                                                            UpdateSurveyDetailsData(res);
                                                            UpdateCurrentPageData(res[0]?.data);
                                                            UpdateCurrentPageFilteredData(res[0]?.data);
                                                            setShowRmvSteBtn(false)
                                                            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Removed Successfully', life: 10000 });
                                                            loadingTextUpdate("");
                                                            isLoadingUpdate(false);
                                                        }

                                                    })
                                                },
                                            });
                                        }}>{t("SurveyDetails.RemoveAllSites")} </button>
                                    </div>

                                    : ''
                            }
                        </div>
                    </div>
                </div >
            </>
        );
    }, [filterText, resetPaginationToggle, showCompleted, selectedCompStatus, showRmvSteBtn, remove_selected_array_ids]);

    return (
        <>
            <LoadingScreen isLoading={isLoading} text={loadingText} />
            <Toast ref={toast} />

            <div className={SurveyDetailsIndexCSS.survey_details_table_div_main}>
            </div>

            <div className="table-responsive">
                <DataTable
                    selectableRows
                    onSelectedRowsChange={handleCheckChecked}
                    columns={columns}
                    data={filteredItems}
                    keyField={id}
                    pagination
                    expandableRows
                    expandOnRowClicked={true}
                    expandableRowsComponent={ExpandedComponent}
                    expandableIcon={
                        {
                            collapsed: <i className="fa fa-2x fa-plus-circle" style={{ color: '#e74c3c', fontSize: '20px', textAlign: 'center', display: 'block', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}></i>,
                            expanded: <i className="fa fa-2x fa-minus-circle" style={{ color: '#e74c3c', fontSize: '20px', textAlign: 'center', display: 'block', justifyContent: 'center', alignItems: 'center', margin: 'auto' }} aria-hidden="true"></i>
                        }
                    }
                    defaultSortFieldId={1}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo/*filterText || filteredItems?.length > 0 ? subHeaderComponentMemo : []*/}
                    customStyles={dataTableStyles}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    sortIcon={sortIcon}
                />
            </div>

            <Dialog header={t("SurveyDetails.TableAddPopupHeader")} visible={displayBasic} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => { setDisplayBasic(false) }} className={'create-modal-popup'}>
                <SurveyEdit data={data} originalData={SurveyDetailsData} type="outer" id={TempIDForDeletUpdate} frmTitle={'Add'} UpdateSurveyUpdatedDetailsData={UpdateSurveyUpdatedDetailsData} setDisplayBasic={setDisplayBasic} UpdateCustomToast={UpdateCustomToast} customerId={customerId} siteName={TempSiteName} surveyId={id} surveyTimeZone={tempSurveyTimeZone} />
                <br />
            </Dialog>
            <Dialog header={`Map - ${MapName}`} visible={displayMap} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => setDisplayMap(false)}>
                <GoogleMap lati={MapLat} lon={MapLon} name={MapName} />
                <br />
            </Dialog>
            <ConfirmDialog />

            <Dialog header={`Video - ${popup_video_title}`} visible={DisplayVideo} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => { setDisplayVideo(false); setpopup_video_url("") }}>
                <video width="100%" height="auto" controls disablePictureInPicture controlsList="nodownload noplaybackrate" autoPlay>
                    <source src={popup_video_url} type="video/mp4" />
                </video>
            </Dialog>
            <Dialog header={`Picture - ${popup_image_title}`} visible={DisplayImage} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => setDisplayImage(false)}>

                <img src={popup_image_url} width="100%" height="auto"></img>
            </Dialog>

            <Dialog header={`Notes`} visible={displayNotesPopup} style={{ width: '50vw', maxHeight: "fit-content" }} onHide={() => { setDisplayNotesPopup(false); setNotesSuccess(false); setSendDispNote(false) }}>
                <SiteNotes currrentemissiondata={currentEmissionData} surveyid={selectedEmissionSurveyId} surveyDetails={SurveyDetailsData} setNotesSuccess={setNotesSuccess} originalData={SurveyDetailsData} setSurveyNotesUpdate={setSurveyNotesUpdate} setSendDispNote={sendDispNote} />
            </Dialog>

        </>

    );
}

export default Table;


