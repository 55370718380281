import React, { useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import StoreDashboardOperations from "../../store/dashboard-operations";
import { AppDispatch, AppState } from "../../store/store";


// Localisation
import { useTranslation } from "react-i18next";

import LoadingScreen from "../common/Loading";

// Leaflet maps
import LeafletMap from '../map/leaflet-map';

// Google maps
import GoogleMap from "../map/google-map";

// Sample Chart
import SampleCharts from "./sample-charts/sample-charts";


import CommonPanel from "../common/common-panel";
import Helpers from "../../utils/helpers";



export const Operations: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const DashboardOperations_value = useSelector((state: AppState) => state.DashboardOperations.sampleText);
    Helpers.ConsoleLog(`value: ${DashboardOperations_value}`);
    const [inputValue, setInputValue] = useState(DashboardOperations_value);

    // to set stored values

    const { t } = useTranslation();
    return (
        <>


            <div className="content__header content__boxed">
                <div className="content__wrap pt-3 pb-4">

                    <h6 className="page-title mb-2">{t("operations_view")}</h6>

                    <p className="lead">

                    </p>

                </div>

            </div>

            <div className="content__boxed">
                <div className="content__wrap">
                    <input type="text" placeholder="type name..." value={inputValue}
                        onChange={(event: any) => {
                            var temp: any = event.currentTarget.value;

                            setInputValue(temp);
                            dispatch(StoreDashboardOperations.actions.addData(temp as any))
                        }}></input>
                    <input type="button" value="Submit" onClick={(e) => {
                        e.preventDefault();
                        setInputValue("");
                        dispatch(StoreDashboardOperations.actions.addData("" as any))
                    }} />
                    {/* <LoadingScreen></LoadingScreen> */}
                    <div>
                        <CommonPanel InnerComponent={<GoogleMap />} Heading="Heading" />
                    </div>

                    <div>
                        <CommonPanel InnerComponent={<LeafletMap />} Heading="Heading" ></CommonPanel>

                    </div>


                    <div>
                        <CommonPanel InnerComponent={<SampleCharts />} Heading="Heading" ></CommonPanel>

                    </div>
                </div>
            </div>

        </>
    );
};
export default Operations;
