/* eslint-disable no-loop-func */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import { AppState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import Dallas from '../Dallas.json';
import { useParams } from "react-router-dom";
import AogiSiteService from '../../../../services/aogi-site/aogi-site.service';
import { Dialog } from "primereact/dialog";
import PopSurveyEmissionData from "../../dashboard/Map/pop-survey-emission-data";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

const SiteEmission = (para: any) => {
    const data = useParams();
    // Create chart instance
    const ResizeStoreToSetHeight = useSelector((state: AppState) => state.AOGICommon.StackedBarGraphHeight);

    const { t } = useTranslation();

    const [displayChart, displayChartUpdate] = useState("none");
    const { customerId, name } = useParams();
    const cid = customerId?.replace("}", "");

    const [siteData, setSiteData] = useState<any>("");
    const [showPopUp, setShowPopUp] = useState(false);
    const [titlePopUp, setTitlePopUp] = useState<string>("");
    const [tempLat, setTempLat] = useState<string>("");
    const [tempLon, setTempLon] = useState<string>("");
    const [surveyData, setSurveyData] = useState([]);
    const [surveyDpValue, setSurveyDpValue] = useState<any>([]);
    useEffect(() => {

        displayChartUpdate("none");
        var GarphData: any = [];
        var temp_obj: any;
        if (siteData.length == 0) {
            AogiSiteService.getAogiSiteSurveyDetails(name as string, cid as string).then((res) => {
                setSiteData(res)
            })
        }
        for (let i = 0; i < siteData?.length; i++) {
            let mainData = JSON.parse(JSON.stringify(siteData[i].data));
            const count = ({ emission }: any, type: any) => emission.reduce((r: any, { size }: any) => r + (type === size), 0)

            mainData.sort(
                (a: any, b: any) => {
                    return count(b, "Large") - count(a, "Large") || count(b, "Medium") - count(a, "Medium") || count(b, "Small") - count(a, "Small")
                }
            );

            mainData?.forEach((val: any) => {
                let Other_count: number = 0;
                let Flare_count: number = 0;
                let enardovalve_count: number = 0;
                let Hatch_count: number = 0;
                let Vent_count: number = 0;
                //let NoEmission_count: number = 0;
                val.emission.forEach((emission_val: any) => {
                    if (emission_val.source === "Other") {
                        if (emission_val.noOfLeaks != null) {
                            Other_count = Other_count + emission_val.noOfLeaks;
                        } else {
                            Other_count++;
                        }

                    } else if (emission_val.source === "Flare") {
                        if (emission_val.noOfLeaks != null) {
                            Flare_count = Flare_count + emission_val.noOfLeaks;
                        } else {
                            Flare_count++;
                        }
                    } else if (emission_val.source === "EnardoValve") {
                        if (emission_val.noOfLeaks != null) {
                            enardovalve_count = enardovalve_count + emission_val.noOfLeaks;
                        } else {
                            enardovalve_count++;
                        }
                    } else if (emission_val.source === "Hatch") {
                        if (emission_val.noOfLeaks != null) {
                            Hatch_count = Hatch_count + emission_val.noOfLeaks;
                        } else {
                            Hatch_count++;
                        }
                    } else if (emission_val.source === "Vent") {
                        if (emission_val.noOfLeaks != null) {
                            Vent_count = Vent_count + emission_val.noOfLeaks;
                        } else {
                            Vent_count++;
                        }
                    }
                    // else if (emission_val.source === "NoEmission") {
                    //     NoEmission_count++;
                    // }
                });

                if (Other_count > 0 || Flare_count > 0 || enardovalve_count > 0 || Hatch_count > 0 || Vent_count > 0 /*|| NoEmission_count > 0*/) {
                    temp_obj = {
                        "sid": siteData[i] && siteData[i]?.id,
                        "Site": siteData[i] && siteData[i].name,
                        "flare": Flare_count,
                        "hatch": Hatch_count,
                        "enardovalve": enardovalve_count,
                        "vent": Vent_count,
                        "other": Other_count,
                        "edate": siteData[i] && siteData[i].date,
                        "map": siteData[i]?.data?.[0]?.gmap,
                        "emissionData": siteData[i]?.data[0],
                        //"NoEmission": NoEmission_count,
                        "total": Flare_count + Hatch_count + enardovalve_count + Vent_count + Other_count
                    }

                    GarphData.push(temp_obj);
                }




            });

        }
        // Add data
        let chart = am4core.create("chartsourcediv", am4charts.XYChart);
        // chart.legend = new am4charts.Legend();
        // chart.legend.position = "bottom";
        // GarphData.sort((a: any, b: any) => parseFloat(a.total) - parseFloat(b.total));
        GarphData.sort((a: any, b: any) => b - a);

        if (GarphData.length > 20) {
            GarphData = GarphData.slice(0, 20);
        }
        // GarphData = GarphData.reverse()
        // setExGraphData(GarphData);
        GarphData = GarphData.sort((a: any, b: any) => {
            return (new Date(a.date) as any) - (new Date(b.date) as any); //sort by decending
        });

        chart.data = GarphData;
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Site";
        categoryAxis.renderer.grid.template.opacity = 0;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        valueAxis.renderer.baseGrid.disabled = true;
        if (chart.logo) {
            chart.logo.disabled = true;
        }
        chart.responsive.enabled = true;
        categoryAxis.renderer.minGridDistance = 22;
        categoryAxis.renderer.fontSize = 9;
        categoryAxis.renderer.labels.template.tooltipText = t("ClickTooltipText");
        categoryAxis.renderer.labels.template.events.on("hit", function (event) {
            setShowPopUp(true)
            let res = chart.data.filter((itm) => itm?.Site == event?.target?.currentText)
            if ((res != undefined || res != null) && res) {
                setTempLat(res[0]?.map?.lat)
                setTempLon(res[0]?.map?.lon)
                setTitlePopUp(res[0].Site)
                setSurveyData(siteData)
                setSurveyDpValue([{ 'value': res[0]?.sid, 'label': res[0]?.Site }])
            }

            // if (res) {
            //     let siteUrl = '/aogi/survey-details/' + res?.[0].sid + '/' + data?.cust + '/' + data?.customerId;
            //     window.open(siteUrl)
            // }
        })

        // Create series
        function createSeries(field: any, name: any, color: any) {
            let strokeColor: any = "#fff";
            let val = "{valueX}";
            if (val !== "" && val !== null) {
                let series = chart.series.push(new am4charts.ColumnSeries());

                series.dataFields.valueX = field;
                series.dataFields.categoryY = "Site";
                series.stacked = true;
                series.name = name;
                series.columns.template.tooltipText = "{categoryY} : {name} {valueX}";

                var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                labelBullet.label.text = "{valueX}";
                labelBullet.label.horizontalCenter = "right";
                labelBullet.label.paddingRight = 10;
                labelBullet.label.fill = am4core.color("#fff");
                series.columns.template.fill = color;
                series.columns.template.stroke = strokeColor;
                series.columns.template.events.on("hit", function (ev: any) {
                    let currentTextVal: string = '';
                    if (ev?.target?.dataItem?.dataContext?.Site) {
                        currentTextVal = ev?.target?.dataItem?.dataContext?.Site
                        setShowPopUp(true)
                        let res = chart.data.filter((itm) => itm?.Site == currentTextVal)
                        if ((res != undefined || res != null) && res) {
                            setTempLat(res[0]?.map?.lat)
                            setTempLon(res[0]?.map?.lon)
                            setTitlePopUp(res[0]?.Site)
                            setSurveyData(siteData)
                            setSurveyDpValue([{ 'value': res[0]?.sid, 'label': res[0]?.Site }])
                        }
                    }
                });
                labelBullet.label.adapter.add("textOutput", function (text: any, target: any) {
                    // Hide labels with 0 value
                    if (target.dataItem && target.dataItem.valueX == 0) {
                        return "";
                    }
                    return text;
                });

            }
        }

        if (siteData?.length > 0) {
            createSeries("flare", "Flare", "#283250");
            createSeries("hatch", "Hatch", "#902c2d");
            createSeries("enardovalve", "EnardoValve", "#d5433d");
            createSeries("vent", "Vent", "#36a6f1");
            // createSeries("NoEmission", "NoEmission", "#008000d1");
            createSeries("other", "Other", "#f05440");
            setTimeout(() => {
                displayChartUpdate("block")
            }, 3000);
        }

    }, [siteData]);
    return <>
        {
            siteData?.length > 0 ? "" : <div style={{ padding: '24px', textAlign: 'center', marginTop: '40px', color: 'rgba(0,0,0,0.87)' }}>{t("NoRecordsToDisplay")}</div>
        }
        <div id="chartsourcediv" style={{ display: displayChart, width: "100%", height: (ResizeStoreToSetHeight) ? "550px" : "350px" }}></div>
        <Dialog header={titlePopUp} visible={showPopUp} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => setShowPopUp(false)} className={'create-modal-popup'}>
            <PopSurveyEmissionData surveyData={surveyData} cid={cid} cusname={name} siteName={titlePopUp} lat={tempLat} lon={tempLon} dropdownValue={surveyDpValue} />

        </Dialog>
    </>
}

export default SiteEmission;