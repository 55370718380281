import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import { AppState } from "../../../../store/store";
import { useSelector } from "react-redux";
import LoadingScreen from "../../../common/Loading";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";


/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

const SurveyEmissionChart = (para: any) => {
    const [exGraphData, setExGraphData] = useState([]);
    const { customerId, name, id } = useParams();
    const cid = customerId?.replace("}", "");

    // Create chart instance

    const { t } = useTranslation();


    const [displayChart, displayChartUpdate] = useState("none");
    const ResizeStoreToSetHeight = useSelector((state: AppState) => state.AOGICommon.StackedBarGraphHeight);


    useEffect(() => {
        displayChartUpdate("none");
        var GarphData: any = [];
        var temp_obj: any;
        if (para?.SurveyDetails_Redux?.length > 0) {
            let mainData = JSON.parse(JSON.stringify(para?.SurveyDetails_Redux[0]?.data));

            const count = ({ emission }: any, type: any) => emission.reduce((r: any, { size }: any) => r + (type === size), 0)

            mainData.sort(
                (a: any, b: any) => {
                    return count(b, "Large") - count(a, "Large") || count(b, "Medium") - count(a, "Medium") || count(b, "Small") - count(a, "Small")
                }
            );

            mainData?.forEach((val: any) => {
                let Other_count: number = 0;
                let Flare_count: number = 0;
                let enardovalve_count: number = 0;
                let Hatch_count: number = 0;
                let Vent_count: number = 0;
                let NoEmission_count: number = 0;

                val.emission.forEach((emission_val: any) => {
                    if (emission_val.source === "Other") {
                        if (emission_val.noOfLeaks != null) {
                            Other_count = Other_count + emission_val.noOfLeaks;
                        } else {
                            Other_count++;
                        }

                    } else if (emission_val.source === "Flare") {
                        if (emission_val.noOfLeaks != null) {
                            Flare_count = Flare_count + emission_val.noOfLeaks;
                        } else {
                            Flare_count++;
                        }
                    } else if (emission_val.source === "EnardoValve") {
                        if (emission_val.noOfLeaks != null) {
                            enardovalve_count = enardovalve_count + emission_val.noOfLeaks;
                        } else {
                            enardovalve_count++;
                        }
                    } else if (emission_val.source === "Hatch") {
                        if (emission_val.noOfLeaks != null) {
                            Hatch_count = Hatch_count + emission_val.noOfLeaks;
                        } else {
                            Hatch_count++;
                        }
                    } else if (emission_val.source === "Vent") {
                        if (emission_val.noOfLeaks != null) {
                            Vent_count = Vent_count + emission_val.noOfLeaks;
                        } else {
                            Vent_count++;
                        }
                    }
                    else if (emission_val.source === "NoEmission") {
                        //NoEmission_count++;
                    }
                });



                if (Other_count > 0 || Flare_count > 0 || enardovalve_count > 0 || Hatch_count > 0 || Vent_count > 0/*|| NoEmission_count > 0*/) {
                    temp_obj = {
                        "Site": val.name,
                        "flare": Flare_count,
                        "hatch": Hatch_count,
                        "vent": Vent_count,
                        "enardovalve": enardovalve_count,
                        "other": Other_count,
                        //"NoEmission": NoEmission_count,
                        "total": Flare_count + Hatch_count + enardovalve_count + Vent_count + Other_count
                    }
                    GarphData.push(temp_obj);
                }
            });

            // let tmpCount = GarphData?.filter((itms: any) => {
            //     if (itms?.total > 1) {
            //         return true
            //     }
            // })
            // if (tmpCount < 2) {
            //     para?.SurveyDetails_Redux[0]?.data?.forEach((val: any, id: any) => {
            //         let Other1_count: number = 0;
            //         let Flare1_count: number = 0;
            //         let enardovalve1_count: number = 0;
            //         let Hatch1_count: number = 0;
            //         let NoEmission1_count: number = 0;
            //         if (val?.emissionCount == 1) {
            //             val.emission.filter((itm: any) => {
            //                 if (itm?.source == "Other") {
            //                     Other1_count++
            //                 } else if (itm?.source == "Flare") {
            //                     Flare1_count++
            //                 } else if (itm?.source == "EnardoValve") {
            //                     enardovalve1_count++
            //                 } else if (itm?.source == "Hatch") {
            //                     Hatch1_count++
            //                 } else if (itm?.source == "NoEmission") {
            //                     //NoEmission1_count++
            //                 }
            //             })
            //             GarphData?.push({
            //                 "Site": val.name,
            //                 "flare": Flare1_count,
            //                 "hatch": Hatch1_count,
            //                 "enardovalve": enardovalve1_count,
            //                 "other": Other1_count,
            //                 //"NoEmission": NoEmission1_count,
            //                 "total": Flare1_count + Hatch1_count + enardovalve1_count + Other1_count /*+ NoEmission1_count*/,
            //                 "size": val.emission[0].size
            //             })
            //         }
            //     });
            //     let fData = GarphData.sort((a: any, b: any) => (b['size'] || "").toString().localeCompare((a['size'] || "").toString()));
            //     if (fData?.length > 0) {
            //         GarphData.push(fData);
            //     }
            // }
        }


        // Add data
        let chart = am4core.create("chartdiv", am4charts.XYChart);
        // chart.legend = new am4charts.Legend();
        // chart.legend.position = "bottom";
        // GarphData.sort((a: any, b: any) => parseFloat(a.total) - parseFloat(b.total));
        GarphData.sort((a: any, b: any) => b - a);

        if (GarphData.length > 20) {
            GarphData = GarphData.slice(0, 20);
        }
        GarphData = GarphData.reverse()
        setExGraphData(GarphData)

        chart.data = GarphData;

        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Site";
        categoryAxis.renderer.grid.template.opacity = 0;
        // if (ResizeStoreToSetHeight) {
        //     categoryAxis.renderer.minGridDistance = 0;
        // } else {
        //     categoryAxis.renderer.minGridDistance = 22;
        // }
        categoryAxis.renderer.minGridDistance = 22;
        categoryAxis.renderer.fontSize = 9;
        categoryAxis.renderer.labels.template.tooltipText = t("ClickTooltipText");
        categoryAxis.renderer.labels.template.events.on("hit", function (event: any) {


            let tempGLatLon = para?.SurveyDetails_Redux[0]?.data?.filter((itm: any) => itm.name == event?.target?.currentText)

            if (tempGLatLon[0]) {
                let siteUrl = '/aogi/site-details/' + event?.target?.currentText + '/' + tempGLatLon[0]?.gmap?.lat + '/' + tempGLatLon[0]?.gmap?.lon + '/' + name + '/' + cid;
                window.open(siteUrl)
            }

        })

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");

        valueAxis.renderer.baseGrid.disabled = true;
        if (chart.logo) {
            chart.logo.disabled = true;
        }
        chart.responsive.enabled = true;





        // Create axes





        // valueAxis.min = 0;
        // valueAxis.renderer.grid.template.opacity = 0;
        // valueAxis.renderer.ticks.template.strokeOpacity = 0.5;

        // valueAxis.renderer.ticks.template.length = 10;
        // valueAxis.renderer.line.strokeOpacity = 0.5;

        // valueAxis.renderer.minGridDistance = 40;
        // Create series
        function createSeries(field: any, name: any, color: any) {
            let strokeColor: any = "#fff";
            let val = "{valueX}";
            if (val !== "" && val !== null) {
                let series = chart.series.push(new am4charts.ColumnSeries());

                series.dataFields.valueX = field;
                series.dataFields.categoryY = "Site";
                series.stacked = true;
                series.name = name;
                series.columns.template.tooltipText = "{categoryY} : {name} {valueX}";
                var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                labelBullet.label.text = "{valueX}";
                labelBullet.label.horizontalCenter = "right";
                labelBullet.label.paddingRight = 10;
                labelBullet.label.fill = am4core.color("#fff");
                series.columns.template.fill = color;
                series.columns.template.stroke = strokeColor;

                /*To update tooltip label color*/
                am4core.useTheme(function customTheme(object) {
                    // Identify the instances
                    if (object instanceof am4core.Tooltip && object.label) {
                        object.autoTextColor = false;
                        object.label.fill = am4core.color("#FFFFFF");
                    }
                });
                /*To update tooltip label color*/

                labelBullet.label.adapter.add("textOutput", function (text: any, target: any) {
                    // Hide labels with 0 value
                    if (target.dataItem && target.dataItem.valueX == 0) {
                        return "";
                    }
                    return text;
                });

            }
        }
        createSeries("flare", "Flare", "#283250");
        createSeries("hatch", "Hatch", "#902c2d");
        createSeries("enardovalve", "EnardoValve", "#d5433d");
        createSeries("vent", "Vent", "#36a6f1");
        //createSeries("NoEmission", "NoEmission", "#008000d1"); //#85e029
        createSeries("other", "Other", "#f05440");

        return () => {
            setTimeout(() => {
                displayChartUpdate("block")
            }, 4000)
            chart.dispose();
        };


    }, [para]);

    return <>
        {
            (exGraphData?.length > 0) ? <div id="chartdiv" style={{ display: displayChart, width: "100%", height: (ResizeStoreToSetHeight) ? "550px" : "300px" }}></div> : <div style={{ padding: '24px', textAlign: 'center', marginTop: '40px', color: 'rgba(0,0,0,0.87)' }}>{t("NoRecordsToDisplay")}</div>
        }
    </>
}

export default SurveyEmissionChart;