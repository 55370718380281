import GoogleMap from "../Map/google-map";
import { useState } from 'react';
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/store";

const EmissionOverView = (props: any) => {
    const ResizeStoreToSetHeight = useSelector((state: AppState) => state.AOGICommon.setMapHeightPanel);

    return (
        <>
            <GoogleMap lati={-31.563910} lon={147.154312} data={props} height={ResizeStoreToSetHeight} />
        </>
    );
}

export default EmissionOverView;