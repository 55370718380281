import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Todo {
    message: string;
}

const AOGISurveyDetails = createSlice({
    name: "AOGISurveyDetails",
    initialState: {
        SurveyDetailsData: ""
    },
    reducers: {
        AddSurveyDetails: (state, action: any) => {
            state.SurveyDetailsData = action.payload;
            return state;
        }
    }
});

export default AOGISurveyDetails;
export const { AddSurveyDetails } = AOGISurveyDetails.actions;