



import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


import { useEffect, useState } from "react";
import "../css/pagination.css";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/store";
import { useTranslation } from "react-i18next";


// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end
const EmissionBySizeChart = (para: any) => {
    const { t } = useTranslation();

    const ResizeStoreToSetHeight = useSelector((state: AppState) => state.AOGICommon.setEmissionBySizeHeightPanel);
    const [displayChart, displayChartUpdate] = useState("none");
    const [exGraphData, setexGraphData] = useState(0);

    useEffect(() => {
        displayChartUpdate("none");
        if (para.SurveyDetails_Redux.length > 0) {


            let large_count: number = 0;
            let medium_count: number = 0;
            let small_count: number = 0;
            let total_emission_count: number = 0;
            para?.SurveyDetails_Redux[0]?.data?.forEach((val: any) => {
                let check_complete = 0;
                val.emission.forEach((emission_val: any) => {

                    if (emission_val.size === 'Medium') {


                        if (emission_val.noOfLeaks != null) {

                            medium_count = medium_count + emission_val.noOfLeaks;
                            total_emission_count = total_emission_count + emission_val.noOfLeaks;

                        } else {
                            medium_count++;
                            total_emission_count++;

                        }
                    } else if (emission_val.size === 'Small') {


                        if (emission_val.noOfLeaks != null) {

                            small_count = small_count + emission_val.noOfLeaks;
                            total_emission_count = total_emission_count + emission_val.noOfLeaks;

                        } else {
                            small_count++;
                            total_emission_count++;

                        }

                    } else if (emission_val.size === 'Large') {


                        if (emission_val.noOfLeaks != null) {

                            large_count = large_count + emission_val.noOfLeaks;
                            total_emission_count = total_emission_count + emission_val.noOfLeaks;

                        } else {
                            large_count++;
                            total_emission_count++;

                        }


                    }

                    check_complete++;
                });


                // Calculate Percentage for Emission by Size


            });


            let large_emission_per: number = (large_count / total_emission_count) * 100;
            let small_emission_per: number = (small_count / total_emission_count) * 100;
            let medium_emission_per: number = (medium_count / total_emission_count) * 100;
            setexGraphData(total_emission_count);

            var chart = am4core.create("guage_chart", am4charts.RadarChart);
            chart.responsive.enabled = true;
            if (chart.logo) {
                chart.logo.disabled = true;
            }
            // Add data
            chart.data = [
                {
                    "category": "Small",
                    "value": small_emission_per,
                    "full": 100,
                    // columnSettings: {
                    //     fill: am5.color("#f44336")
                    // }
                }, {
                    "category": "Medium",
                    "value": medium_emission_per,
                    "full": 100,
                    // columnSettings: {
                    //     fill: am5.color("#f44336")
                    // }
                },
                {
                    "category": "Large",
                    "value": large_emission_per,
                    "full": 100,
                    // columnSettings: {
                    //     fill: am4core.color("#f44336")
                    // }
                }
            ];
            // Make chart not full circle
            chart.startAngle = -90;
            chart.endAngle = 180;
            chart.innerRadius = am4core.percent(20);

            // Set number format
            chart.numberFormatter.numberFormat = "#.#'%'";

            // Create axes
            var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis<any>());
            categoryAxis.dataFields.category = "category";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.grid.template.strokeOpacity = 0;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.fontWeight = 500;
            categoryAxis.renderer.labels.template.adapter.add("fill", function (fill: any, target: any) {
                return am4core.color("#000");
            });
            categoryAxis.renderer.minGridDistance = 10;

            var valueAxis = chart.xAxes.push(new am4charts.ValueAxis<any>());
            valueAxis.renderer.grid.template.strokeOpacity = 0;
            valueAxis.min = 0;
            valueAxis.max = 100;
            valueAxis.strictMinMax = true;

            // Create series
            var series1 = chart.series.push(new am4charts.RadarColumnSeries());
            series1.dataFields.valueX = "full";
            series1.dataFields.categoryY = "category";
            series1.clustered = false;
            series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
            series1.columns.template.fillOpacity = 0.08;
            // series1.columns.template.cornerRadiusTopLeft = 20;
            series1.columns.template.strokeWidth = 0;
            series1.columns.template.radarColumn.cornerRadius = 20;

            var series2 = chart.series.push(new am4charts.RadarColumnSeries());
            series2.dataFields.valueX = "value";
            series2.dataFields.categoryY = "category";
            series2.clustered = false;
            series2.columns.template.strokeWidth = 0;
            series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
            series2.columns.template.radarColumn.cornerRadius = 20;

            series2.columns.template.adapter.add("fill", function (fill: any, target: any) {
                if (target.dataItem.categoryY === "Large")
                    return am4core.color("#f44336");
                else if (target.dataItem.categoryY === "Medium")
                    return am4core.color("#fc7a0f");
                else if (target.dataItem.categoryY === "Small")
                    return am4core.color("#ffb300");
                else
                    return chart.colors.getIndex(target.dataItem.index);
            });

            // Add cursor
            chart.cursor = new am4charts.RadarCursor()

            return () => {
                setTimeout(() => {
                    displayChartUpdate("block")
                }, 3500)
                chart.dispose();
            };
        }
    }, [para]);





    let text = t("NoRecordsToDisplay");
    return <>
        {(exGraphData > 0) ? <div id="chartdiv_solid_gauge" className={ResizeStoreToSetHeight ? '' : 'guage_chart'} style={{ height: ResizeStoreToSetHeight ? '350px' : '', display: displayChart }}></div> : <div style={{ padding: '24px', textAlign: 'center', marginTop: '40px', color: 'rgba(0,0,0,0.87)' }}>{text}</div>}
    </>
}

export default EmissionBySizeChart;