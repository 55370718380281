import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DataTable, { TableStyles } from 'react-data-table-component';
import AogiSiteService from '../../../../services/aogi-site/aogi-site.service';
import { useTranslation } from "react-i18next";
import moment from "moment";
import { BsSortDown } from 'react-icons/bs';
import InfoIcon from "../../../common/InfoIcon";

const SiteDetailsTable = () => {
    const sortIcon = <BsSortDown />;
    const { customerId, name } = useParams();
    const cid = customerId?.replace("}", "");
    const [siteData, setSiteData] = useState<any>("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState('');
    const { t } = useTranslation();
    interface ColumnDef {
        header?: string,
        body?: any,
        name?: any,
        cell?: any,
        selector?: any,
        sortable?: boolean,
        style?: any,
        width?: any
    }
    const dataTableStyles: TableStyles = {

        headCells: {
            style: {
                borderTop: "1px solid rgba(0,0,0,0.07)",
                borderLeft: "1px solid rgba(0,0,0,0.07)",
                '&:last-of-type': {
                    borderRight: "1px solid rgba(0,0,0,0.07)",
                },
                justifyContent: "space-between",
                fontWeight: "bold",
                paddingRight: "5px",
                paddingBottom: "0px !important",

            }
        },
        cells: {
            style: {
                borderLeft: "1px solid rgba(0,0,0,0.07)",
                '&:last-of-type': {
                    borderRight: "1px solid rgba(0,0,0,0.07)",
                }
            }
        }
    }

    useEffect(() => {
        AogiSiteService.getAogiSiteSurveyDetails(name as string, cid as string).then((res) => {
            setSiteData(res)
        })
    }, [])

    const columns: ColumnDef[] = [

        {
            name: t("SiteDetails.SurveyDetailsTableSurveyName"),
            selector: (row: any) => row.name,
            cell: (row: any) => {

                return <div><a href="javascript:void(0)" title="click here to view survey emission details"><Link
                    to={`/aogi/survey-details/${row.id}/${name}/${customerId}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {row.name}
                </Link></a></div>
            },
            sortable: true,
            width: '19%'
        },


        {
            name: t("SiteDetails.SurveyDetailsTableDate"),
            selector: (row: any) => (row.date),
            cell: (row: any) => {
                return <>
                    {moment(row.date).format("DD-MMM-YYYY")}
                </>
            },
            sortable: true,
            width: '11%'
        },
        {
            name: t("SiteDetails.SurveyDetailsTableStatus"),
            selector: (row: any) => {
                if (row.status == "OPEN") {
                    return (
                        <span className="badge bg-info" style={{ textAlign: 'left' }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OPEN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span >
                        // <div className="" style={{ height: '20px', marginBottom: '5px' }}>
                        //   <div style={{ width: '100%', lineHeight: '1.6em', fontSize: '12px' }} className="badge bg-info">OPEN</div>&nbsp; &nbsp;
                        // </div>
                    )
                } else if (row.status == "COMPLETED") {
                    return (
                        <span className="badge bg-success" style={{ textAlign: 'left' }}>&nbsp;COMPLETED&nbsp;</span>
                    )
                } else if (row.status == "INPROGRESS") {
                    return (
                        <span className="badge bg-warning" style={{ textAlign: 'center' }}>INPROGRESS</span>
                    )
                }
                else if (row.status == "SCHEDULED") {
                    return (
                        <span className="badge bg-primary" style={{ textAlign: 'center' }}>&nbsp;SCHEDULED&nbsp;</span>
                    )
                }
                else if (row.status == "CANCELLED") {
                    return (
                        <span className="badge bg-danger" style={{ textAlign: 'center' }}>&nbsp;CANCELLED&nbsp;</span>
                    )
                }
            },
            //sortable: true,
            width: '10%',
        },
        {
            name: t("SiteDetails.SurveyDetailsTableEmissionSource"),
            cell: (row: any) => {
                let emiCount: any = updateEmissionCnt("source", row?.data)
                let styleDiv = false
                const ua = navigator.userAgent;
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                    styleDiv = true;
                } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                    styleDiv = true;
                } else {
                    styleDiv = false;
                }

                return (
                    styleDiv == true ? <div style={{ marginTop: '5px ' }}>
                        {(emiCount?.flare_count == 0 || emiCount?.flare_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#283250', marginBottom: '3px' }}>{emiCount?.flare_count} Flare</span>}
                        {/* {(emiCount?.noemission_count == 0 || emiCount?.noemission_count == undefined) ? "" : <span className="d-block badge bg-success" style={{ marginRight: '5px' }}>{emiCount?.noemission_count} No Emission</span>} */}
                        {(emiCount?.leak_count == 0 || emiCount?.leak_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#283250', marginBottom: '3px' }}>{emiCount?.leak_count} Leak</span>}
                        {(emiCount?.hatch_count == 0 || emiCount?.hatch_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#902c2d', marginBottom: '3px' }}>{emiCount?.hatch_count} Hatch</span>}
                        {(emiCount?.enardovalve_count == 0 || emiCount?.enardovalve_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#d5433d', marginBottom: '3px' }}>{emiCount?.enardovalve_count} Enardovalve</span>}
                        {(emiCount?.vent_count == 0 || emiCount?.vent_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#36a6f1', marginBottom: '3px' }}>{emiCount?.vent_count} Vent</span>}
                        {(emiCount?.other_count == 0 || emiCount?.other_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#f05440', marginBottom: '3px' }}>{emiCount?.other_count} Other</span>}

                    </div> : <>
                        {(emiCount?.flare_count == 0 || emiCount?.flare_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#283250' }}>{emiCount?.flare_count} Flare</span>}
                        {/* {(emiCount?.noemission_count == 0 || emiCount?.noemission_count == undefined) ? "" : <span className="d-block badge bg-success" style={{ marginRight: '5px' }}>{emiCount?.noemission_count} No Emission</span>} */}
                        {(emiCount?.leak_count == 0 || emiCount?.leak_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#283250' }}>{emiCount?.leak_count} Leak</span>}
                        {(emiCount?.hatch_count == 0 || emiCount?.hatch_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#902c2d' }}>{emiCount?.hatch_count} Hatch</span>}
                        {(emiCount?.enardovalve_count == 0 || emiCount?.enardovalve_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#d5433d' }}>{emiCount?.enardovalve_count} Enardovalve</span>}
                        {(emiCount?.vent_count == 0 || emiCount?.vent_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#36a6f1' }}>{emiCount?.vent_count} Vent</span>}
                        {(emiCount?.other_count == 0 || emiCount?.other_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#f05440' }}>{emiCount?.other_count} Other</span>}

                    </>

                )

            },
            width: "28%"
            //sortable: true,
        },

        {
            name: t("SiteDetails.SurveyDetailsTableEmissionSize"),
            cell: (row: any) => {
                let emiCount: any = updateEmissionCnt("size", row?.data)
                let styleDiv = false
                const ua = navigator.userAgent;
                if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                    styleDiv = true; // alert("tablet");
                } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                    styleDiv = true; //alert("mobile");
                } else {
                    styleDiv = false; //alert("desktop");
                }
                return (
                    styleDiv == true ? <div style={{ marginTop: '5px ' }}>
                        {(emiCount?.small_count == 0 || emiCount?.small_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '5px', backgroundColor: '#ffb300', marginBottom: '3px' }}>{emiCount?.small_count} Small</span>}
                        {(emiCount?.medium_count == 0 || emiCount?.medium_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '5px', backgroundColor: '#fc7a0f', marginBottom: '3px' }}>{emiCount?.medium_count} Medium</span>}
                        {(emiCount?.large_count == 0 || emiCount?.large_count == undefined) ? "" : <span className="d-block badge" style={{ backgroundColor: '#f44336', marginBottom: '3px' }}>{emiCount?.large_count} Large</span>}
                    </div> :
                        <>
                            {(emiCount?.small_count == 0 || emiCount?.small_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '5px', backgroundColor: '#ffb300' }}>{emiCount?.small_count} Small</span>}
                            {(emiCount?.medium_count == 0 || emiCount?.medium_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '5px', backgroundColor: '#fc7a0f' }}>{emiCount?.medium_count} Medium</span>}
                            {(emiCount?.large_count == 0 || emiCount?.large_count == undefined) ? "" : <span className="d-block badge" style={{ backgroundColor: '#f44336' }}>{emiCount?.large_count} Large</span>}
                        </>
                )
            },
            width: "22%",
            //sortable: true,
        },
        {
            name: t("SiteDetails.SurveyDetailsTableCount"),
            selector: (row: any) => {
                let emiPer: any = updateEmissionCnt("count", row?.data);

                if (emiPer != undefined) {
                    return emiPer
                } else {
                    return ""
                }

            },
            sortable: true,
            width: "10%"
        },
    ];

    const liveData = siteData?.length > 0 ? siteData : [];

    const filteredItems = liveData?.filter((item: any) => {

        let regex = /"(\\.|[^"\\])*"/g;;
        var exactMatchText: any = filterText != null ? filterText.match(regex)?.[0].replace(/"/g, "") : null;

        if (exactMatchText) {
            return (item?.name?.toString().toLowerCase() == exactMatchText?.toLowerCase() ||
                moment(item?.date).format('DD-MMM-YYYY').toString().toLowerCase() == exactMatchText?.toLowerCase() ||
                item?.status?.toString().toLowerCase() == exactMatchText?.toLowerCase())
        } else {
            return (item?.name?.toString().toLowerCase().includes(filterText?.toLowerCase()) ||
                moment(item?.date).format('DD-MMM-YYYY').toString().toLowerCase().includes(filterText?.toLowerCase()) ||
                item?.status?.toString().toLowerCase().includes(filterText?.toLowerCase()))
        }

    })

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <div className="col-md-12" style={{ marginRight: '20px', marginBottom: "10px" }}>
                <div className="d-flex flex-row  justify-content-start align-items-center">
                    <label className="custom_common_label">{t("SearchLabel")}: &nbsp;&nbsp;&nbsp; </label>
                    <input type="text" name="sitedetailssearch" id="sitedetailssearch" className="form-control input-sm" onChange={(e: any) => setFilterText(e?.target?.value)} value={filterText} />
                    <div className="searchClear">
                        <button type="button" className="btn btn-primary" onClick={(e) => handleClear()}> X</button >
                        <InfoIcon />
                    </div>
                </div>
            </div>
        );
    }, [filterText, resetPaginationToggle]);


    const updateEmissionCnt = (emiType: any, emiArr: any) => {
        if (emiType == "size" && emiArr.length != 0) {
            let temp_small_count = 0;
            let temp_medium_count = 0;
            let temp_large_count = 0;
            emiArr?.length > 0 && emiArr.forEach((val_data: any) => {
                val_data?.emission?.length > 0 && val_data.emission.forEach((val_emission: any) => {
                    if (val_emission.size == 'Medium') {
                        if (val_emission.noOfLeaks != null) {
                            temp_medium_count = temp_medium_count + val_emission.noOfLeaks;
                        } else {
                            temp_medium_count++;
                        }

                    }
                    if (val_emission.size == 'Large') {
                        if (val_emission.noOfLeaks != null) {
                            temp_large_count = temp_large_count + val_emission.noOfLeaks;
                        } else {
                            temp_large_count++;
                        }

                    }
                    if (val_emission.size == 'Small') {
                        if (val_emission.noOfLeaks != null) {
                            temp_small_count = temp_small_count + val_emission.noOfLeaks;
                        } else {
                            temp_small_count++;
                        }

                    }
                })
            })
            const finObj = {
                "small_count": temp_small_count,
                "medium_count": temp_medium_count,
                "large_count": temp_large_count
            }
            return finObj;
        } else if (emiType == "source" && emiArr.length != 0) {
            let temp_flare_count = 0;
            let temp_noemission_count = 0;
            let temp_leak_count = 0;
            let temp_hatch_count = 0;
            let temp_enardovalve_count = 0;
            let temp_vent_count = 0;
            let temp_other_count = 0;
            emiArr.forEach((val_data: any) => {
                val_data.emission.forEach((val_emission: any) => {
                    if (val_emission.source == 'Flare') {
                        if (val_emission.noOfLeaks != null) {
                            temp_flare_count = temp_flare_count + val_emission.noOfLeaks;
                        } else {
                            temp_flare_count++;
                        }
                    }
                    if (val_emission.source == 'Other') {
                        if (val_emission.noOfLeaks != null) {
                            temp_other_count = temp_other_count + val_emission.noOfLeaks;
                        } else {
                            temp_other_count++;
                        }
                    }
                    if (val_emission.source == 'Hatch') {
                        if (val_emission.noOfLeaks != null) {
                            temp_hatch_count = temp_hatch_count + val_emission.noOfLeaks;
                        } else {
                            temp_hatch_count++;
                        }
                    }
                    if (val_emission.source == 'Leak') {
                        if (val_emission.noOfLeaks != null) {
                            temp_leak_count = temp_leak_count + val_emission.noOfLeaks;
                        } else {
                            temp_leak_count++;
                        }
                    }
                    if (val_emission.source == 'Vent') {
                        if (val_emission.noOfLeaks != null) {
                            temp_vent_count = temp_vent_count + val_emission.noOfLeaks;
                        } else {
                            temp_vent_count++;
                        }
                    }
                    if (val_emission.source == 'EnardoValve') {
                        if (val_emission.noOfLeaks != null) {
                            temp_enardovalve_count = temp_enardovalve_count + val_emission.noOfLeaks;
                        } else {
                            temp_enardovalve_count++;
                        }
                    }
                    if (val_emission.source == 'NoEmission') {
                        if (val_emission.noOfLeaks != null) {
                            temp_noemission_count = temp_noemission_count + val_emission.noOfLeaks;
                        } else {
                            temp_noemission_count++;
                        }
                    }
                })
            })
            const finObj = {
                "flare_count": temp_flare_count,
                "noemission_count": temp_noemission_count,
                "leak_count": temp_leak_count,
                "hatch_count": temp_hatch_count,
                "enardovalve_count": temp_enardovalve_count,
                "vent_count": temp_vent_count,
                "other_count": temp_other_count,
            }
            return finObj;
        } else if (emiType == "count" && emiArr.length != 0) {
            let siteCnt = emiArr?.length;
            let EmiCnt = 0;
            let test_count = 0;
            emiArr.forEach((val_data: any) => {
                EmiCnt = 0;
                let count_flag: any = false;

                val_data.emission.forEach((val_emission: any) => {
                    count_flag = false;


                    if (val_emission?.size != "" || val_emission?.size != null || val_emission?.size != 'null' || val_emission?.size != undefined || val_emission?.size != 'undefined') {
                        count_flag = true;
                        if (val_emission?.noOfLeaks != null) {
                            EmiCnt = EmiCnt + val_emission?.noOfLeaks;
                        } else {
                            EmiCnt = EmiCnt + 1;
                        }

                    }
                });
                if (EmiCnt > 0) {
                    test_count++;
                }
            });
            if (EmiCnt == 0) {
                return "";
            } else {
                return EmiCnt;
            }

        }
    }

    return (
        <>
            <div className="table-responsive">
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    defaultSortFieldId={2}
                    defaultSortAsc={false}
                    subHeader
                    subHeaderComponent={filterText || filteredItems?.length > 0 ? subHeaderComponentMemo : []}
                    customStyles={dataTableStyles}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    sortIcon={sortIcon}
                />
            </div>
        </>
    )
}

export default SiteDetailsTable;