import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import DataTable, { TableStyles } from 'react-data-table-component';
import { BsSortDown } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import GoogleMap from '../../map/google-map';
import CreateSite from './create-site';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import Customer from '../../../interfaces/customer/customer';
import AOGISite from '../../../interfaces/aogi-site/aogi-site';
import Helpers from '../../../utils/helpers';
import AogiSiteService from '../../../services/aogi-site/aogi-site.service';
import Loading from '../../common/Loading';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { AppendSiteList, GetSiteListByID, RemoveSiteList } from '../../../services/AOGI/site-upload';
import { SitesList } from "../../../store/AOGI/site-upload";
import usePermissionsContext from '../../../hooks/usePermissionContext';
import { Toast } from 'primereact/toast';
import { useTranslation } from "react-i18next";
import InfoIcon from '../../common/InfoIcon';
import { useNavigate } from "react-router-dom";

interface ColumnDef {
    name: string,
    cell?: any,
    selector?: any,
    sortable?: boolean,
    style?: any,
    width?: any,
    omit?: boolean
}

const sortIcon = <BsSortDown />;

const dataTableStyles: TableStyles = {

    headCells: {
        style: {
            borderTop: "1px solid rgba(0,0,0,0.07)",
            borderLeft: "1px solid rgba(0,0,0,0.07)",
            '&:last-of-type': {
                borderRight: "1px solid rgba(0,0,0,0.07)",
            },
            justifyContent: "space-between",
            fontWeight: "bold",
            paddingRight: "5px",

        }
    },
    cells: {
        style: {
            borderLeft: "1px solid rgba(0,0,0,0.07)",
            '&:last-of-type': {
                borderRight: "1px solid rgba(0,0,0,0.07)",
            }
        }
    }
}

interface SiteUploadTableInterface {
    selectedCustomer: Customer,
    updateSelectedCustomer: any,
    downloadSiteUploadData: number
}

const AOGISiteTable = (siteUploadTableProps: SiteUploadTableInterface) => {
    let { canAccessEditSiteUpload } = usePermissionsContext();
    const dispatch: AppDispatch = useDispatch();
    const { t } = useTranslation();
    var navigate = useNavigate();
    const [aogiSiteRedux, setAogiSitesRedux] = useState<AOGISite[]>([]);
    const [createSiteModalShow, setCreateSiteModalShow] = React.useState(false);
    const [filteredValues, setFilteredValues] = useState<AOGISite[]>([]);
    const [searchCustomer, setSearchCustomer] = useState<string>('');
    const [aogiSites, setAogiSites] = useState<AOGISite[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingText, loadingTextUpdate] = useState("");
    const toast = useRef<any>(null);
    const [visibleGoogleMap, setVisibleGoogleMap] = useState<boolean>(false);
    const [currentCustomerLatitude, setcurrentCustomerLatitude] = useState<number | null>();
    const [currentCustomerLongitude, setcurrentCustomerLongitude] = useState<number | null>();
    const [currentCustomerMapPinName, setcurrentCustomerMapPinName] = useState("");
    const [isexportInProgress, setIsexportInProgress] = useState(false);


    const columns: ColumnDef[] = [
        {
            name: t("SiteUpload.TableFID"),
            sortable: true,
            selector: (row: AOGISite) => row.serialNo,
            width: "7%"
        },
        {
            name: t("SiteUpload.TableName"),
            sortable: true,
            selector: (row: any) => {
                return <>
                    <a href="javascript:void(0)" title="click here to view site details" onClick={() => {
                        navigate(`/aogi/site-details/${row.thingNo}/${row.location?.split(",")[0]}/${row.location?.split(",")[1]}/${siteUploadTableProps.selectedCustomer.name}/${siteUploadTableProps.selectedCustomer?.customerID}}`)
                    }}>{row.thingNo}</a>
                </>
            },

            width: "22%"
        },
        {
            name: t("SiteUpload.TableType"),
            sortable: true,
            selector: (row: AOGISite) => row.type,
            width: "20%"
        },
        {
            name: t("SiteUpload.TableCounty"),
            sortable: true,
            selector: (row: AOGISite) => row.county,
        },
        {
            name: t("SiteUpload.TableState"),
            sortable: true,
            selector: (row: AOGISite) => row.state,
        },
        {
            name: t("SiteUpload.TableLatitude"),
            sortable: true,
            selector: (row: AOGISite) => row.location?.split(",")[0],
        },
        {
            name: t("SiteUpload.TableLongitude"),
            sortable: true,
            selector: (row: AOGISite) => row.location?.split(",")[1],
        },
        {
            name: t("SiteUpload.TableMap"),
            sortable: true,
            style: {
                justifyContent: 'center',
                cursor: 'pointer'
            },
            cell: (row: AOGISite, index: number, column: any, id: number) => {
                return <FaMapMarkerAlt style={{ color: "red", fontSize: "25px" }} onClick={() => { openGoogleMap(row) }} />
            },
            width: "75px"
        },
        {
            name: '',
            cell: (row: any, index: number, column: any, id: number) => {
                return (
                    <>
                        <i className="fa fa-2x fa-trash text-danger" style={{ fontSize: '20px' }} onClick={() => { confirmDeleteSiteUpload(row) }} />
                    </>

                )

            },
            style: {
                justifyContent: 'center',
                cursor: 'pointer'
            },
            width: "50px",
            omit: canAccessEditSiteUpload ? false : true

        }

    ];


    useLayoutEffect(() => {
        setFilteredValues(aogiSites);
    }, []);

    useEffect(() => {
        filterValues();
    }, [searchCustomer]);

    useEffect(() => {
        fetchSites();
    }, [siteUploadTableProps.selectedCustomer]);

    useEffect(() => {
        filterValues();
    }, [aogiSites])

    useEffect(() => {
        fetchSites();
    }, [createSiteModalShow])

    useEffect(() => {
        if (siteUploadTableProps.downloadSiteUploadData > 0 && !isexportInProgress) {
            setIsexportInProgress(true);
            if (filteredValues?.length > 0) {
                Helpers.exportToCSV(filteredValues, "sites-uploaded", 'Sites Uploaded', { serialNo: 'FID', thingNo: 'Name', type: 'Type', county: 'County', state: 'State', location: 'Location' }, ["serialNo", "thingNo", "type", "county", "state", "location"]);
            }
            else
                toast?.current?.show({ severity: 'info', summary: 'No Data!', detail: 'There is no data to download!!', life: 3000 });

            setIsexportInProgress(false);
        }
    }, [siteUploadTableProps.downloadSiteUploadData]);


    const confirmDeleteSiteUpload = (site: AOGISite) => {
        confirmDialog({
            message: 'Are you sure you want to delete ' + site.thingNo + ' ?',
            header: 'Delete Site',
            icon: 'pi pi-exclamation-triangle',
            // accept: () => removeSite(site)
            accept: async () => {
                await removeSite(site);
                toast?.current?.show({ severity: 'success', summary: 'Success Message', detail: 'Deleted Successfully', life: 3000 });
            }
        });
    }

    const removeSite = async (site: AOGISite) => {

        if (site._id) {
            await AogiSiteService.removeAogiSite(site._id);

            var updatedFilterValues = aogiSites.filter((aogiSite: AOGISite) => {
                return (site._id != aogiSite._id)
            });

            RemoveSiteList(site._id, aogiSiteRedux).then((res: any) => {
                dispatch(SitesList(res));
                setAogiSitesRedux(res);
            })
            setAogiSites(updatedFilterValues);
            setFilteredValues(updatedFilterValues);
        }
    }

    const toggleGoogleMap = () => {
        setVisibleGoogleMap(!visibleGoogleMap);
    }

    const openGoogleMap = (aogiSite: any) => {
        setcurrentCustomerMapPinName(aogiSite.thingNo);
        setcurrentCustomerLatitude(aogiSite.location?.split(',')[0]);
        setcurrentCustomerLongitude(aogiSite.location?.split(',')[1]);
        toggleGoogleMap();
    }

    const downloadSampleSiteUploadFile = () => {
        // throw new Error("uncomment this line to mock failure of API");
        return axios.get(
            "/sample-files/site-bulk-upload-data.xlsx",
            {
                responseType: "blob",
            }
        );
    }

    const getDownloadFile = async () => {

        const { data } = await downloadSampleSiteUploadFile();
        const url = URL.createObjectURL(new Blob([data]));
        const link: any = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `AOGI Site Upload Sample.xlsx`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const fetchSites = () => {

        if (siteUploadTableProps.selectedCustomer) {

            setIsLoading(true);
            setAogiSitesRedux([]);

            const SearchValue = (aogiSiteReduxVal: any) => {
                return aogiSiteReduxVal.filter(function (obj: any) {
                    if (obj.customerID === siteUploadTableProps.selectedCustomer?.customerID) {
                        return true;
                    }
                    return false;
                });
            }

            if (aogiSiteRedux.length == 0 || SearchValue(aogiSiteRedux).length === 0) {
                if (siteUploadTableProps.selectedCustomer?.name != 'All') {
                    AogiSiteService.getAogiSite(siteUploadTableProps.selectedCustomer?.customerID).then((response: any) => {

                        AppendSiteList(aogiSiteRedux, response).then((merged_res: any) => {
                            setAogiSitesRedux(merged_res);
                            dispatch(SitesList(merged_res));

                            GetSiteListByID(siteUploadTableProps.selectedCustomer?.customerID, response).then((res: any) => {

                                setAogiSites(res);
                            })

                        });
                        setIsLoading(false);
                    });
                } else {
                    setAogiSites([]);
                    setAogiSitesRedux([]);
                    setIsLoading(false);
                }

            } else {

                GetSiteListByID(siteUploadTableProps.selectedCustomer?.customerID, aogiSiteRedux).then((res: any) => {

                    setAogiSites(res);
                    setIsLoading(false);
                })

            }
        }

    }

    const filterValues = () => {

        Helpers.ConsoleLog(siteUploadTableProps.selectedCustomer);
        let updatedList: any = aogiSites;

        if (searchCustomer) {

            let regex = /"(\\.|[^"\\])*"/g;;
            var exactMatchText: any = searchCustomer != null ? searchCustomer.match(regex)?.[0].replace(/"/g, "") : null;

            if (exactMatchText) {
                updatedList = updatedList.filter((item: AOGISite) => {
                    return ((item?.assetType?.toLowerCase() == exactMatchText?.toLowerCase())
                        || (item?.type?.toLowerCase() == exactMatchText?.toLowerCase())
                        || (item?.serialNo?.toLowerCase() == exactMatchText?.toLowerCase())
                        || (item?.thingNo?.toLowerCase() == exactMatchText?.toLowerCase())
                        || (item?.county?.toLowerCase() == exactMatchText?.toLowerCase())
                        || (item?.state?.toLowerCase() == exactMatchText?.toLowerCase())
                        || (item?.location?.split(",")[0].toLowerCase() == exactMatchText?.toLowerCase())
                        || (item?.location?.split(",")[1].toLowerCase() == exactMatchText?.toLowerCase()));
                });

            } else {
                updatedList = updatedList.filter((item: AOGISite) => {
                    return ((item?.assetType?.toLowerCase().includes(searchCustomer?.toLowerCase()))
                        || (item?.type?.toLowerCase().includes(searchCustomer?.toLowerCase()))
                        || (item?.serialNo?.toLowerCase().includes(searchCustomer?.toLowerCase()))
                        || (item?.thingNo?.toLowerCase().includes(searchCustomer?.toLowerCase()))
                        || (item?.county?.toLowerCase().includes(searchCustomer?.toLowerCase()))
                        || (item?.state?.toLowerCase().includes(searchCustomer?.toLowerCase()))
                        || (item?.location?.split(",")[0].toLowerCase().includes(searchCustomer?.toLowerCase()))
                        || (item?.location?.split(",")[1].toLowerCase().includes(searchCustomer?.toLowerCase())));
                });
            }

        }
        setFilteredValues(updatedList);
    }

    const handleClear = (e: any) => {
        if (searchCustomer) {
            setSearchCustomer('')
        }
    }

    const onDataRead = (data: any) => {
        Helpers.ConsoleLog("sites created", data);
        siteUploadTableProps.updateSelectedCustomer(data.selectedCustomer)
        fetchSites();

    }
    return <>
        <Toast ref={toast} />
        <Loading isLoading={isLoading} text={loadingText} />
        {canAccessEditSiteUpload &&
            <div className="d-flex flex-row  justify-content-between align-items-center">
                <span className="label label-info download-button-template" onClick={async () => await getDownloadFile()}>{t("SiteUpload.DownloadButtonText")}</span>
                <button title="Click here to Create Sites" type="button" onClick={() => setCreateSiteModalShow(true)} className="btn btn-primary create-sites float-right"  >{t("SiteUpload.CreateButton")}</button>
            </div>
        }


        <CreateSite setAogiSitesRedux={setAogiSitesRedux} visible={createSiteModalShow} onHide={() => setCreateSiteModalShow(false)} setIsLoading onDataRead={onDataRead} />

        <Dialog header={`Map - ${currentCustomerMapPinName}`} visible={visibleGoogleMap} style={{ width: '50vw' }} onHide={() => toggleGoogleMap()}>
            <GoogleMap lati={currentCustomerLatitude} lon={currentCustomerLongitude} name={currentCustomerMapPinName} />
            <br />
        </Dialog>
        {(searchCustomer || filteredValues?.length > 0) &&
            <div className="d-flex flex-row  justify-content-start align-items-center">
                <label className="custom_common_label">{t("SearchLabel")}: &nbsp;&nbsp;&nbsp; </label>
                <input type="text" name="sitebulkuploadsearch" value={searchCustomer} id="sitebulkuploadsearch" className="form-control input-sm" onChange={(e: any) => {
                    e.preventDefault();
                    setSearchCustomer(e.target.value);

                }} />
                <div className="searchClear">
                    <button onClick={handleClear} className="btn btn-primary" > X</button >
                    <InfoIcon />
                </div>
            </div>
        }
        <br />
        <DataTable
            columns={columns}
            data={filteredValues}
            sortIcon={sortIcon}
            highlightOnHover={true}
            pagination={true}
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            customStyles={dataTableStyles}
        />
        <ConfirmDialog />
    </>
}

export default AOGISiteTable