import { useState, useRef } from 'react';
import 'font-awesome/css/font-awesome.min.css';
import { Tooltip } from 'primereact/tooltip';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { setEmissionBySizeHeightPanel, setMapHeightPanel, setSurveyCompStatusHeightPanel, setSurveyEmissionStackGraph } from '../../store/AOGI/common';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useTranslation } from 'react-i18next';


interface Props {
    InnerComponent: any;
    Heading?: string;
    downloadData?: any
}

const RightPanel = (ParaForRightCompEvents: Props) => {
    const { t } = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const { InnerComponent, Heading, downloadData } = ParaForRightCompEvents;
    var ToolTip: any = Heading;
    if (Heading === 'Survey Complete') {

        ToolTip = 'Survey Completion Status';
    } else if (Heading === 'Emission By Size') {
        ToolTip = 'Emission By Size';
    }
    const [resize_icon, setResizeIcon] = useState('fa-expand');
    const [resize_class, setResizeClass] = useState('');
    const [toggle_icon, setToggleIcon] = useState('fa-chevron-up');
    const [toggle_class, setToggleClass] = useState('');
    const [toggle_question, setToggleQuestion] = useState();

    const toggle_ref = useRef<any>(null);
    const resize_ref = useRef<any>(null);
    const op = useRef<any>(null);
    const op_1 = useRef<any>(null);

    var resizeBlock = (resize_icon_para: any, e: any) => {

        if (resize_icon_para === 'fa-expand') {
            setResizeIcon('fa-compress');
            setResizeClass("right-component-to-full-size");
            toggle_ref.current.style.visibility = "hidden";
            if (Heading === 'Survey Emission' || Heading === 'Emission by Source') {
                dispatch(setSurveyEmissionStackGraph(true));
            }
            if (Heading === "Emission Overview") {
                dispatch(setMapHeightPanel(true))
            }
            if (Heading === "Emission By Size") {
                dispatch(setEmissionBySizeHeightPanel(true));
            }
            if (Heading === "Survey Complete") {
                dispatch(setSurveyCompStatusHeightPanel(true))
            }

        } else {
            setResizeIcon('fa-expand');
            setResizeClass("");
            toggle_ref.current.style.visibility = "visible";

            if (Heading === 'Survey Emission' || Heading === 'Emission by Source') {
                dispatch(setSurveyEmissionStackGraph(false));
            }
            if (Heading === "Emission Overview") {
                dispatch(setMapHeightPanel(false))
            }
            if (Heading === "Emission By Size") {
                dispatch(setEmissionBySizeHeightPanel(false));
            }
            if (Heading === "Survey Complete") {
                dispatch(setSurveyCompStatusHeightPanel(false))
            }

        }
    }

    var toogleBlock = (toggle_icon_para: any, e: any) => {

        if (toggle_icon_para === 'fa-chevron-up') {
            setToggleIcon('fa-chevron-down');
            setToggleClass("right-component-to-toggle");
            resize_ref.current.style.visibility = "hidden";
        } else {
            setToggleIcon('fa-chevron-up');
            setToggleClass("");
            resize_ref.current.style.visibility = "visible";

        }
    }

    const downloadExcelData = (event: any) => {
        downloadData();
    }

    return <>
        <div className={`${resize_class} mb-4`}>
            <div className="panel_div">
                <Tooltip target=".common_panel_heading_test" />
                {Heading && <span className='common_panel_heading_test' data-pr-tooltip={ToolTip} data-pr-position="top" data-pr-at="right+5 top" data-pr-my="left center-2" style={{ fontSize: '4rem' }}>{Heading}</span>}
                {(Heading == 'Survey Emission') && <label className="top20label" data-i18n="Dashboard.TopTwenty">Top 20</label>}
                {(Heading == ('Emission by Source')) && <label className="top20label" data-i18n="Dashboard.TopTwenty">Top 20</label>}
                <div className="panel_icon_div">
                    {(Heading == ('Emission by Source')) && <i className={`pi pi-question-circle custom-fa-icon-style fs-5 me-3`} onClick={(e) => { setToggleQuestion(op.current.toggle(e)) }}></i>}
                    <OverlayPanel ref={op}>
                        {
                            window.location.href.indexOf("dashboard") > -1 ?
                                <>
                                    <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#ffb300' }}>{t("SurveyDetails.TableSizeSmall")}</label></div>
                                    <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#fc7a0f' }}>{t("SurveyDetails.TableSizeMedium")}</label></div>
                                    <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#f44336' }}>{t("SurveyDetails.TableSizeLarge")}</label></div>
                                </>
                                :
                                <>
                                    <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#283250', paddingBottom: '5px' }}>{t("SurveyDetails.TableSourceFlare")}</label></div>
                                    <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#902c2d' }}>{t("SurveyDetails.TableSourceHatch")}</label></div>
                                    <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#d5433d' }}>{t("SurveyDetails.TableSourceEnardoValve")}</label></div>
                                    <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#36a6f1' }}>{t("SurveyDetails.TableSourceVent")}</label></div>
                                    <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#f05440' }}>{t("SurveyDetails.TableSourceOther")}</label></div>
                                    <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#42D371' }}>{t("SurveyDetails.TableSourceNoEmission")}</label></div>
                                </>
                        }
                    </OverlayPanel>
                    {(Heading == ('Survey Emission')) && <i className={`pi pi-question-circle custom-fa-icon-style fs-5 me-3`} onClick={(e) => { (op_1.current.toggle(e)) }}></i>}
                    <OverlayPanel ref={op_1}>
                        <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#283250', paddingBottom: '5px' }}>{t("SurveyDetails.TableSourceFlare")}</label></div>
                        <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#902c2d' }}>{t("SurveyDetails.TableSourceHatch")}</label></div>
                        <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#d5433d' }}>{t("SurveyDetails.TableSourceEnardoValve")}</label></div>
                        <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#36a6f1' }}>{t("SurveyDetails.TableSourceVent")}</label></div>
                        <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#f05440' }}>{t("SurveyDetails.TableSourceOther")}</label></div>
                        <div style={{ paddingBottom: '5px' }}><label className="d-block badge" style={{ backgroundColor: '#42D371' }}>{t("SurveyDetails.TableSourceNoEmission")}</label></div>
                    </OverlayPanel>
                    {downloadData && <i className={`fa fa-file-excel-o custom-fa-icon-style fs-5 me-3`} onClick={(e) => downloadExcelData(e)}></i>}
                    <i className={`fa ${toggle_icon} custom-fa-icon-style fs-5 me-3`} ref={toggle_ref} onClick={(e) => toogleBlock(toggle_icon, e)}></i>
                    <i className={`fa ${resize_icon} custom-fa-icon-style fs-5 me-3`} ref={resize_ref} onClick={(e) => resizeBlock(resize_icon, e)}></i>
                </div>
            </div>

            <div className={`${toggle_class} card-body`}>
                {InnerComponent}

            </div>
        </div>

    </>
}

export default RightPanel;