// eslint-disable-next-line @typescript-eslint/no-unused-expressions

import './assets/css/bootstrap.min.css';

import './assets/css/nifty.min.css';
import './assets/css/enterprise.css';

import './index.css';
import "./localisation/i18n";

import App from './App';
import { Provider } from "react-redux";
import store from './store/store';
import ReactDOM from 'react-dom/client';

import '../node_modules/@popperjs/core/lib/popper';
import 'font-awesome/css/font-awesome.min.css';
import { PermissionsProvider } from './providers/PermissionsProvider';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();