
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { BsSortDown } from 'react-icons/bs';
import DataTable from "react-data-table-component";
import "./survey.css";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import { AppDispatch, AppState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { addSurveyData } from "../../../store/AOGI/survey";
import { TableStyles } from 'react-data-table-component';
import { Toast } from 'primereact/toast';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import AddOrEditSurvey from "./add-or-edit-survey";
import { useNavigate } from "react-router-dom";
import SurveyService from "../../../services/survey/survey.service";
import AOGISurvey from "../../../interfaces/aogi-survey/aogi-survey";
import Helpers from "../../../utils/helpers";
import Customer from "../../../interfaces/customer/customer";
import moment from "moment";
import { splitTextByCharCount } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import Loading from "../../common/Loading";
import UserService from "../../../services/user/user.service";
import usePermissionsContext from "../../../hooks/usePermissionContext";
import InfoIcon from "../../common/InfoIcon";

interface ColumnDef {
  name: string,
  cell?: any,
  selector?: any,
  sortField?: string,
  sortable?: boolean,
  style?: any,
  width?: any,
  omit?: boolean
}

const dataTableStyles: TableStyles = {

  headCells: {
    style: {
      borderTop: "1px solid rgba(0,0,0,0.07)",
      borderLeft: "1px solid rgba(0,0,0,0.07)",
      '&:last-of-type': {
        borderRight: "1px solid rgba(0,0,0,0.07)",
      },
      justifyContent: "space-between",
      fontWeight: "bold",
      paddingLeft: "5px",

    }
  },
  cells: {
    style: {
      borderLeft: "1px solid rgba(0,0,0,0.07)",
      '&:last-of-type': {
        borderRight: "1px solid rgba(0,0,0,0.07)",
      }
    }
  }
}

interface SiteSurveyPropsInterface {
  selectedCustomer: Customer;
  selectedSurveyName: AOGISurvey;
  selectedStatus: any;
  statusValues?: any;
  surveyDate: Date;
  CustData: any,
  setSurveyDropDownNames?: any
  setSurveyDate?: any,
  exportSurveyData?: any
}

const SiteSurveyTable = (siteSurveyProps: SiteSurveyPropsInterface) => {
  const { t } = useTranslation();
  var navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const toast = useRef<any>(null);
  let { canAccessEditSiteSurvey } = usePermissionsContext();
  const [surveyList, surveyListUpdate] = useState<AOGISurvey[]>([]);
  const [filteredValues, setFilteredValues] = useState<AOGISurvey[]>([]);
  const [searchCustomer, setSearchCustomer] = useState<string>('');
  const [addEditSurveyModalShow, setAddEditSurveyModalShow] = React.useState(false);
  const [tempDataForUpdateSurvey, setTempDataForUpdateSurvey] = useState<AOGISurvey>();
  const [isEditSurvey, setIsEditSurvey] = useState<boolean>(false);
  const Sites_Redux = useSelector((state: AppState) => state.AOGISurveyIndex.SurveyData);
  const sortIcon = <BsSortDown />;
  const [isLoadingInProgress, setIsLoadingInProgress] = useState<boolean>(false);
  const [loadingText, loadingTextUpdate] = useState("");
  const [isexportInProgress, setIsexportInProgress] = useState(false);

  useEffect(() => {
    siteSurveyProps.setSurveyDropDownNames(surveyList);
  }, [surveyList]);

  useEffect(() => {
    if (Sites_Redux == "") {
      getSurveyListFromApi();
    } else {
      surveyListUpdate(Sites_Redux as any);


    }



  }, []);

  useEffect(() => {
    if (siteSurveyProps.exportSurveyData > 0 && !isexportInProgress) {
      setIsexportInProgress(true);
      if (filteredValues?.length > 0) {
        Helpers.exportToCSV(filteredValues, "surveys", 'Surveys', { customerName: 'Customer', surveyName: 'Survey Name', surveyDate: 'Survey Date', siteCount: 'Site Count', createdBy: 'Requested By', createdOn: 'Request On', status: 'Status' }, ["customerName", "surveyName", "surveyDate", "siteCount", "createdBy", "createdOn", "status"]);
      }
      else
        toast?.current?.show({ severity: 'info', summary: 'No Data!', detail: 'There is no data to download!!', life: 3000 });

      setIsexportInProgress(false);
    }
  }, [siteSurveyProps.exportSurveyData]);

  useEffect(() => {
    getSurveyListFromApi()
  }, [siteSurveyProps.surveyDate]);

  const getSurveyListFromApi = () => {
    setIsLoadingInProgress(true);
    UserService.getCurrentUser().then((res) => {
      if (res.type == "CustomerAdmin") {
        SurveyService.getSurveyList({ surveyDate: siteSurveyProps.surveyDate, customerID: res.customerID }).then((surveyList: AOGISurvey[]) => {
          surveyListUpdate(surveyList);
          dispatch(addSurveyData(surveyList));
          setIsLoadingInProgress(false);
        })
      } else {
        SurveyService.getSurveyList({ surveyDate: siteSurveyProps.surveyDate }).then((surveyList: AOGISurvey[]) => {
          surveyListUpdate(surveyList);
          dispatch(addSurveyData(surveyList));
          setIsLoadingInProgress(false);
        })
      }
    });


  }

  const columns: ColumnDef[] = [
    {
      name: t("Survey.TableCustomer"),
      selector: (row: AOGISurvey) => row.customerName,
      sortable: true,
      // width: "15%"
    },
    {
      name: t("Survey.TableSurveyName"),
      selector: (row: AOGISurvey) => row.surveyName,
      cell: (row: AOGISurvey) => {
        return <a href="javascript:void(0)" title="click here to view/edit survey emission details" onClick={() => {
          navigate(`/aogi/survey-details/${row._id}/${row.customerName}/${row.customerID}`)
        }}>{row.surveyName}</a>
      },
      sortable: true,
      width: "18%"
    },
    {

      name: t("Survey.TableSurveyDate"),
      selector: (row: AOGISurvey) => row.surveyDate,
      cell: (row: AOGISurvey, index: number, column: any, id: number) => {
        return <>
          {moment(row.surveyDate).format("DD-MMM-YYYY")} {row.surveyTimeZone ? "(" + row.surveyTimeZone + ")" : ""}
        </>
      },
      sortable: true,
      width: "11%"
    },
    {
      name: t("Survey.TableSiteCount"),
      selector: (row: AOGISurvey) => row.siteCount,
      sortable: true,
      width: "10%",
    },
    {
      name: t("Survey.TableRequestedBy"),
      selector: (row: AOGISurvey) => (row.createdBy == "undefined undefined") ? "" : row.createdBy,
      sortable: true,
      // width: "12%"
    },
    {
      name: t("Survey.TableRequestOn"),
      selector: (row: AOGISurvey) => row.createdOn,
      cell: (row: AOGISurvey, index: number, column: any, id: number) => {
        return <>
          {moment(row.createdOn).format("DD-MMM-YYYY hh:mm:ss A")}
        </>
      },
      sortable: true,
      width: "17%"
    },
    {
      name: t("Survey.TableStatus"),
      selector: (row: AOGISurvey) => row.status,
      sortable: true,
      width: "11%"
    },
    {
      name: t("Survey.TableAction"),
      cell: (row: AOGISurvey, index: number, column: any, id: number) => {

        return (
          <>
            {(row.status == "OPEN" || row.status == "SCHEDULED" || row.status == "INPROGRESS") &&
              <>
                <i className='fa fa-2x fa-edit text-primary' aria-hidden='true' style={{ fontSize: '20px', cursor: "pointer" }} onClick={(e: any) => onDisplay(row)}></i>
                <span className="mobile_action">&nbsp;&nbsp;</span>
              </>
            }

            {(row.status == "OPEN" || row.status == "INPROGRESS" || row.status == "SCHEDULED") &&
              <>
                <i className="fa fa-2x fa-trash text-danger" aria-hidden="true" style={{ fontSize: "20px", marginBottom: "3px", cursor: "pointer" }} onClick={() => { confirmDeleteSurvey(row) }}></i>

              </>
            }
          </>
        )
      },
      width: "8%",
      omit: canAccessEditSiteSurvey ? false : true,
    }
  ];

  const confirmDeleteSurvey = (survey: AOGISurvey) => {
    confirmDialog({
      style: { whiteSpace: "pre-line" },
      message: 'Are you sure you want to remove this survey?' +
        "\nCustomer : " + survey.customerName +
        "\nName : " + survey.surveyName +
        "\nDate : " + moment(survey.surveyDate).format("DD-MMM-YYYY"),
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await removeSurvey(survey);
        toast?.current?.show({ severity: 'success', summary: 'Success Message', detail: 'Deleted Successfully', life: 3000 });
      }
    });
  }

  const removeSurvey = async (survey: AOGISurvey) => {
    if (survey._id) {
      await SurveyService.removeAogiSurvey(survey._id).then((data: any) => {
        Helpers.ConsoleLog("removing survey data", data);
      });
      var updatedFilterValues = surveyList.filter((item: AOGISurvey) => {
        return !(survey.surveyName == item.surveyName)
      });
      surveyListUpdate(updatedFilterValues);
      dispatch(addSurveyData(updatedFilterValues))
      setFilteredValues(updatedFilterValues);
      getSurveyListFromApi()
    } else {
      Helpers.ConsoleLog("Id not found");
    }
  }

  useLayoutEffect(() => {
    setFilteredValues(surveyList);
  }, [])

  useEffect(() => {
    filterValues();
  }, [searchCustomer, siteSurveyProps.selectedCustomer, siteSurveyProps.selectedSurveyName, siteSurveyProps.selectedStatus]);

  useEffect(() => {
    filterValues();
  }, [surveyList])

  function convert(str: any) {
    var date = new Date(str);

    return [date.getFullYear()].join("-");
  }

  const filterValues = () => {

    let filteredSurveyList = surveyList;
    if (siteSurveyProps.selectedCustomer?.name != "All Customers" && siteSurveyProps.selectedCustomer?.name) {
      filteredSurveyList = filteredSurveyList?.filter((survey: AOGISurvey) => {
        return survey?.customerName?.toLowerCase() == siteSurveyProps.selectedCustomer?.name?.toLowerCase();
      });
    }

    if (siteSurveyProps.selectedStatus && siteSurveyProps.selectedStatus.length > 0) {
      filteredSurveyList = filteredSurveyList?.filter((surveyItem: AOGISurvey) => {
        return siteSurveyProps.selectedStatus.find((statusItem: any) => { return surveyItem.status?.toLowerCase() == statusItem.status?.toLowerCase() }) != undefined;
      });
    }
    else {
      filteredSurveyList = [];
    }

    if (siteSurveyProps.surveyDate) {

      filteredSurveyList = filteredSurveyList?.filter((surveyItem: AOGISurvey) => {
        return convert(surveyItem.surveyDate) == convert(siteSurveyProps.surveyDate);
      });
    }

    if (siteSurveyProps.selectedSurveyName?.surveyName != "All Surveys" && siteSurveyProps.selectedSurveyName?.surveyName) {
      filteredSurveyList = filteredSurveyList?.filter((surveyItem: AOGISurvey) => {
        return surveyItem?.surveyName?.toLowerCase() == siteSurveyProps.selectedSurveyName?.surveyName?.toLowerCase()
      });
    }

    if (searchCustomer) {

      let regex = /"(\\.|[^"\\])*"/g;;
      var exactMatchText: any = searchCustomer != null ? searchCustomer.match(regex)?.[0].replace(/"/g, "") : null;

      if (exactMatchText) {
        filteredSurveyList = filteredSurveyList?.filter((surveyItem: AOGISurvey) => {

          return (surveyItem?.customerName?.toLowerCase() == exactMatchText?.toLowerCase())

            || (surveyItem?.surveyName?.toLowerCase() == exactMatchText?.toLowerCase())

            || (surveyItem?.siteCount?.toString() == exactMatchText?.toLowerCase())

            || (moment(surveyItem?.createdOn)?.format("DD-MMM-YYYY hh:mm:ss A").toString().toLowerCase() == exactMatchText?.toLowerCase())

            || (moment(surveyItem?.surveyDate)?.format("DD-MMM-YYYY").toString().toLowerCase() == exactMatchText?.toLowerCase())

            || (surveyItem?.surveyTimeZone?.toLowerCase() == exactMatchText?.toLowerCase())

            || (surveyItem?.status?.toLowerCase() == exactMatchText?.toLowerCase())

            || (surveyItem?.createdBy?.toLowerCase() == exactMatchText?.toLowerCase())
        });

      } else {

        filteredSurveyList = filteredSurveyList?.filter((surveyItem: AOGISurvey) => {

          return (searchCustomer && surveyItem?.customerName?.toLowerCase().includes(searchCustomer?.toLowerCase()))

            || (searchCustomer && surveyItem?.surveyName?.toLowerCase().includes(searchCustomer?.toLowerCase()))

            || (searchCustomer && surveyItem?.siteCount?.toString().includes(searchCustomer?.toLowerCase()))

            || (searchCustomer && moment(surveyItem?.createdOn)?.format("DD-MMM-YYYY hh:mm:ss A").toString().toLowerCase().includes(searchCustomer?.toLowerCase()))

            || (searchCustomer && moment(surveyItem?.surveyDate)?.format("DD-MMM-YYYY").toString().toLowerCase().includes(searchCustomer?.toLowerCase()))

            || (searchCustomer && surveyItem?.surveyTimeZone?.toLowerCase().includes(searchCustomer?.toLowerCase()))

            || (searchCustomer && surveyItem?.status?.toLowerCase().includes(searchCustomer?.toLowerCase()))

            || (searchCustomer && surveyItem?.createdBy?.toLowerCase().includes(searchCustomer?.toLowerCase()))
        });

      }
    }

    setFilteredValues(filteredSurveyList);

  }

  const handleClear = (e: any) => {
    if (searchCustomer) {
      setSearchCustomer('')
    }

  }

  const onDisplay = (data: AOGISurvey) => {
    setAddEditSurveyModalShow(true)
    setTempDataForUpdateSurvey(data)
    setIsEditSurvey(true)
  }

  const refreshSurveyTable = () => {
    getSurveyListFromApi();
  }


  return <>
    <Toast ref={toast} />
    <Loading isLoading={isLoadingInProgress} text={loadingText} />

    <div className="Buttons">
      {canAccessEditSiteSurvey && <>
        <button className="btn btn-primary create-sites float-right" type="button" onClick={() => { setAddEditSurveyModalShow(true); setIsEditSurvey(false); setTempDataForUpdateSurvey({}) }} >{t("CreateSurvey.ButtonText")} </button>
      </>}

    </div>

    <AddOrEditSurvey statusValues={siteSurveyProps.statusValues} refreshSurveyTable={refreshSurveyTable} visible={addEditSurveyModalShow} setAddEditSurveyModalShow={setAddEditSurveyModalShow} aogiSurvey={tempDataForUpdateSurvey} setSurveyDate={siteSurveyProps.setSurveyDate} isEditSurvey={isEditSurvey} />

    <br />

    <div className="col-md-12" style={{ marginRight: '20px', marginBottom: "10px" }}>
      <br className='d-md-none' />
      <br className='d-md-none' />
      {(searchCustomer || filteredValues?.length > 0) &&
        <div className="d-flex flex-row  justify-content-start align-items-center">
          <label className="custom_common_label">{t("SearchLabel")}: &nbsp;&nbsp;&nbsp; </label>
          <input type="text" name="sitebulkuploadsearch" value={searchCustomer} id="sitebulkuploadsearch" className={"form-control input-sm"} onChange={(e: any) => {
            e.preventDefault();
            setSearchCustomer(e.target.value);
          }} />
          <div className="searchClear">
            <button type="button" onClick={handleClear} className="btn btn-primary" > X</button >
            <InfoIcon />
          </div>
        </div>
      }
    </div>

    <DataTable
      columns={columns}
      defaultSortAsc={false}
      defaultSortFieldId={6}
      data={filteredValues}
      highlightOnHover={true}
      pagination={true}
      paginationPerPage={10}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
      customStyles={dataTableStyles}
      sortIcon={sortIcon}
    />
    <ConfirmDialog />
  </>
}

export default SiteSurveyTable;