import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import DataTable, { TableStyles } from "react-data-table-component";
import { BsSortDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/store";
import { useTranslation } from "react-i18next";
import { Dialog } from "primereact/dialog";
import AogiSiteService from "../../../../services/aogi-site/aogi-site.service";
import PopSurveyEmissionData from "../Map/pop-survey-emission-data";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end


interface ColumnDef {
    name: string,
    cell?: any,
    selector?: any,
    sortable?: boolean,
    style?: any,
    width?: any
}


const dataTableStyles: TableStyles = {
    table: {
        style: {
            // overflow:"hidden"
        }
    },
    headCells: {
        style: {
            borderTop: "1px solid rgba(0,0,0,0.07)",
            borderLeft: "1px solid rgba(0,0,0,0.07)",
            '&:last-of-type': {
                borderRight: "1px solid rgba(0,0,0,0.07)",
            },
            justifyContent: "left",
            fontWeight: "bold",
            paddingLeft: "5px",

        }
    },
    cells: {
        style: {
            borderLeft: "1px solid rgba(0,0,0,0.07)",
            '&:last-of-type': {
                borderRight: "1px solid rgba(0,0,0,0.07)",
            },
            width: "fitContent",
            justifyContent: "left",
            paddingLeft: "5px",

        }
    },
    pagination: {
        style: {
            fontSize: "12px",
        }
    }
}


const EmissionBySizeChart = (props: any) => {
    const ResizeStoreToSetHeight = useSelector((state: AppState) => state.AOGICommon.setEmissionBySizeHeightPanel);

    const { t } = useTranslation();

    const MainData = props.filteredValues;
    const [SmallTotalCount, setSmallTotalCount] = useState(0);
    const [MediumTotalCount, setMediumTotalCount] = useState(0);
    const [LargeTotalCount, setLargeTotalCount] = useState(0);
    const [PerPageCount, setPerPageCount] = useState(5);

    // Emission by Size
    const [EmissionBySizeLargeObj, setEmissionBySizeLargeObj] = useState([]);
    const [EmissionBySizeMediumObj, setEmissionBySizeMediumObj] = useState([]);
    const [EmissionBySizeSmallObj, setEmissionBySizeSmallObj] = useState([]);
    const [displayChart, displayChartUpdate] = useState("none");

    const [displayTable, setDisplayTable] = useState([]);
    const [displayTableColumns, setDisplayTableColumns] = useState([]);

    const [showPopUp, setShowPopUp] = useState(false);
    const [surveyData, setSurveyData] = useState([]);
    const [titlePopUp, setTitlePopUp] = useState<string>("");
    const [tempLat, setTempLat] = useState<string>("");
    const [tempLon, setTempLon] = useState<string>("");
    const [overAllPulled, setOverAllPulled] = useState<string>("");

    const sortIcon = <BsSortDown />;
    const columns_large: any = [

        {
            name: t("Dashboard.EmissionBySizeTable.Name"),
            selector: (row: any) => row.Name,
            cell: (row: any) => <a href="javascript:void(0)" style={{ textDecoration: 'none' }} onClick={(e: any) => handleEmissionPopUp(e, row)}>{row.Name}</a>,
            sortable: true
        },
        {
            name: t("Dashboard.EmissionBySizeTable.Large"),
            selector: (row: any) => row.large,
            cell: (row: any) =>
                <span className="badge bg-purple" >{row.large}</span>,
            sortable: true,
            // width: "30%",
        }
    ];

    const columns_medium: any = [

        {
            name: t("Dashboard.EmissionBySizeTable.Name"),
            cell: (row: any) => <a href="javascript:void(0)" style={{ textDecoration: 'none' }} onClick={(e: any) => handleEmissionPopUp(e, row)}>{row.Name}</a>,
            selector: (row: any) => row.Name,
            sortable: true
        },
        {
            name: t("Dashboard.EmissionBySizeTable.Medium"),
            selector: (row: any) => row.medium,
            cell: (row: any) =>
                <span className="badge bg-purple" >{row.medium}</span>,
            sortable: true,
            // width: "30%",
        }
    ];

    const columns_small: any = [

        {
            name: t("Dashboard.EmissionBySizeTable.Name"),
            cell: (row: any) => <a href="javascript:void(0)" style={{ textDecoration: 'none' }} onClick={(e: any) => handleEmissionPopUp(e, row)}>{row.Name}</a>,
            selector: (row: any) => row.Name,
            sortable: true
        },
        {
            name: t("Dashboard.EmissionBySizeTable.Small"),
            selector: (row: any) => row.small,
            cell: (row: any) =>
                <span className="badge bg-purple" >{row.small}</span>,
            sortable: true,
            // width: "30%",
        }
    ];


    function DuplicateSizeObjectSetting(objArr: any, SizeType: any) {
        let counts = objArr?.reduce((prev: any, curr: any) => {
            let count = prev.get(curr.Name) || 0;
            if (SizeType == 'large') {
                prev.set(curr.Name, curr.large + count);
            } else if (SizeType == 'medium') {
                prev.set(curr.Name, curr.medium + count);
            } else if (SizeType == 'small') {
                prev.set(curr.Name, curr.small + count);
            }

            return prev;
        }, new Map());

        // then, map your counts object back to an array
        if (SizeType == 'large') {
            let reducedObjArr = [...counts].map(([Name, large]) => {
                return { Name, large }
            });
            reducedObjArr = reducedObjArr.sort(function (a: any, b: any) { return b.large - a.large })

            return reducedObjArr
        } else if (SizeType == 'medium') {
            let reducedObjArr = [...counts].map(([Name, medium]) => {
                return { Name, medium }
            });
            reducedObjArr = reducedObjArr.sort(function (a: any, b: any) { return b.medium - a.medium })
            return reducedObjArr

        } else if (SizeType == 'small') {
            let reducedObjArr = [...counts].map(([Name, small]) => {
                return { Name, small }
            });
            reducedObjArr = reducedObjArr.sort(function (a: any, b: any) { return b.small - a.small })

            return reducedObjArr;

        }
    }
    useEffect(() => {
        displayChartUpdate("none");
        if (MainData.length != 0) {
            let temp_small_count = 0;
            let temp_medium_count = 0;
            let temp_large_count = 0;

            let chart_small_bar: any = [];
            let chart_medium_bar: any = [];
            let chart_large_bar: any = [];

            MainData?.forEach((val: any) => {
                val.data.forEach((val_data: any) => {

                    let data_name = val_data.name;
                    let data_name_count_small = 0;
                    let data_name_count_medium = 0;
                    let data_name_count_large = 0;
                    val_data.emission.forEach((val_emission: any) => {

                        if (val_emission.size == 'Medium') {
                            if (val_emission.noOfLeaks != null) {
                                temp_medium_count = temp_medium_count + val_emission.noOfLeaks;
                                data_name_count_medium = data_name_count_medium + val_emission.noOfLeaks;
                            } else {
                                temp_medium_count++;
                                data_name_count_medium++;
                            }

                        }
                        if (val_emission.size == 'Large') {

                            if (val_emission.noOfLeaks != null) {
                                temp_large_count = temp_large_count + val_emission.noOfLeaks;
                                data_name_count_large = data_name_count_large + val_emission.noOfLeaks;
                            } else {
                                temp_large_count++;
                                data_name_count_large++;
                            }


                        }
                        if (val_emission.size == 'Small') {

                            if (val_emission.noOfLeaks != null) {
                                temp_small_count = temp_small_count + val_emission.noOfLeaks;
                                data_name_count_small = data_name_count_small + val_emission.noOfLeaks;
                            } else {
                                temp_small_count++;
                                data_name_count_small++;
                            }


                        }
                    });
                    if (data_name_count_small != 0) {
                        chart_small_bar.push({ "Name": data_name, "small": data_name_count_small, 'lat': val_data?.gmap?.lat, 'lon': val_data?.gmap?.lon, "Site": val_data?.name })

                    }

                    if (data_name_count_medium != 0) {
                        chart_medium_bar.push({ "Name": data_name, "medium": data_name_count_medium, 'lat': val_data?.gmap?.lat, 'lon': val_data?.gmap?.lon, "Site": val_data?.name })
                    }

                    if (data_name_count_large != 0) {
                        chart_large_bar.push({ "Name": data_name, "large": data_name_count_large, 'lat': val_data?.gmap?.lat, 'lon': val_data?.gmap?.lon, "Site": val_data?.name })


                    }
                })
            });

            setMediumTotalCount(temp_medium_count);
            setLargeTotalCount(temp_large_count);
            setSmallTotalCount(temp_small_count);

            setEmissionBySizeLargeObj(DuplicateSizeObjectSetting(chart_large_bar, "large") as any);
            setEmissionBySizeMediumObj(DuplicateSizeObjectSetting(chart_medium_bar, "medium") as any)
            setEmissionBySizeSmallObj(DuplicateSizeObjectSetting(chart_small_bar, "small") as any)

        } else {
            setMediumTotalCount(0);
            setLargeTotalCount(0);
            setSmallTotalCount(0);

            setEmissionBySizeLargeObj([])
            setEmissionBySizeMediumObj([])
            setEmissionBySizeSmallObj([])
        }
    }, [props])



    useEffect(() => {
        if (props?.filteredValues?.length > 0) {
            // Add data
            let total_count = LargeTotalCount + MediumTotalCount + SmallTotalCount;

            //Doughnut chart
            var doughnut = am4core.create("chartdivdoughnut", am4charts.PieChart);
            if (doughnut.logo) {
                doughnut.logo.disabled = true;
            }
            let large_per = ((LargeTotalCount / total_count) * 100).toFixed(2);
            let medium_per = ((MediumTotalCount / total_count) * 100).toFixed(2);
            let small_per = ((SmallTotalCount / total_count) * 100).toFixed(2);
            let percentage_obj = [
                {
                    'name': 'Large',
                    'percentage': large_per
                }, {
                    'name': 'Medium',
                    'percentage': medium_per
                }, {
                    'name': 'Small',
                    'percentage': small_per
                },
            ];
            percentage_obj = percentage_obj.sort(function (a: any, b: any) {
                return b.percentage - a.percentage;
            });
            let chart = [{
                "Name": "BIG EDDY 007",
                "large": 2,
            }, {
                "Name": "BIG EDDY 064",
                "large": 6,

            }, {
                "Name": "BIG EDDY 066",
                "large": 6,

            },
            ];
            let small_pulled = false;
            let medium_pulled = false;
            let large_pulled = false;
            if (percentage_obj[0].name == 'Small') {
                chart = EmissionBySizeSmallObj;
                small_pulled = true;
                setDisplayTableColumns(columns_small);
            } else if (percentage_obj[0].name == 'Medium') {
                chart = EmissionBySizeMediumObj;
                medium_pulled = true;
                setDisplayTableColumns(columns_medium);

            }
            else if (percentage_obj[0].name == 'Large') {
                chart = EmissionBySizeLargeObj;
                large_pulled = true;
                setDisplayTableColumns(columns_large);

            }
            barChart(chart);
            setDisplayTable(chart as any);
            // Add data
            doughnut.data = [{
                "country": "Large",
                "value": LargeTotalCount,
                color: "#f44336",
                pulled: large_pulled
            }, {
                "country": "Medium",
                "value": MediumTotalCount,
                color: "#fc7a0f",
                pulled: medium_pulled
            }, {
                "country": "Small",
                "value": SmallTotalCount,
                color: "#ffb300",
                pulled: small_pulled
            }];
            if (large_pulled == true) {
                setOverAllPulled("Large")
            } else if (medium_pulled == true) {
                setOverAllPulled("Medium")
            } else if (small_pulled == true) {
                setOverAllPulled("Small")
            }
            var pieSeries = doughnut.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "value";
            pieSeries.dataFields.category = "country";
            pieSeries.slices.template.propertyFields.isActive = "pulled";
            pieSeries.slices.template.propertyFields.fill = "color";
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;


            doughnut.innerRadius = am4core.percent(50);

            var label = pieSeries.createChild(am4core.Label);
            label.text = percentage_obj[0].percentage + "%\n " + percentage_obj[0].name;

            label.horizontalCenter = "middle";
            label.verticalCenter = "middle";
            label.fontSize = 20;

            pieSeries.slices.template.events.on("hit", function (ev: any) {

                let series = ev.target.dataItem.component;
                series.slices.each(function (item: any) {
                    if (item.isActive && item != ev.target) {
                        item.isActive = false;
                    }
                })


                let hit_event = ev.target.dataItem._dataContext.country;

                let chart = [{
                    "Name": "BIG EDDY 087",
                    "large": 8,
                }, {
                    "Name": "BIG EDDY 044",
                    "large": 16,

                }, {
                    "Name": "BIG EDDY 046",
                    "large": 4,

                },
                ];
                if (hit_event == 'Small') {
                    chart = EmissionBySizeSmallObj;
                    setDisplayTableColumns(columns_small);
                } else if (hit_event == 'Medium') {
                    chart = EmissionBySizeMediumObj;
                    setDisplayTableColumns(columns_medium);

                }
                else if (hit_event == 'Large') {
                    chart = EmissionBySizeLargeObj;
                    setDisplayTableColumns(columns_large);

                }

                barChart(chart);
                setDisplayTable(chart as any);
                var doughnutSlice = ev.target._dataItem?._dataContext;

                label.text = ((doughnutSlice.value / total_count) * 100).toFixed(2) + "%" + "\n" + doughnutSlice.country;
            })
        }

    }, [props, LargeTotalCount, MediumTotalCount, SmallTotalCount])

    const barChart = (para: any) => {
        let chart = am4core.create("chartdiv", am4charts.XYChart);
        if (chart.logo) {
            chart.logo.disabled = true;
        }

        chart.data = para

        // Create axes
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Name";
        categoryAxis.renderer.grid.template.opacity = 0;

        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.grid.template.opacity = 0;
        valueAxis.renderer.ticks.template.strokeOpacity = 0.5;
        valueAxis.renderer.ticks.template.stroke = am4core.color("#495C43");
        valueAxis.renderer.ticks.template.length = 10;
        valueAxis.renderer.line.strokeOpacity = 0.5;
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.minGridDistance = 40;

        // Create series
        function createSeries(field: any, name: any, color: any) {
            let val = "{valueX}";
            if (val !== "" && val !== null) {
                let series = chart.series.push(new am4charts.ColumnSeries());

                series.dataFields.valueX = field;
                series.dataFields.categoryY = "Name";
                series.stacked = true;
                series.name = name;
                series.columns.template.tooltipText = "{categoryY} : {name} {valueX}";
                var labelBullet = series.bullets.push(new am4charts.LabelBullet());
                labelBullet.label.text = "{valueX}";
                labelBullet.label.horizontalCenter = "right";
                labelBullet.label.paddingRight = 10;
                labelBullet.label.fill = am4core.color("#fff");
                series.columns.template.fill = color;
                series.columns.template.stroke = color;

                labelBullet.label.adapter.add("textOutput", function (text: any, target: any) {
                    // Hide labels with 0 value
                    if (target.dataItem && target.dataItem.valueX == 0) {
                        return "";
                    }
                    return text;
                });

            }
        }


        createSeries("small", "Small", "#ffb300");
        createSeries("medium", "Medium", "#fc7a0f");
        createSeries("large", "Large", "#f44336");
        setTimeout(() => {
            displayChartUpdate("block")
        }, 3000)
    }
    useEffect(() => {
        (ResizeStoreToSetHeight) ? setPerPageCount(10) : setPerPageCount(5);

    }, [ResizeStoreToSetHeight, PerPageCount]);

    const handleEmissionPopUp = async (e: any, row: any) => {
        const retrecord = MainData?.map((subData: any) => {
            return subData?.data.filter((a: any) => a.name == row?.Name)
        })
        setTempLat(retrecord[0]?.[0].gmap.lat)
        setTempLon(retrecord[0]?.[0].gmap.lon)

        let currData: any = [];
        if (overAllPulled == "Small") {
            currData = EmissionBySizeSmallObj;
        } else if (overAllPulled == "Medium") {
            currData = EmissionBySizeMediumObj;
        } else if (overAllPulled == "Large") {
            currData = EmissionBySizeLargeObj;
        }
        let tempGLatLon: any = currData?.filter((itm: any) => itm.Name == row?.Name)
        setShowPopUp(true)
        if (row?.Name && props?.customer?.customerID) {
            await AogiSiteService.getAogiSiteSurveyDetails(row?.Name as string, props?.customer?.customerID as string).then((res) => {
                if (res?.length > 0) {
                    const updatedData = res.sort((a: any, b: any) => {
                        return (new Date(b.date) as any) - (new Date(a.date) as any); // ascending
                    })
                    setSurveyData(updatedData)
                }
            })
            setTitlePopUp(row?.Name)
        }
    }
    return <>
        {
            displayTable?.length > 0 ? <div className="row">
                {/*  align-items-end */}
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12" style={{ marginTop: "2%" }}>
                    <DataTable
                        columns={displayTableColumns}
                        data={displayTable}
                        highlightOnHover={true}
                        pagination={true}
                        paginationPerPage={PerPageCount}
                        fixedHeader
                        paginationIconFirstPage={false}
                        paginationIconLastPage={false}
                        paginationComponentOptions={{ rowsPerPageText: '', rangeSeparatorText: 'of', noRowsPerPage: true, selectAllRowsItem: true, selectAllRowsItemText: '' }}
                        customStyles={dataTableStyles}
                        sortIcon={sortIcon}
                    />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div id="chartdivdoughnut" style={{ width: "110%", height: "350px", display: displayChart }}></div>
                </div>
            </div> : <div style={{ padding: '24px', textAlign: 'center', marginTop: '40px', color: 'rgba(0,0,0,0.87)' }}>{t("NoRecordsToDisplay")}</div>
        }

        <Dialog header={titlePopUp} visible={showPopUp} style={{ width: '50vw' }} /*footer={renderFooter('displayBasic2')}*/ onHide={() => setShowPopUp(false)} className={'create-modal-popup'}>
            <PopSurveyEmissionData surveyData={surveyData} cid={props?.customer?.customerID} cusname={props?.customer?.name} siteName={titlePopUp} lat={tempLat} lon={tempLon} dropdownValue={[{ 'value': props?.filteredValues[0]?.id, 'label': props?.filteredValues[0]?.name }]} />

        </Dialog>
    </>
}

export default EmissionBySizeChart;