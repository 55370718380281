import DataTable, { TableStyles } from 'react-data-table-component';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { DataTable as RaectTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import reportWebVitals from '../../../../reportWebVitals';
import { FaEllipsisV } from 'react-icons/fa';

import Leak from "../../../../assets/img/icons/leak.png"
import Flare from "../../../../assets/img/icons/flare.png"
import Hatch from "../../../../assets/img/icons/hatch.png"
import OtherLeak from "../../../../assets/img/icons/otherleak.png"
import NoEmission from "../../../../assets/img/icons/onlineCircle.png";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import InfoIcon from '../../../common/InfoIcon';

interface ColumnDef {
  header?: string,
  body?: any,
  name?: any,
  cell?: any,
  selector?: any,
  sortable?: boolean,
  style?: any,
  width?: any
}
const dataTableStyles: TableStyles = {

  headCells: {
    style: {
      borderTop: "1px solid rgba(0,0,0,0.07)",
      borderLeft: "1px solid rgba(0,0,0,0.07)",
      '&:last-of-type': {
        borderRight: "1px solid rgba(0,0,0,0.07)",
      },
      justifyContent: "space-between",
      fontWeight: "bold",
      paddingLeft: "5px",

    }
  },
  cells: {
    style: {
      borderLeft: "1px solid rgba(0,0,0,0.07)",
      '&:last-of-type': {
        borderRight: "1px solid rgba(0,0,0,0.07)",
      }
    }
  }
}

interface Customer {
  SurveyName?: string;
  name?: string;
  Date?: string;
  Status?: any;
  Source?: any;
  Size?: any;
  Percent?: number;
}

const AllSurveyTable = (props: any) => {

  const { t } = useTranslation();
  var navigate = useNavigate();
  const [currentPageData, setCurrentPageData] = useState<Customer[]>([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedCustomerID, setSelectedCustomerID] = useState('');

  const session = window.localStorage;
  useEffect(() => {
    if (props?.filteredValues?.length > 0) {
      setCurrentPageData(props?.filteredValues)
    } else {
      setCurrentPageData([])
    }
    let selected_dashboard_customer_name: any = JSON.parse(session.getItem("customer-selected") as any);



    if (selected_dashboard_customer_name != "") {
      setSelectedCustomer(selected_dashboard_customer_name?.dashboardSelectedCustomer?.name);
      setSelectedCustomerID(selected_dashboard_customer_name?.dashboardSelectedCustomer?.customerID);
    }

  }, [props]);
  const columns: ColumnDef[] = [

    {
      name: t("Dashboard.AllSurveyTable.SurveyName"),
      selector: (row: any) => row.name,
      cell: (row: any) => {

        return <div><a href="javascript:void(0)" title="click here to view survey emission details"><Link
          to={`/aogi/survey-details/${row.id}/${selectedCustomer}/${selectedCustomerID}`}
          target="_blank"
          rel="noreferrer"
        >
          {row.name}
        </Link></a></div>
      },
      sortable: true,
      width: '19%'
    },


    {
      name: t("Dashboard.AllSurveyTable.Date"),
      selector: (row: any) => (row.date),
      cell: (row: any) => {
        return <>
          {moment(row.date).format("DD-MMM-YYYY")}
        </>
      },
      sortable: true,
      width: '11%'
    },
    {
      name: t("Dashboard.AllSurveyTable.Status"),
      selector: (row: any) => {
        if (row.status == "OPEN") {
          return (
            <span className="badge bg-info" style={{ textAlign: 'left' }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OPEN&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span >
          )
        } else if (row.status == "COMPLETED") {
          return (
            <span className="badge bg-success" style={{ textAlign: 'left' }}>&nbsp;COMPLETED&nbsp;</span>
          )
        } else if (row.status == "INPROGRESS") {
          return (
            <span className="badge bg-warning" style={{ textAlign: 'center' }}>INPROGRESS</span>
          )
        }
        else if (row.status == "SCHEDULED") {
          return (
            <span className="badge bg-primary" style={{ textAlign: 'center' }}>&nbsp;SCHEDULED&nbsp;</span>
          )
        }
        else if (row.status == "CANCELLED") {
          return (
            <span className="badge bg-danger" style={{ textAlign: 'center' }}>&nbsp;CANCELLED&nbsp;</span>
          )
        }
      },
      width: '10%',
    },
    {
      name: t("Dashboard.AllSurveyTable.EmissionSource"),
      cell: (row: any) => {
        let emiCount: any = updateEmissionCnt("source", row?.data)
        let styleDiv = false
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
          styleDiv = true;
        } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
          styleDiv = true;
        } else {
          styleDiv = false;
        }



        return (
          styleDiv == true ? <div style={{ marginTop: '5px ' }}>
            {(emiCount?.flare_count == 0 || emiCount?.flare_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#283250', marginBottom: '3px' }}>{emiCount?.flare_count} Flare</span>}

            {(emiCount?.leak_count == 0 || emiCount?.leak_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#283250', marginBottom: '3px' }}>{emiCount?.leak_count} Leak</span>}
            {(emiCount?.hatch_count == 0 || emiCount?.hatch_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#902c2d', marginBottom: '3px' }}>{emiCount?.hatch_count} Hatch</span>}
            {(emiCount?.enardovalve_count == 0 || emiCount?.enardovalve_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#d5433d', marginBottom: '3px' }}>{emiCount?.enardovalve_count} Enardovalve</span>}
            {(emiCount?.vent_count == 0 || emiCount?.vent_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#36a6f1', marginBottom: '3px' }}>{emiCount?.vent_count} Vent</span>}
            {(emiCount?.other_count == 0 || emiCount?.other_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#f05440', marginBottom: '3px' }}>{emiCount?.other_count} Other</span>}

          </div> : <>
            {(emiCount?.flare_count == 0 || emiCount?.flare_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#283250' }}>{emiCount?.flare_count} Flare</span>}

            {(emiCount?.leak_count == 0 || emiCount?.leak_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#283250' }}>{emiCount?.leak_count} Leak</span>}
            {(emiCount?.hatch_count == 0 || emiCount?.hatch_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#902c2d' }}>{emiCount?.hatch_count} Hatch</span>}
            {(emiCount?.enardovalve_count == 0 || emiCount?.enardovalve_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#d5433d' }}>{emiCount?.enardovalve_count} Enardovalve</span>}
            {(emiCount?.vent_count == 0 || emiCount?.vent_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#36a6f1' }}>{emiCount?.vent_count} Vent</span>}
            {(emiCount?.other_count == 0 || emiCount?.other_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '3px', backgroundColor: '#f05440' }}>{emiCount?.other_count} Other</span>}

          </>

        )

      },
      width: "28%"
    },

    {
      name: t("Dashboard.AllSurveyTable.EmissionSize"),
      cell: (row: any) => {
        let emiCount: any = updateEmissionCnt("size", row?.data)
        let styleDiv = false
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
          styleDiv = true;
        } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
          styleDiv = true;
        } else {
          styleDiv = false;
        }
        return (
          styleDiv == true ? <div style={{ marginTop: '5px ' }}>
            {(emiCount?.small_count == 0 || emiCount?.small_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '5px', backgroundColor: '#ffb300', marginBottom: '3px' }}>{emiCount?.small_count} Small</span>}
            {(emiCount?.medium_count == 0 || emiCount?.medium_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '5px', backgroundColor: '#fc7a0f', marginBottom: '3px' }}>{emiCount?.medium_count} Medium</span>}
            {(emiCount?.large_count == 0 || emiCount?.large_count == undefined) ? "" : <span className="d-block badge" style={{ backgroundColor: '#f44336', marginBottom: '3px' }}>{emiCount?.large_count} Large</span>}
          </div> :
            <>
              {(emiCount?.small_count == 0 || emiCount?.small_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '5px', backgroundColor: '#ffb300' }}>{emiCount?.small_count} Small</span>}
              {(emiCount?.medium_count == 0 || emiCount?.medium_count == undefined) ? "" : <span className="d-block badge" style={{ marginRight: '5px', backgroundColor: '#fc7a0f' }}>{emiCount?.medium_count} Medium</span>}
              {(emiCount?.large_count == 0 || emiCount?.large_count == undefined) ? "" : <span className="d-block badge" style={{ backgroundColor: '#f44336' }}>{emiCount?.large_count} Large</span>}
            </>
        )
      },
      width: "22%",
    },
    {
      name: t("Dashboard.AllSurveyTable.Emission%"),
      selector: (row: any) => {
        let emiPer: any = updateEmissionCnt("percentage", row?.data);

        if (emiPer != undefined) {
          return emiPer + "%"
        } else {
          return ""
        }

      },
      sortable: true,
      width: "10%"
    },

  ];

  const liveData = currentPageData?.length > 0 ? currentPageData : [];

  const filteredItems = liveData?.filter((item: any) => {

    let regex = /"(\\.|[^"\\])*"/g;;
    var exactMatchText: any = filterText != null ? filterText.match(regex)?.[0].replace(/"/g, "") : null;

    if (exactMatchText) {
      return (item?.name?.toString().toLowerCase() == exactMatchText?.toLowerCase() ||
        moment(item?.date).format('DD-MMM-YYYY').toString().toLowerCase() == exactMatchText?.toLowerCase() ||
        item?.status?.toString().toLowerCase() == exactMatchText?.toLowerCase())
    } else {
      return (item?.name?.toString().toLowerCase().includes(filterText?.toLowerCase()) ||
        moment(item?.date).format('DD-MMM-YYYY').toString().toLowerCase().includes(filterText?.toLowerCase()) ||
        item?.status?.toString().toLowerCase().includes(filterText?.toLowerCase()))
    }

  })

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div className="col-md-12" style={{ marginRight: '20px', marginBottom: "10px" }}>
        <div className="d-flex flex-row  justify-content-start align-items-center">
          <label className="custom_common_label">{t("SearchLabel")}: &nbsp;&nbsp;&nbsp; </label>
          <input type="text" name="sitebulkuploadsearch" id="sitebulkuploadsearch" className="form-control input-sm" onChange={(e: any) => setFilterText(e?.target?.value)} value={filterText} />
          <div className="dashboardTblsearchClear">
            <button type="button" className="btn btn-primary" onClick={(e) => handleClear()}> X</button >
            <InfoIcon />
          </div>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const updateEmissionCnt = (emiType: any, emiArr: any) => {
    if (emiType == "size" && emiArr.length != 0) {
      let temp_small_count = 0;
      let temp_medium_count = 0;
      let temp_large_count = 0;
      emiArr?.length > 0 && emiArr.forEach((val_data: any) => {
        val_data?.emission?.length > 0 && val_data.emission.forEach((val_emission: any) => {
          if (val_emission.size == 'Medium') {
            if (val_emission.noOfLeaks != null) {
              temp_medium_count = temp_medium_count + val_emission.noOfLeaks;
            } else {
              temp_medium_count++;
            }

          }
          if (val_emission.size == 'Large') {
            if (val_emission.noOfLeaks != null) {
              temp_large_count = temp_large_count + val_emission.noOfLeaks;
            } else {
              temp_large_count++;
            }

          }
          if (val_emission.size == 'Small') {
            if (val_emission.noOfLeaks != null) {
              temp_small_count = temp_small_count + val_emission.noOfLeaks;
            } else {
              temp_small_count++;
            }

          }
        })
      })
      const finObj = {
        "small_count": temp_small_count,
        "medium_count": temp_medium_count,
        "large_count": temp_large_count
      }
      return finObj;
    } else if (emiType == "source" && emiArr.length != 0) {
      let temp_flare_count = 0;
      let temp_noemission_count = 0;
      let temp_leak_count = 0;
      let temp_hatch_count = 0;
      let temp_enardovalve_count = 0;
      let temp_vent_count = 0;
      let temp_other_count = 0;
      emiArr.forEach((val_data: any) => {
        val_data.emission.forEach((val_emission: any) => {
          if (val_emission.source == 'Flare') {
            if (val_emission.noOfLeaks != null) {
              temp_flare_count = temp_flare_count + val_emission.noOfLeaks;
            } else {
              temp_flare_count++;
            }
          }
          if (val_emission.source == 'Other') {
            if (val_emission.noOfLeaks != null) {
              temp_other_count = temp_other_count + val_emission.noOfLeaks;
            } else {
              temp_other_count++;
            }
          }
          if (val_emission.source == 'Hatch') {
            if (val_emission.noOfLeaks != null) {
              temp_hatch_count = temp_hatch_count + val_emission.noOfLeaks;
            } else {
              temp_hatch_count++;
            }
          }
          if (val_emission.source == 'Leak') {
            if (val_emission.noOfLeaks != null) {
              temp_leak_count = temp_leak_count + val_emission.noOfLeaks;
            } else {
              temp_leak_count++;
            }
          }
          if (val_emission.source == 'Vent') {
            if (val_emission.noOfLeaks != null) {
              temp_vent_count = temp_vent_count + val_emission.noOfLeaks;
            } else {
              temp_vent_count++;
            }
          }
          if (val_emission.source == 'EnardoValve') {
            if (val_emission.noOfLeaks != null) {
              temp_enardovalve_count = temp_enardovalve_count + val_emission.noOfLeaks;
            } else {
              temp_enardovalve_count++;
            }
          }
          if (val_emission.source == 'NoEmission') {
            if (val_emission.noOfLeaks != null) {
              temp_noemission_count = temp_noemission_count + val_emission.noOfLeaks;
            } else {
              temp_noemission_count++;
            }
          }
        })
      })
      const finObj = {
        "flare_count": temp_flare_count,
        "noemission_count": temp_noemission_count,
        "leak_count": temp_leak_count,
        "hatch_count": temp_hatch_count,
        "enardovalve_count": temp_enardovalve_count,
        "vent_count": temp_vent_count,
        "other_count": temp_other_count,
      }
      return finObj;
    } else if (emiType == "percentage" && emiArr.length != 0) {
      let siteCnt = emiArr?.length;
      let EmiCnt = 0;
      let test_count = 0;
      emiArr.forEach((val_data: any) => {
        EmiCnt = 0;
        let count_flag: any = false;

        val_data.emission.forEach((val_emission: any) => {
          count_flag = false;


          if (val_emission?.size != "" || val_emission?.size != null || val_emission?.size != 'null' || val_emission?.size != undefined || val_emission?.size != 'undefined') {
            count_flag = true;
            if (val_emission?.noOfLeaks != null) {
              EmiCnt = EmiCnt + val_emission?.noOfLeaks;
            } else {
              EmiCnt = EmiCnt + 1;
            }

          }
        });
        if (EmiCnt > 0) {
          test_count++;
        }
      });
      return Math.ceil((test_count / siteCnt) * 100);
    }
  }



  return <>
    <div className="table-responsive">
      <DataTable
        columns={columns}
        data={filteredItems}
        highlightOnHover={true}
        pagination={true}
        subHeader
        subHeaderComponent={liveData?.length > 0 ? subHeaderComponentMemo : []}
        responsive={true}
        striped={true}
        className={'cusTableRes'}
        customStyles={dataTableStyles}
        defaultSortFieldId={2}
        defaultSortAsc={false}
      />
    </div>
  </>
}

export default AllSurveyTable;