import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Todo {
    message: string;
}

const DashboardStore = createSlice({
    name: "DashboardStore",
    initialState: {
        dashboardSurvey:""
    },
    reducers: {
        addDashboardStoreData: (state, action: any) => {
            state.dashboardSurvey = action.payload;
            return state;
        }
    }
});
export const { addDashboardStoreData } = DashboardStore.actions;

export default DashboardStore;