import DataTable, { TableStyles } from "react-data-table-component";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import { BsSortDown } from "react-icons/bs";
import { FaEllipsisV } from 'react-icons/fa';
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../../common/InfoIcon";

interface ColumnDef {
  name: string,
  cell?: any,
  selector?: any,
  sortable?: boolean,
  style?: any,
  width?: any
}


const dataTableStyles: TableStyles = {

  headCells: {
    style: {
      borderTop: "1px solid rgba(0,0,0,0.07)",
      borderLeft: "1px solid rgba(0,0,0,0.07)",
      '&:last-of-type': {
        borderRight: "1px solid rgba(0,0,0,0.07)",
      },
      justifyContent: "left",
      fontWeight: "bold",
      paddingLeft: "5px",

    }
  },
  cells: {
    style: {
      borderLeft: "1px solid rgba(0,0,0,0.07)",
      '&:last-of-type': {
        borderRight: "1px solid rgba(0,0,0,0.07)",
      },
      width: "fitContent",
      justifyContent: "left",
      paddingLeft: "5px",

    }
  }
}

const SiteWithContinousEmissionChart = (props: any) => {

  const { t } = useTranslation();
  var sitesWithContEmission: any = [];
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState('');
  props.filteredValues.map((val: any) => {
    val?.data?.map((val_data: any) => {
      if (val_data.continuousEmission) {
        sitesWithContEmission.push(val_data);
      }

    })

  })


  const sortIcon = <BsSortDown />;


  const columns: ColumnDef[] = [

    {
      name: t("Dashboard.SiteEmissionTable.Type"),
      selector: (row: any) => row.type,
      cell: (row: any) => row.type,
      sortable: true
    },
    {
      name: t("Dashboard.SiteEmissionTable.Name"),
      selector: (row: any) => row.name,
      cell: (row: any) => row.name,
      sortable: true
    },
    {
      name: t("Dashboard.SiteEmissionTable.County"),
      selector: (row: any) => row.county,
      cell: (row: any) =>
        <span className="badge bg-purple" >{row.county}</span>,
      sortable: true,
      width: "25%",
    },
  ];

  const data = [
    {
      Type: "Production_sit_with_tank",
      Name: "BIG_EDDY-007",
      Country: "Harris"
    },
    {
      Type: "Production_sit_with_tank",
      Name: "BIG_EDDY_069",
      Country: "Lee"
    },
  ];

  const ExpandedComponent = () => () => {
    const columns: ColumnDef[] = [
      {
        name: t("Dashboard.SiteEmissionSubTable.SurveyName"),
        cell: (row: any) => row.SurveyName,
        sortable: true,
        width: "30%"
      },
      {
        name: t("Dashboard.SiteEmissionSubTable.Date"),
        cell: (row: any) => row.Date,
        sortable: true,
        width: "28%"
      },
      {
        name: t("Dashboard.SiteEmissionSubTable.EmissionCount"),
        cell: (row: any) => <span className="badge bg-dark">{row.EmissionCount}</span>,
        sortable: true,
        width: "32%"
      },
      {
        name: "",
        cell: (row: any) => {
          return <>
            <FaEllipsisV></FaEllipsisV>

          </>
        },
        sortable: true,
        width: "10%",

      }
    ];

    const data = [
      {
        SurveyName: "RadOil Sites Survey",
        Date: "22-Oct_2022",
        EmissionCount: 5
      },
      {
        SurveyName: "RadOil Sites Survey-00 ",
        Date: "20-Nov-2022",
        EmissionCount: 8
      },
    ];
    return (
      <>
        <div className="table-responsive" style={{ border: '15px solid #EEEE' }}>
          <DataTable

            columns={columns}
            data={data}
            highlightOnHover={true}

            customStyles={dataTableStyles}
            sortIcon={sortIcon}


          />
        </div>
      </>
    );
  };

  const liveData = sitesWithContEmission?.length > 0 ? sitesWithContEmission : [];

  const filteredItems = liveData?.filter((item: any) => {

    let regex = /"(\\.|[^"\\])*"/g;;
    var exactMatchText: any = filterText != null ? filterText.match(regex)?.[0].replace(/"/g, "") : null;

    if (exactMatchText) {
      return (item?.name?.toString().toLowerCase() == exactMatchText?.toLowerCase() ||
        item?.county?.toString().toLowerCase() == exactMatchText?.toLowerCase() ||
        item?.type?.toString().toLowerCase() == exactMatchText?.toLowerCase())
    } else {
      return (item?.name?.toString().toLowerCase().includes(filterText?.toLowerCase()) ||
        item?.county?.toString().toLowerCase().includes(filterText?.toLowerCase()) ||
        item?.type?.toString().toLowerCase().includes(filterText?.toLowerCase()))
    }

  })
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };
    return <><div className="col-md-12" style={{ marginRight: '20px', marginBottom: "10px" }}>
      <div className="d-flex flex-row  justify-content-start align-items-center">
        <label className="custom_common_label">{t("SearchLabel")}: &nbsp;&nbsp;&nbsp; </label>
        <input type="text" name="sitewithemissionsearch" id="sitewithemissionsearch" className="form-control input-sm" onChange={(e: any) => setFilterText(e?.target?.value)} value={filterText} />
        <div className="searchClear">
          <button type="button" className="btn btn-primary" onClick={(e) => handleClear()}> X</button >
          <InfoIcon />
        </div>
      </div>
    </div>
    </>
  }, [filterText, resetPaginationToggle]);
  return <>
    {/*  */}
    <DataTable
      columns={columns}
      data={filteredItems}
      highlightOnHover={true}
      pagination={true}
      subHeader
      subHeaderComponent={liveData?.length > 0 ? subHeaderComponentMemo : []}
      paginationPerPage={4}
      paginationIconFirstPage={false}
      paginationIconLastPage={false}
      paginationComponentOptions={{ rowsPerPageText: '', rangeSeparatorText: 'of', noRowsPerPage: true, selectAllRowsItem: true, selectAllRowsItemText: '' }}
      customStyles={dataTableStyles}
      sortIcon={sortIcon}

      expandableIcon={
        {
          collapsed: <i className="fa fa-2x fa-plus-circle" style={{ color: '#e74c3c', fontSize: '20px', textAlign: 'center', display: 'block', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}></i>,
          expanded: <i className="fa fa-2x fa-minus-circle" style={{ color: '#e74c3c', fontSize: '20px', textAlign: 'center', display: 'block', justifyContent: 'center', alignItems: 'center', margin: 'auto' }} aria-hidden="true"></i>
        }
      }
    />

  </>;
}

export default SiteWithContinousEmissionChart;