import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Todo {
    message: string;
}

const AOGISiteUpload = createSlice({
    name: "AOGISiteUpload",
    initialState: {
        SiteList : []
    },
    reducers: {
        SitesList: (state, action: any) => {
            state.SiteList = action.payload;
            return state;
        }
    }
});

export default AOGISiteUpload;
export const { SitesList } = AOGISiteUpload.actions;