import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import EmissionNoteService from "../../../../services/emission-note/emission-note.service";
import TokenService from '../../../../services/autentication/token.service';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import '../css/notes-popup.css';
import { InputTextarea } from 'primereact/inputtextarea';

interface SiteNoteInterface {
    currrentemissiondata: any,
    surveyid: string,
    surveyDetails: any,
    setNotesSuccess: any,
    originalData: any,
    setSurveyNotesUpdate: any,
    setSendDispNote: any
}

const SiteNotes = (siteNotesInterface: SiteNoteInterface) => {
    const { t } = useTranslation();
    const [IsEmissionNoteAdding, setIsemissionNoteAdding] = useState<boolean>(false);
    const [curUserData, setCurUserData] = useState<any>(null);
    const [currentNotes, setcurrentNotes] = useState<any>(null);
    const messagesEndRef = useRef(document.createElement("div"))
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        setCurUserData(TokenService?.getUser())
        let curData = JSON.parse(JSON.stringify(siteNotesInterface.surveyDetails[0]));
        const emissionData = curData.data.filter((e: any) => e.id == siteNotesInterface.surveyid)
        const tempCurrentNotes = emissionData[0]?.emission?.filter((e: any) => e.id == siteNotesInterface.currrentemissiondata?.id)
        tempCurrentNotes[0]?.notes?.map((d: any) => {
            d.id = d._id
        })
        if (tempCurrentNotes[0].notes?.length > 0) {
            tempCurrentNotes[0]?.notes?.sort(function (a: any, b: any) {
                let datea: any = new Date(a.createdOn)
                let dateb: any = new Date(b.createdOn)
                return datea - dateb;
            });
        }
        setcurrentNotes(tempCurrentNotes[0])
        scrollToBottom()
    }, [])

    useEffect(() => {
        if (siteNotesInterface?.setSendDispNote == true) {
            setTimeout(() => {
                const section: any = document.querySelector('.scrr:last-child');
                section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 900);
        }
    }, [siteNotesInterface?.setSendDispNote])


    const sitesNotesForm: any = useFormik({
        initialValues: {
            emissionNote: '',
        },
        enableReinitialize: true,
        validate: async (data: any) => {
            let errors: any = {};

            if (!data.emissionNote) {
                errors.emissionNote = 'This field is required.';
            }

            return errors;
        },
        onSubmit: async (data: any, { resetForm }) => {
            let res = await addNotesToEmission(data);
            if (res) {
                resetForm();
            }
        }
    });

    const addNotesToEmission = async (emissionNoteDetails: any) => {
        return await new Promise(async (resolve, reject) => {
            let emissionNote = {
                AogiID: siteNotesInterface.surveyid,
                EmissionID: siteNotesInterface.currrentemissiondata?.id,
                Description: emissionNoteDetails.emissionNote,
                createdBy: curUserData?.firstName + " " + curUserData?.lastName
            }

            let responseFterNoteAddition = await EmissionNoteService.addNotesToEmission(emissionNote).then((response: any) => {
                return response;
            });

            if (responseFterNoteAddition) {
                let retdata = {
                    createdBy: responseFterNoteAddition?.createdBy,
                    // createdOn: moment.utc(moment.utc().valueOf()).toDate(),
                    description: responseFterNoteAddition?.description,
                    id: responseFterNoteAddition?.noteId,
                    _id: responseFterNoteAddition?.noteId
                }
                currentNotes.notes.push(retdata)
                let _ReduxData = JSON.parse(JSON.stringify(siteNotesInterface?.originalData)); //Created A Copy for rewrite object 
                _ReduxData?.forEach(function (obj: any) {
                    var tmpData = obj.data.filter((o: any) => o.id == siteNotesInterface?.surveyid);
                    if (tmpData?.length > 0) {
                        tmpData[0].emission?.forEach(function (obj2: any) {
                            if (obj2.id == siteNotesInterface?.currrentemissiondata?.id) {
                                obj2?.notes.push(retdata)
                            }
                        })
                    }

                })
                resolve(_ReduxData);
                siteNotesInterface.setNotesSuccess(true)
                siteNotesInterface.setSurveyNotesUpdate(_ReduxData)
                return "SUCCESS"
            }

        })


    }


    const isFormFieldValid = (name: string) => {
        return !!(sitesNotesForm.touched[name] && sitesNotesForm.errors[name])
    };

    const getFormErrorMessage = (name: string) => {
        return isFormFieldValid(name) && <span className="p-error">{sitesNotesForm.errors[name]}</span>;
    };

    const removeNotesForAnEmission = (rmdata: any) => {
        confirmDialog({
            message: 'Are you sure you want to delete ?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
                let deleteNotesObj: any = {
                    AogiID: siteNotesInterface.surveyid,
                    EmissionID: siteNotesInterface.currrentemissiondata?.id,
                    NoteId: rmdata._id,
                }

                let responseFterNoteDeletion = await EmissionNoteService.removeNoteFromEmission(deleteNotesObj).then((response: any) => {
                    return response;
                });

                if (responseFterNoteDeletion) {
                    currentNotes.notes = currentNotes.notes.filter((person: { id: any; }) => person.id != rmdata?.id);
                    const finalObj = {
                        "id": currentNotes.id,
                        "source": currentNotes.source,
                        "size": currentNotes.size,
                        "description": currentNotes.description,
                        "picture": currentNotes.picture,
                        "video": currentNotes.video,
                        "pictureFile": currentNotes.pictureFile,
                        "pictureID": currentNotes.pictureID,
                        "videoID": currentNotes.videoID,
                        "videoFile": currentNotes.videoFile,
                        "createdBy": currentNotes.createdBy,
                        "updatedBy": currentNotes.updatedBy,
                        "createdOn": currentNotes.createdOn,
                        "updatedOn": currentNotes.updatedOn,
                        "noOfLeaks": currentNotes.noOfLeaks,
                        "leakPersistence": currentNotes.leakPersistence,
                        "userLocDateTime": currentNotes.userLocDateTime,
                        "notes": currentNotes.notes
                    }
                    setcurrentNotes(finalObj)
                    let _ReduxData = JSON.parse(JSON.stringify(siteNotesInterface?.originalData)); //Created A Copy for rewrite object 
                    _ReduxData?.forEach(function (obj: any) {
                        var tmpData = obj.data.filter((o: any) => o.id == siteNotesInterface?.surveyid);
                        if (tmpData?.length > 0) {
                            tmpData[0].emission?.forEach(function (obj2: any) {
                                if (obj2.id == siteNotesInterface?.currrentemissiondata?.id) {
                                    var tempindex = obj2.notes.findIndex((x: any) => x._id == rmdata._id);
                                    obj2.notes.splice(tempindex, 1);
                                }
                            })
                        }

                    })
                    siteNotesInterface.setNotesSuccess(true)
                    siteNotesInterface.setSurveyNotesUpdate(_ReduxData)
                }
            },
            // reject: () => rejectFunc()
        });
    }

    return <>
        <div className="card">
            <div className="card-body">
                {/* <div className="bg-light d-flex flex-column flex-fill justify-content-end p-3 rounded notesPopUp"> */}
                <div className="bg-light justify-content-end p-3 rounded notesPopUp">

                    {
                        currentNotes != null && currentNotes?.notes?.map((expData: any, i: any) => {
                            return (
                                <div className={`${((curUserData.firstName + " " + curUserData.lastName).toLocaleLowerCase() == (expData.createdBy).toLocaleLowerCase()) ? "d-flex justify-content-end mb-2" : "d-flex mb-2"} scrr`} key={i} ref={messagesEndRef} id="scrr">
                                    <div className={`${((curUserData.firstName + " " + curUserData.lastName).toLocaleLowerCase() == (expData.createdBy).toLocaleLowerCase()) ? "bubble bubble-primary" : "bubble"}`}>
                                        <p className="mb-1">
                                            {expData.description}<br />

                                            <small className="pull-right"><span className="text-muted"> {expData.createdBy} - {moment(expData.createdOn).format('DD-MMM-YYYY hh:mm A')} </span>

                                                {
                                                    ((curUserData.firstName + " " + curUserData.lastName).toLocaleLowerCase() == (expData.createdBy).toLocaleLowerCase()) ? <span className="text-end  pull-right">
                                                        <span className="fa fa-trash" onClick={() => removeNotesForAnEmission(expData)} style={{ cursor: 'pointer', marginLeft: "5px", fontSize: "10px" }}></span>
                                                    </span> : ""

                                                }
                                            </small>


                                        </p>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <form className="d-flex align-items-end gap-1 py-3" onSubmit={sitesNotesForm.handleSubmit}>
                    {/* <input type="text" name="emissionNote" id="emissionNote" className="form-control border-3 shadow-none" value={sitesNotesForm.values.emissionNote} onChange={(event: any) => {
                        sitesNotesForm.setFieldValue("emissionNote", event.target.value);
                    }} placeholder="Type a note" style={{ resize: "none" }} /> */}
                    <InputTextarea name="emissionNote" id="emissionNote" rows={2} cols={100} value={sitesNotesForm.values.emissionNote} onChange={(event: any) => {
                        sitesNotesForm.setFieldValue("emissionNote", event.target.value);
                    }} placeholder="Type a note" style={{ width: "100%" }} className="form-control border-3 shadow-none" />

                    <button className="btn btn-primary" type="submit" disabled={IsEmissionNoteAdding}>
                        {IsEmissionNoteAdding && <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>}
                        {!IsEmissionNoteAdding ? t("Add") : t("Adding") + "...."}
                    </button>
                </form>
                {getFormErrorMessage('emissionNote')}
            </div>
        </div></>
}

export default SiteNotes;