import { useState } from "react";
import { Dialog } from 'primereact/dialog';
import GoogleMap from '../map/google-map';
import { useParams } from "react-router-dom";



const SiteOverView = (aogiSite: any) => {
    const [visibleGoogleMap, setVisibleGoogleMap] = useState<boolean>(false);
    const [currentCustomerLatitude, setcurrentCustomerLatitude] = useState<any>("");
    const [currentCustomerLongitude, setcurrentCustomerLongitude] = useState<any>();
    const [currentCustomerMapPinName, setcurrentCustomerMapPinName] = useState("");

    const { lat, lon } = useParams();
    const lonFinal = lon?.replace("}", "");


    const toggleGoogleMap = () => {
        setVisibleGoogleMap(!visibleGoogleMap);
    }

    const openGoogleMap = (aogiSite: any) => {
        setcurrentCustomerMapPinName(aogiSite.thingNo);
        setcurrentCustomerLatitude(lat);
        setcurrentCustomerLongitude(lon);
        toggleGoogleMap();
    }


    return <>
        <GoogleMap lati={lat} lon={lonFinal} name={currentCustomerMapPinName} />
    </>
}

export default SiteOverView;