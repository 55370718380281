import ApiConstant from "../../constants/api.constants";
import ConsoleType from "../../constants/console-type.constant";
import LocalStorageConstant from "../../constants/localstroage.constant";
import AOGISurvey from "../../interfaces/aogi-survey/aogi-survey";
import Helpers from "../../utils/helpers";
import api from "../api";

const getSurveyList = async (survey: AOGISurvey) => {

  // let apiValues = '?SurveyYear=';
  let apiValues = '?SurveyYear=' + survey.surveyDate?.getFullYear();

  if (survey.customerID) {
    apiValues = apiValues + '&customerId=' + survey.customerID;
  }

  return await api
    .get(ApiConstant.getAOGISurveys + apiValues)
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error) {
      Helpers.ConsoleLog(error, ConsoleType.Error);
    });
};

const addSurvey = async (survey: AOGISurvey) => {
  return await api.post(ApiConstant.addSurvey, survey)
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error) {
      Helpers.ConsoleLog(error, ConsoleType.Error);
    })
}

const updateAogiSurvey = async (survey: AOGISurvey) => {
  return await api.post(ApiConstant.updateAogiSurvey, survey)
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error) {
      Helpers.ConsoleLog(error, ConsoleType.Error);
    })
}


const removeAogiSurvey = async (surveyId: string) => {

  return await api
    .post(ApiConstant.removeAogiSurvey, { _id: surveyId })
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error) {
      Helpers.ConsoleLogError(error);
    });
};

const SurveyService = {
  getSurveyList,
  addSurvey,
  updateAogiSurvey,
  removeAogiSurvey
};

export default SurveyService;