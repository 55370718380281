/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/store";
import { useTranslation } from "react-i18next";


/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
// Themes end



const SurveyCompletionStatusChart = (para: any) => {
    const { t } = useTranslation();

    const [displayChart, displayChartUpdate] = useState("none");
    const ResizeStoreToSetHeight = useSelector((state: AppState) => state.AOGICommon.setSurveyCompStatusHeightPanel);
    const TotalSites = Number(para.TotalSites);

    const RemainingSites = Math.ceil(Number(para.RemainingSites));
    const CompletedSites = Math.floor(Number(para.CompletedSites));

    let RemainingSitesPer:any = ((RemainingSites / TotalSites) * 100).toFixed(2);
    let CompletedSitesPer:any = ((CompletedSites / TotalSites) * 100).toFixed(2);
    if(RemainingSitesPer % 1 == 0){
        RemainingSitesPer = Number(RemainingSitesPer).toFixed(0)
    }
    if(CompletedSitesPer % 1 == 0){
        CompletedSitesPer = Number(CompletedSitesPer).toFixed(0)
    }
    useEffect(() => {

        displayChartUpdate("none");
        // Create chart instance
        let chart_pie = am4core.create("chartdiv_pie", am4charts.PieChart);
        if (chart_pie.logo) {
            chart_pie.logo.disabled = true;
        }
        chart_pie.responsive.enabled = true;
        // Let's cut a hole in our Pie chart the size of 40% the radius
        chart_pie.innerRadius = am4core.percent(40);
        // Add and configure Series
        let pieSeries = chart_pie.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.innerRadius = 10;
        pieSeries.slices.template.fillOpacity = 0.5;

        let label = pieSeries.createChild(am4core.Label);
        label.text = CompletedSitesPer + "%" + " " + "\n"+t("SurveyDetails.CompletedText");
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 11;

        // pieSeries.slices.template.propertyFields.disabled = "labelDisabled";
        // pieSeries.labels.template.propertyFields.disabled = "labelDisabled";
        // pieSeries.ticks.template.propertyFields.disabled = "labelDisabled";


        // Add data
        // pieSeries.data = [{
        //     "category": "Remaining",
        //     "value": 60
        // }, {
        //     "category": "Completed",
        //     "value": 30,
        //     "labelDisabled": true
        // }];

        // Disable sliding out of slices
        // pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        // pieSeries.slices.template.states.getKey("hover").properties.scale = 1;

        // Add second series
        let pieSeries2 = chart_pie.series.push(new am4charts.PieSeries());
        pieSeries2.responsive.enabled = true;
        pieSeries2.dataFields.value = "value";
        pieSeries2.dataFields.category = "category";
        // pieSeries2.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        // pieSeries2.slices.template.states.getKey("hover").properties.scale = 1;
        pieSeries2.slices.template.propertyFields.fill = "color";




        // Add data
        pieSeries2.data = [{
            "category": "Remaining",
            "value": RemainingSitesPer,
            color: "#cfcfcf"

        }, {
            "category": "Completed",
            "value": CompletedSitesPer,
            color: "#68b7dc"

        }];


        // pieSeries.adapter.add("innerRadius", function (innerRadius, target) {
        //     return am4core.percent(50);
        // })

        // pieSeries2.adapter.add("innerRadius", function (innerRadius, target) {
        //     return am4core.percent(60);
        // })

        // pieSeries.adapter.add("radius", function (innerRadius, target) {
        //     return am4core.percent(100);
        // })

        // pieSeries2.adapter.add("radius", function (innerRadius, target) {
        //     return am4core.percent(80);
        // })

        return () => {
            setTimeout(() => {
                displayChartUpdate("block")
            }, 4000)
            chart_pie.dispose();
        };
    }, [para]);

    // ResizeStoreToSetHeight
    return <>
        {
            (RemainingSites == 0 && CompletedSites == 0) ? <div style={{ padding: '24px', textAlign: 'center', marginTop: '40px', color: 'rgba(0,0,0,0.87)' }}>{t("NoRecordsToDisplay")}</div> : <div id="chartdiv_pie" style={{ width: "100%", height: ResizeStoreToSetHeight ? "350px" : "190px", marginTop: ResizeStoreToSetHeight ? "30px" : "0px", display: displayChart }} ></div>
        }
    </>
}

export default SurveyCompletionStatusChart;