import ApiConstant from "../../constants/api.constants";
import ConsoleType from "../../constants/console-type.constant";
import Helpers from "../../utils/helpers";
import api from "../api";

const getSurveyDetailsList = async (surveyId: string) => {
    return await api
        .get(ApiConstant.getSurveyDetails + "?SurveyID=" + surveyId)
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLog(error, ConsoleType.Error);
        });
};

const DeleteSurveyDetails = async (frmData: any) => {
    return await api
        .post(ApiConstant.removeSiteDetails, frmData)
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });

}

const DeleteSubSurveyRecord = async (frmData: any) => {
    return await api
        .post(ApiConstant.removeEmissionDetails, frmData)
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
}


const RemoveSurveyDetailsMultiple = async () => {

}

const UpdateSurveyDetailsList = async (formData: any) => {
    return await api
        .post(ApiConstant.addOrUpdateSurveyDetailsNew, formData, {
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
        })
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
}


const DownloadPictureVideoService = async (id: any, fileName: any) => {
    return await api
        .get(ApiConstant.dowloadFiles + "?id=" + id + "&Filename=" + fileName + "&container=aogi-survey")
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
}

const GetBlobURI = async (custID:any,SurveyID:any,VideoID:any) => {
    
    let BlobFileID:any = custID + "\\" +SurveyID+ "\\" + VideoID;
    return await api
        .get(ApiConstant.GetBlobURI + "?BlobFileID=" + BlobFileID + "&Container=aogi-survey")
        .then(function (response: any) {
            return response.data;
        })
        .catch(function (error) {
            Helpers.ConsoleLogError(error);
        });
}

const UpdateSubSurveyDetailsList = async () => {

}

const SurveyDetailsService = {
    getSurveyDetailsList,
    UpdateSurveyDetailsList,
    DeleteSubSurveyRecord,
    DeleteSurveyDetails,
    DownloadPictureVideoService,
    GetBlobURI
};

export default SurveyDetailsService;