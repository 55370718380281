import profileImage from '../../../assets/img/profile-photos/icon.png';
import settings from '../../../utils/settings.json';
import { SiteSettings } from '../../../models/site-settings';
import MenuItem from './menu-item';
import { useEffect, useState } from 'react';
import UserService from '../../../services/user/user.service';
import Helpers from '../../../utils/helpers';

export const MainNav = () => {
    const siteSettings = (settings as SiteSettings);
    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        UserService.getCurrentUser().then((userDetails: any) => {
            Helpers.ConsoleLog(userDetails);
            setUser(userDetails)
        });
    }, []);

    

    return (
        <>
            <nav id="mainnav-container" className="mainnav">
                <div className="mainnav__inner">

                    <div className="mainnav__top-content scrollable-content pb-5">

                        <div className="mainnav__profile mt-3">

                            <div className="mininav-toggle text-center py-2">
                                <img className="mainnav__avatar img-md rounded-circle" src={profileImage} alt="Profile Picture" />
                            </div>

                            <div className="mininav-content collapse d-mn-max">
                                <div className="d-grid">

                                    <button className="d-block btn shadow-none p-2" data-bs-toggle="collapse" data-bs-target="#usernav" aria-expanded="false" aria-controls="usernav">
                                            <div className="mb-0 me-2">{user && (user?.firstName + ' ' + user?.lastName)} </div>
                                        <small className="text-muted" style={{ textTransform: "lowercase" }}>{user && user?.userName}</small>
                                    </button>
                                    
                                </div>
                            </div>
                        </div>

                        <ul className="mainnav__menu nav flex-column">
                            <MenuItem menuItems={siteSettings.menu} />
                        </ul>

                        {/* <div className="mainnav__profile">
                            <div className="mininav-toggle text-center py-2 d-mn-min">
                                <i className="pli-monitor-2"></i>
                            </div>

                            <div className="d-mn-max mt-5"></div>
                        </div> */}
                    </div>
                </div>
            </nav>

        </>
    );
};
export default MainNav;
