import LocalStorageConstant from "../../constants/localstroage.constant";
import { PersistSelectedCustomer } from "../../models/persist-selected-customer";

const setPersistSelectedCustomer = (persistSelectedCustomer: PersistSelectedCustomer) => {

    let oldpersistantCustomer = getPersistSelectedCustomer();

    let newSelecteddCustomerToStore: PersistSelectedCustomer = {
        siteUploadSelectedcustomer: persistSelectedCustomer?.siteUploadSelectedcustomer ? persistSelectedCustomer?.siteUploadSelectedcustomer : oldpersistantCustomer?.siteUploadSelectedcustomer
        , dashboardSelectedCustomer: persistSelectedCustomer?.dashboardSelectedCustomer ? persistSelectedCustomer?.dashboardSelectedCustomer : oldpersistantCustomer?.dashboardSelectedCustomer
        , surveySelectedcustomer: persistSelectedCustomer?.surveySelectedcustomer ? persistSelectedCustomer?.surveySelectedcustomer : oldpersistantCustomer?.surveySelectedcustomer
    }

    localStorage.setItem(LocalStorageConstant.selectedCustomer, JSON.stringify(newSelecteddCustomerToStore) ?? '');
}

const getPersistSelectedCustomer = (): PersistSelectedCustomer => {

    let selectedCustomers = localStorage.getItem(LocalStorageConstant.selectedCustomer) ?? '';
    let parsedJsonCustomer = selectedCustomers ? JSON.parse(selectedCustomers) : null;
    return parsedJsonCustomer;
}

const PersistantService = {
    getPersistSelectedCustomer,
    setPersistSelectedCustomer
};

export default PersistantService;