import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Services
import { AppendSiteList, RemoveSiteList, UpdateSiteList } from "../../services/AOGI/site-upload";
import { GetCustomerList, GetSiteList, isAuth } from "../../services/AOGI/common";
import { GetSurveyList, AppendSurvey, RemoveSurvey, UpdateSurveyList } from "../../services/AOGI/survey"
import { GetSurveyDetails, RemoveSurveyDetails } from "../../services/AOGI/survey-details"

// Store/Reducers
import { addCustomersData, getSitesData } from "../../store/AOGI/common";
import { addSurveyData } from "../../store/AOGI/survey";
import { AddSurveyDetails, } from "../../store/AOGI/survey-details";
import { AppDispatch, AppState } from "../../store/store";

// Loading
import LoadingScreen from "../common/Loading";



export const ReduxSandbox = (prop: any) => {
const [loadingText, loadingTextUpdate] = useState("");

    const { setTest } = prop;
    // Redux Dispatch
    const dispatch: AppDispatch = useDispatch();
    const CustData_Redux = useSelector((state: AppState) => state.AOGICommon.CustData);
    const SitesData_Redux = useSelector((state: AppState) => state.AOGICommon.SiteData);
    const SurveyData_Redux = useSelector((state: AppState) => state.AOGISurveyIndex.SurveyData);
    const SurveyDetails_Redux = useSelector((state: AppState) => state.AOGISurveyDetails.SurveyDetailsData);


    // Loading Screen State
    const [isLoading, isLoadingUpdate] = useState(false);

    // Check for isAuth (isAuth true after login)
    let authStatus = useSelector((state: AppState) => state.AOGICommon.isAuth);
    isAuth(authStatus);



    // Load 
    function loadCust() {
        // isLoadingUpdate(true);
        GetCustomerList(CustData_Redux).then((res) => {
            setTimeout(() => {
                dispatch(addCustomersData(res as any));
                // isLoadingUpdate(false);

            }, 1000);

        })

    }

    function LoadSurvey() {
        isLoadingUpdate(true);
        GetSurveyList(SurveyData_Redux).then((res: any) => {
            setTimeout(() => {
                dispatch(addSurveyData(res as any));
                isLoadingUpdate(false);
            }, 1000)

        })

    }

    function LoadSurveyDetail() {
        isLoadingUpdate(true);
        GetSurveyDetails("asd", SurveyDetails_Redux).then((res: any) => {
            setTimeout(() => {
                dispatch(AddSurveyDetails(res as any));
                isLoadingUpdate(false);
            }, 1000)

        })

    }

    function loadSites() {
        GetSiteList(SitesData_Redux).then((res) => {
            dispatch(getSitesData(res as any));
        })
    }



    // Remove
    function RemoveSites() {
        let removeID = "2";
        RemoveSiteList(removeID, SitesData_Redux).then((res) => {
            dispatch(getSitesData(res as any));
        })
    }

    function RemoveSurveyHandle() {
        let removeID = "7938fcb7-a9a2-440c-a2f8-d40b6a7a486e";
        RemoveSurvey(removeID, SurveyData_Redux).then((res) => {
            dispatch(addSurveyData(res as any));
        })
    }


    function RemoveSurveyDetailsHandle() {
        let removeID = "7eab4e67-0d1b-43a1-ad04-7695ae38fabf";
        let SurveyID = "7eab4e67-0d1b-43a1-ad04-7695ae38fabf";
        RemoveSurveyDetails(removeID, SurveyID, SurveyDetails_Redux).then((res) => {
            dispatch(AddSurveyDetails(res as any));
        })
    }



    // Append(Create) Data
    function AddSites() {

        // 
        // Excel Data
        // 
        let new_site_data = [
            {
                "id": "12",
                "type": "Production_site_with_tank",
                "name": "BAR 4 FED BTY",
                "county": "harris",
                "state": "Texas",
                "lat": "32.43104345",
                "lon": "-104.0942642",
                "cust_id": "1",
                "cust_name": "Cutomer 1",
                "created_by": "Test User"
            }, {
                "id": "133",
                "type": "Production_site_with_tank",
                "name": "BAR 4 FED BTY",
                "county": "harris",
                "state": "Texas",
                "lat": "32.43104345",
                "lon": "-104.0942642",
                "cust_id": "1",
                "cust_name": "Cutomer 1",
                "created_by": "Test User"
            }

        ]

        AppendSiteList(new_site_data, SitesData_Redux).then((res) => {
            dispatch(getSitesData(res as any));
        });

    }


    function AddSurvey() {
        let new_survey_data = [
            {
                "id": "7938fcb7-a9a2-440c-a2f8-d40b6a7a486e",
                "cust_id": "1",
                "survey_name": "Sample Survey Name 13",
                "survey_date": "1 Nov 2022",
                "site_id": "1",
                "site_count": "3",
                "requested_by": "Customer 1",
                "requested_on": "1 Nov 2022",
                "status": "in progress"
            }
        ]

        AppendSurvey(new_survey_data, SurveyData_Redux).then((res) => {
            dispatch(addSurveyData(res as any));
        });

    }



    // Update Data

    // Sites
    function UpdateSite() {
        let updateID = "2";
        let UpdateData = {
            "type": "Production_site_with_tank",
            "name": "New Updated Name",
            "county": "harris",
            "state": "Texas",
            "lat": "32.43104345",
            "lon": "-104.0942642",
            "cust_id": "1",
            "cust_name": "Cutomer 1",
            "created_by": "Test User"
        }
        UpdateSiteList(updateID, UpdateData, SitesData_Redux).then((res) => {
            dispatch(getSitesData(res as any));
        })
    }

    //Survey
    function UpdateSurvey() {
        let updateID = "7938fcb7-a9a2-440c-a2f8-d40b6a7a486e";

        //  status is changed from progress to completed
        let UpdateData = {
            "cust_id": "1",
            "survey_name": "Sample Survey Name 13",
            "survey_date": "1 Nov 2022",
            "site_id": "1",
            "site_count": "3",
            "requested_by": "Customer 1",
            "requested_on": "1 Nov 2022",
            "status": "completed"
        }
        UpdateSurveyList(updateID, UpdateData, SurveyData_Redux).then((res) => {
            dispatch(addSurveyData(res as any));
        })
    }

    return (
        <>
            <LoadingScreen isLoading={isLoading} text={loadingText}/>
            {/* {isLoading && <LoadingScreen isLoading={isLoading} />} */}
            <div className="content__header content__boxed overlapping">
                <div className="content__wrap pt-3 pb-4">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="./index.html">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Max Navigation</li>
                        </ol>
                    </nav>

                    <h1 className="page-title mb-2 mt-4">Max Navigation</h1>

                    <p className="lead">

                    </p>

                </div>

            </div>

            <div className="content__boxed">
                <div className="content__wrap">
                    <div className="card mb-4">
                        <div className="card-body">

                            <h5 className="card-title">Your content here</h5>

                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Qui, natus temporibus voluptate corporis possimus ab aut cumque.</p>
                            <div className="">
                                {/* <!-- Bordered table --> */}
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="text-center">#</th>
                                                <th>Task</th>
                                                <th>Check</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-center">1</td>
                                                <td>Get Customer List</td>
                                                <td><button onClick={(e) => { loadCust() }} className="btn btn-success btn-sm">Fetch Customers</button></td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">2</td>
                                                <td>Fetch Sites</td>
                                                <td><button onClick={(e) => { loadSites() }} className="btn btn-success btn-sm">Load Sites</button></td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">3</td>
                                                <td>Add Sites</td>
                                                <td><button onClick={(e) => { AddSites() }} className="btn btn-primary btn-sm">Add Sites</button></td>
                                            </tr>

                                            <tr>
                                                <td className="text-center">4</td>
                                                <td>Update Sites</td>
                                                <td><button onClick={(e) => { UpdateSite() }} className="btn btn-warning btn-sm">Update Sites</button></td>
                                            </tr>

                                            <tr>
                                                <td className="text-center">5</td>
                                                <td>Remove Site</td>
                                                <td><button onClick={(e) => { RemoveSites() }} className="btn btn-danger btn-sm">Remove Sites</button></td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">6</td>
                                                <td>Survey List</td>
                                                <td><button onClick={(e) => { LoadSurvey() }} className="btn btn-success btn-sm">Fetch Survey</button></td>
                                            </tr>


                                            <tr>
                                                <td className="text-center">7</td>
                                                <td>Add Survey</td>
                                                <td><button onClick={(e) => { AddSurvey() }} className="btn btn-primary btn-sm">Add A Survey</button></td>
                                            </tr>

                                            <tr>
                                                <td className="text-center">8</td>
                                                <td>Update Survey</td>
                                                <td><button onClick={(e) => { UpdateSurvey() }} className="btn btn-warning btn-sm">Update Survey</button></td>
                                            </tr>

                                            <tr>
                                                <td className="text-center">9</td>
                                                <td>Remove Survey</td>
                                                <td><button onClick={(e) => { RemoveSurveyHandle() }} className="btn btn-danger btn-sm">Remove A Survey</button></td>
                                            </tr>

                                            <tr>
                                                <td className="text-center">10</td>
                                                <td>Survey Detail List</td>
                                                <td><button onClick={(e) => { LoadSurveyDetail() }} className="btn btn-success btn-sm">Fetch Survey Detail</button></td>
                                            </tr>

                                            <tr>
                                                <td className="text-center">11</td>
                                                <td>Remove Survey Details</td>
                                                <td><button onClick={(e) => { RemoveSurveyDetailsHandle() }} className="btn btn-danger btn-sm">Remove</button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                {/* <!-- END : Bordered table --> */}


                            </div>

                        </div>
                    </div>

                    <div style={{ height: "250px" }}></div>

                </div>
            </div>

        </>
    );
};
export default ReduxSandbox;
